import React, { FC, useState } from "react";
import styled from "styled-components";
import { Sider } from "../components/sider/Sider";
import { SearchBar } from "../components/commun/SearchBar";
import heartTrue from "../assets/icons/heartTrue.svg";
import { Button, ButtonVariantEnum } from "../components/buttons/Button";
import sac from "../assets/icons/sac.svg"
import appart from "../assets/icons/appart.svg"
import users from "../assets/icons/users.svg"
import { ArrowRightIcon } from '@radix-ui/react-icons';


/*
 * Props.
 */

// interface MissionProps {
//   url: string;
//   missionTitle: string;
//   companyName: string;
//   localisation: string;
//   skills: string[];
//   description: string;
// }

/*
 * Styles.
 */

const Container = styled.div`
  display: flex;
  height: 100vh;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100%;
`;

const Header = styled.div`
  display: flex;
  height: 100px;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

const MissionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 20px;
`;

const MissionsContainerColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 48%;
  padding: 0 1%;
`;

const MissionsCard = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  background: #F5F5F5;
  margin-bottom: 15px;
  padding: 20px;
`;

const TitleMission = styled.h2`
  color: #363636;
  font-size: 24px;
  font-weight: 700;
  margin-right: 10px;
`;

const SubTitleMission = styled.h3`
  color: #5C5C5C;
  font-size: 11px;
  font-weight: 400;
`;

const Skills = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
  margin-top: 5px;
`;

const Skill = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  background: #1b3042;
  color: #fff;
  font-size: 8px;
  font-weight: 400;
  padding: 5px 10px;
`;

const DescriptionTitle = styled.div`
  color: #363636;
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 10px;
`;

const DescriptionContent = styled.p`
  color: #5C5C5C;
  font-size: 11px;
  font-weight: 400;
`;

const Input = styled.textarea`
  padding: 16px 16px 15px 16px;
  border: none;
  border-radius: 10px;
  width: 100%;
  min-height: 85px;
  background: #fff;

  color: #2c2c2c;
  font-size: 13.333px;
  font-weight: 600;

  &:focus,
  &:active {
    outline: none;
  }
`;

const Image = styled.img`
  border-radius: 5px;
  box-shadow: 2px 2px 5px 0px #D1D6D9;
  width: 64px;
  height: 64px;
`;

const ButtonVoir = styled(Button)`
  width: 68px;
  height: 28px;
  background:rgba(0, 0, 0, 0.05);
  color: #5C5C5C;
  font-size: 11px;
  font-weight: 400;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;

  &:hover{
    background:rgba(0, 0, 0, 0.25);
  }

`

const ConpanyRow = styled.div`
  display: flex;
  gap: 10px;
  color: #5C5C5C;
  font-size: 11px;
  font-weight: 400;
`;

const Icon = styled.div`
  display: flex;
  width: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ArrowIcon = styled.div`
  display: flex;
  padding: 3px;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.1);
`;


/*
 * Component.
 */

export const MissionPage: FC = () => {
  const [text, setText] = useState("");


  const skills = ["NLP", "AWS"]

  return (
    <Container>
      <Sider />
      <Content>
        <Header>
          <SearchBar />
        </Header>
        <MissionsContainer>
          <MissionsContainerColumn>
            <MissionsCard>
              <div style={{display:"flex"}}>
                <TitleMission>Data science NLP</TitleMission>
                <img src={heartTrue} alt="like"/>
              </div>
              <SubTitleMission>
                Aix en Provence | 1 week ago - 78 applicants
              </SubTitleMission>
              <Skills>
                {skills.map((e, i) => (
                  <Skill key={i}>{e}</Skill>
                ))}
              </Skills>
            </MissionsCard>
            <MissionsCard>
              <DescriptionTitle>
                Description
              </DescriptionTitle>
              <DescriptionContent>
              Doctolib est à la recherche d'un(e) Data Scientist expérimenté(e) en Traitement Automatique du Langage Naturel (NLP) pour rejoindre notre équipe dynamique dans le cadre d'un poste freelance. Nous nous engageons à transformer l'accès aux soins de santé en facilitant la connexion entre les professionnels de santé et les patients, et nous avons besoin de votre expertise pour y parvenir.
              Responsabilités :
              Développer et améliorer des modèles d'apprentissage automatique et de NLP pour une variété d'applications internes, y compris la compréhension du langage naturel, l'analyse de sentiments, la génération de texte, et plus encore.
              Travailler avec des ensembles de données volumineux et complexes pour tirer des informations utiles et aider à orienter les décisions stratégiques.
              Collaborer étroitement avec notre équipe d'ingénieurs et de spécialistes en santé pour comprendre les besoins et développer des solutions appropriées.
              Présenter les résultats d'analyse à diverses parties prenantes au sein de l'organisation.
              Qualifications :
              Un diplôme en informatique, en mathématiques, en statistique ou dans un domaine connexe.
              Au moins trois ans d'expérience dans l'application de techniques de NLP à de vastes ensembles de données.
              Une solide compétence en programmation, idéalement en Python.
              Une expérience avec des bibliothèques de NLP et de machine learning comme NLTK, SpaCy, Gensim, Scikit-learn, TensorFlow, PyTorch, etc.
              </DescriptionContent>
            </MissionsCard>
          </MissionsContainerColumn>
          <MissionsContainerColumn>
            <MissionsCard style={{gap:"15px"}}>
              <div style={{display:"flex", gap:"15px"}}>
                <Image src={"https://play-lh.googleusercontent.com/2ACsMp3soITRoUc7n6_xXqwAhYRSHqbHexJOz6x_1LznBX0FgBVxYVXePN_puZSi6TbS"} alt="Logo company"/>
                <div style={{display:"flex", flexDirection:"column"}}>
                  <DescriptionTitle>Doctolib</DescriptionTitle>
                  <ButtonVoir variant={ButtonVariantEnum.PRIMARY} onClick={() => {}} style={{display:"flex", justifyContent:"center", alignItems:"center", gap:"10px"}}>
                    Voir
                    <ArrowIcon>
                      <ArrowRightIcon/>
                    </ArrowIcon>
                  </ButtonVoir>
                </div>
              </div>
              
              <ConpanyRow>
                <Icon><img src={sac} alt="icon"/></Icon>
                <span>Full-time · Mid-Senior level</span>
              </ConpanyRow>
              <ConpanyRow>
                <Icon><img src={appart} alt="icon"/></Icon>
                <span>1,001-5,000 employees · Technology, Information and Internet</span>
              </ConpanyRow>
              <ConpanyRow>
                <Icon><img src={users} alt="icon"/></Icon>
                <span>26 personnes de votre école travaillent ici</span>
              </ConpanyRow>

            </MissionsCard>
            <MissionsCard style={{gap:"15px"}}>
              <DescriptionTitle>
                J'envoie ma candidature
              </DescriptionTitle>
              <Input
                placeholder=""
                value={text}
                onChange={(e) => setText(e.target.value)}
              />
              <Button variant={ButtonVariantEnum.PRIMARY} style={{width:"115px", display:"flex", justifyContent:"center", alignItems:"center", gap:"10px"}}>
                  Valider
                  <ArrowIcon>
                    <ArrowRightIcon/>
                  </ArrowIcon>
              </Button>
            </MissionsCard>
          </MissionsContainerColumn>
        </MissionsContainer>
      </Content>
    </Container>
  );
};
