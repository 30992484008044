/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ContentRequest } from './ContentRequest';
import {
    ContentRequestFromJSON,
    ContentRequestFromJSONTyped,
    ContentRequestToJSON,
} from './ContentRequest';
import type { TagRequest } from './TagRequest';
import {
    TagRequestFromJSON,
    TagRequestFromJSONTyped,
    TagRequestToJSON,
} from './TagRequest';

/**
 * 
 * @export
 * @interface FormationRequest
 */
export interface FormationRequest {
    /**
     * 
     * @type {Array<ContentRequest>}
     * @memberof FormationRequest
     */
    title?: Array<ContentRequest>;
    /**
     * 
     * @type {Array<ContentRequest>}
     * @memberof FormationRequest
     */
    summary?: Array<ContentRequest>;
    /**
     * 
     * @type {Array<ContentRequest>}
     * @memberof FormationRequest
     */
    assignment?: Array<ContentRequest>;
    /**
     * 
     * @type {Array<ContentRequest>}
     * @memberof FormationRequest
     */
    prerequisite?: Array<ContentRequest>;
    /**
     * 
     * @type {string}
     * @memberof FormationRequest
     */
    target?: FormationRequestTargetEnum;
    /**
     * 
     * @type {Set<TagRequest>}
     * @memberof FormationRequest
     */
    tags?: Set<TagRequest>;
}


/**
 * @export
 */
export const FormationRequestTargetEnum = {
    Developer: 'DEVELOPER',
    Functional: 'FUNCTIONAL',
    Technical: 'TECHNICAL',
    Architecture: 'ARCHITECTURE'
} as const;
export type FormationRequestTargetEnum = typeof FormationRequestTargetEnum[keyof typeof FormationRequestTargetEnum];


/**
 * Check if a given object implements the FormationRequest interface.
 */
export function instanceOfFormationRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FormationRequestFromJSON(json: any): FormationRequest {
    return FormationRequestFromJSONTyped(json, false);
}

export function FormationRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): FormationRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'title': !exists(json, 'title') ? undefined : ((json['title'] as Array<any>).map(ContentRequestFromJSON)),
        'summary': !exists(json, 'summary') ? undefined : ((json['summary'] as Array<any>).map(ContentRequestFromJSON)),
        'assignment': !exists(json, 'assignment') ? undefined : ((json['assignment'] as Array<any>).map(ContentRequestFromJSON)),
        'prerequisite': !exists(json, 'prerequisite') ? undefined : ((json['prerequisite'] as Array<any>).map(ContentRequestFromJSON)),
        'target': !exists(json, 'target') ? undefined : json['target'],
        'tags': !exists(json, 'tags') ? undefined : (new Set((json['tags'] as Array<any>).map(TagRequestFromJSON))),
    };
}

export function FormationRequestToJSON(value?: FormationRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'title': value.title === undefined ? undefined : ((value.title as Array<any>).map(ContentRequestToJSON)),
        'summary': value.summary === undefined ? undefined : ((value.summary as Array<any>).map(ContentRequestToJSON)),
        'assignment': value.assignment === undefined ? undefined : ((value.assignment as Array<any>).map(ContentRequestToJSON)),
        'prerequisite': value.prerequisite === undefined ? undefined : ((value.prerequisite as Array<any>).map(ContentRequestToJSON)),
        'target': value.target,
        'tags': value.tags === undefined ? undefined : (Array.from(value.tags as Set<any>).map(TagRequestToJSON)),
    };
}

