import { NodeViewContent, NodeViewWrapper } from '@tiptap/react'
import React from 'react'

import styled from 'styled-components';

const CodeBlock = styled(NodeViewWrapper)`
  position: relative;

  select {
    position: absolute;
    right: 0.5rem;
    top: 0.5rem;
  }
`;

export const CodeBlockComponent = ({ node: { attrs: { language: defaultLanguage } }, updateAttributes, extension }) => (
  <CodeBlock>
    <select contentEditable={false} defaultValue={defaultLanguage} onChange={event => updateAttributes({ language: event.target.value })}>
      <option value="null">
        auto
      </option>
      <option disabled>
        —
      </option>
      {extension.options.lowlight.listLanguages().map((lang, index) => (
        <option key={index} value={lang}>
          {lang}
        </option>
      ))}
    </select>
    <pre>
      <NodeViewContent as="code" />
    </pre>
  </CodeBlock>
)