export const getToken = () => {
  const token = localStorage.getItem('jwtToken');
  return token ? token : ''; // Retourne une chaîne vide si aucun token n'est trouvé
};

export function stringToPastelHex(str) {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }

  const hue = hash % 360; // Hue value between 0 and 359
  const saturation = 60; // Saturation at 60%
  const lightness = 85; // Lightness at 85% to ensure a pastel tone

  // Conversion de HSL à RGB, puis à une chaîne hexadécimale
  const rgb = hslToRgb(hue, saturation, lightness);
  return rgbToHex(rgb[0], rgb[1], rgb[2]);
}

// Fonction de conversion de HSL à RGB
function hslToRgb(h, s, l) {
  s /= 100;
  l /= 100;

  const k = n => (n + h / 30) % 12;
  const a = s * Math.min(l, 1 - l);
  const f = n => l - a * Math.max(-1, Math.min(k(n) - 3, 9 - k(n), 1));

  return [Math.round(255 * f(0)), Math.round(255 * f(8)), Math.round(255 * f(4))];
}

// Fonction de conversion de RGB à Hex
function rgbToHex(r, g, b) {
  return "#" + [r, g, b].map(x => {
      const hex = x.toString(16);
      return hex.length === 1 ? '0' + hex : hex;
  }).join('');
}

export function formatRelativeDate(date: Date): string {
  const now = new Date();
  const diffInSeconds = Math.round((now.getTime() - date.getTime()) / 1000);

  if (diffInSeconds < 60) {
    return 'Changed a few seconds ago';
  } else if (diffInSeconds < 3600) {
    return `Changed ${Math.round(diffInSeconds / 60)} minutes ago`;
  } else if (diffInSeconds < 86400) {
    return `Changed ${Math.round(diffInSeconds / 3600)} hours ago`;
  } else if (diffInSeconds < 604800) {
    return `Changed ${Math.round(diffInSeconds / 86400)} days ago`;
  } else if (diffInSeconds < 2592000) {
    return `Changed ${Math.round(diffInSeconds / 604800)} weeks ago`;
  } else if (diffInSeconds < 31536000) {
    return `Changed ${Math.round(diffInSeconds / 2592000)} months ago`;
  } else {
    return `Changed ${Math.round(diffInSeconds / 31536000)} years ago`;
  }
}