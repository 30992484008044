import React, { FC, useState } from "react";
import styled from "styled-components";
import { Button, ButtonVariantEnum } from "../buttons/Button";
import { ArrowRightIcon } from "@radix-ui/react-icons";
import { Avatar, List, Tooltip, Transfer, TransferProps } from "antd";
import { AntDesignOutlined, UserOutlined } from "@ant-design/icons";

/*
 * Props.
 */

/*
 * Styles.
 */

const Container = styled.div`
    display: flex;
    align-items: center;
    gap: 25px;
    flex-direction: column;
    width: 100%;
    height: 400px;
`;

const Header = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
    align-self: stretch;
`;

const Title = styled.h2`
    color: #1B3042;
    font-size: 16px;
    font-weight: 700;
`;

const Cell = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 400px;
  height: 400px;
`;

const Divider = styled.div`
    display: flex;
    padding-top: 15px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;
`;

const Line = styled.div`
    height: 1px;
    align-self: stretch;
    background: #D9D9D9;
`;

const ListStyle = styled(List)`
  width: 100%;
  height: 100%;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 5px;
    background: rgba(200,200,200,0.1);
    box-shadow: 1px 1px 4px 0px rgba(0, 0, 0, 0.25) inset;
  }

  &::-webkit-scrollbar-thumb {
    background: #AAA;
    border-radius: 5px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #888;
  }

  &::-webkit-scrollbar-button {
    display: none;
  }
`;



export const Teams: FC = () => {

  const data = [
    {
      title: 'Ant Design Title 1',
    },
    {
      title: 'Ant Design Title 2',
    },
    {
      title: 'Ant Design Title 3',
    },
    {
      title: 'Ant Design Title 4',
    },
    {
      title: 'Ant Design Title 3',
    },
    {
      title: 'Ant Design Title 4',
    },
  ];


  return (
    <Container>
      <Header>
        <Title>Teams</Title>
        <Divider><Line /></Divider>
      </Header>
      <ListStyle
        itemLayout="horizontal"
        dataSource={data}
        bordered
        // pagination={{
        //   onChange: (page) => {
        //     console.log(page);
        //   },
        //   pageSize: 3,
        // }}
        size="large"
        renderItem={(item, index) => (
          <List.Item actions={[<a key="list-loadmore-edit">edit</a>, <a key="list-loadmore-more">more</a>]}>
            <List.Item.Meta
              avatar={<Avatar.Group
                maxCount={2}
                size="large"
                maxStyle={{ color: '#f56a00', backgroundColor: '#fde3cf' }}
              >
                <Avatar src="https://api.dicebear.com/7.x/miniavs/svg?seed=3" />
                <Avatar style={{ backgroundColor: '#f56a00' }}>K</Avatar>
                <Avatar style={{ backgroundColor: '#f56a00' }}>K</Avatar>
                <Avatar style={{ backgroundColor: '#1677ff' }} icon={<AntDesignOutlined />} />
              </Avatar.Group>}
              title={<a href="https://ant.design">{item.title}</a>}
              description="Ant Design, a design language for background applications, is refined by Ant UED Team"
            />
          </List.Item>
        )}
      />
    </Container>
  );
};
