import React, { FC, useState } from "react";
import styled from "styled-components";
import { Modal } from "antd";
import { createNewUser, getUsersManagement } from "../../utils/userManager";
import { notification } from 'antd';
import { PageAdminUserResponse } from "../../api";

/*
 * Props.
 */

interface DataType {
    key: string;
    name: string;
    email: string;
    status: boolean;
    roles: string[];
  }

interface CreateUserProps {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    updateData: () => void;
}


/*
 * Styles.
 */

const Container = styled.div`
    display: flex;
    align-items: center;
    gap: 25px;
    flex-direction: column;
    width: 100%;
    height: 400px;
`;

const Header = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
    align-self: stretch;
`;

const Title = styled.h2`
    color: #1B3042;
    font-size: 16px;
    font-weight: 700;
`;


const Cell = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 15px;
  align-self: stretch;
`;

const TitleCell = styled.span`
    color: #727272;
    font-size: 16px;
    font-weight: 400;
    width: 40%;
`;

const Divider = styled.div`
    display: flex;
    padding-top: 15px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;
`;

const Line = styled.div`
    height: 1px;
    align-self: stretch;
    background: #D9D9D9;
`;

const Columns = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
`;

const InputContainer = styled.div`
    display: flex;
    width: 500px;
    height: 77px;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
`;

const InputTitle = styled.div`
    color: #363636;
    font-size: 12.8px;
    font-weight: 400;
`;

const Input = styled.input`
    display: flex;
    padding: 16px 21px 16px 20px;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    border-radius: 15px;
    border: none;
    background: #F7F7F7;

    &:focus,
    &:active {
      outline: none;
    }
`;

/*
 * Component.
 */

export const CreateUser: FC<CreateUserProps> = ({ open, setOpen, updateData}) => {
    const [prenom, setPrenom] = useState("");
    const [nom, setNom] = useState("");
    const [email, setEmail] = useState("");
    const [api, contextHolder] = notification.useNotification();

    const openNotification = () => {
        api.error({
            message: `Veuillez remplir tous les champs.`,
            description:
                'Veuillez remplir tous les champs pour créer un nouvel utilisateur.',
            placement: "top",
        });
    };

    const onOk = async () => {
        console.log("ok");
        if (prenom === "" || nom === "" || email === "") {
            openNotification()
            return;
        }
        else {
            await createNewUser(prenom, nom, email);
            await updateData();
            setNom("")
            setPrenom("")
            setEmail("")
            setOpen(false)
        }
    }

    return (
        <Modal
            title=""
            centered
            open={open}
            onOk={onOk}
            onCancel={() => setOpen(false)}
            width={1000}>
            {contextHolder}
            <Container>
                <Header>
                    <Title>Profile</Title>
                    <Divider><Line /></Divider>
                </Header>
                <Cell>
                    <TitleCell>Identité</TitleCell>
                    <Columns>
                        <InputContainer>
                            <InputTitle>Prénom</InputTitle>
                            <Input
                                type="text"
                                placeholder=""
                                value={prenom}
                                onChange={(e) => setPrenom(e.target.value)}
                            />
                        </InputContainer>
                        <InputContainer>
                            <InputTitle>Nom</InputTitle>
                            <Input
                                type="text"
                                placeholder=""
                                value={nom}
                                onChange={(e) => setNom(e.target.value)}
                            />
                        </InputContainer>
                    </Columns>
                </Cell>
                <Divider><Line /></Divider>
                <Cell>
                    <TitleCell>Contact</TitleCell>
                    <Columns>
                        <InputContainer>
                            <InputTitle>Email</InputTitle>
                            <Input
                                type="text"
                                placeholder=""
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </InputContainer>
                    </Columns>
                </Cell>
            </Container>
        </Modal>
    );
};
