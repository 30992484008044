import { FormationApi } from "../api/apis/FormationApi";
import { ChaptersRequest } from "../api/models/ChaptersRequest";
import { FormationRequest, FormationRequestTargetEnum } from "../api/models/FormationRequest";
import { TagRequestColorEnum, TagRequestFromJSON } from "../api/models/TagRequest";
import { Configuration } from "../api/runtime";
import { getToken } from "./token";


export const createFormation = async () => {
  const formationApi = new FormationApi(new Configuration({
    accessToken: getToken,
  }));
  const formationData: FormationRequest = {
    title: [{ rawContent: "", languageType: "FR" }],
    summary: [{ rawContent: "", languageType: "FR" }],
    assignment: [{ rawContent: "", languageType: "FR" }],
    prerequisite: [{ rawContent: "", languageType: "FR" }],
    target: FormationRequestTargetEnum.Developer,
    tags: new Set([TagRequestFromJSON({
      tag: 'ExampleTag',
      color: TagRequestColorEnum.Blue,
    })])
  };
  try {
    const response = await formationApi.createFormation({ formationRequest: formationData });
    console.log('Formation créée avec succès:', response);
    return response
  } catch (error) {
    console.error('Erreur lors de la création de la formation:', error);
    return null
  }
};

export const getAllFormation = async () => {
  const formationApi = new FormationApi(new Configuration({
    accessToken: getToken,
  }));
  try {
    const response = await formationApi.getFormation({ page: 0, size: 10 });
    console.log(response)
    if (response.content) {
      return response.content;
    }
    // setFormations(response.data); // Assurez-vous que cela correspond à la structure de votre réponse
  } catch (error) {
    console.error('Erreur lors de la récupération des formations:', error);
  }
  return null
}

export const getMyFormation = async () => {
  const formationApi = new FormationApi(new Configuration({
    accessToken: getToken,
  }));
  try {
    const response = await formationApi.getMyFormation({ page: 0, size: 10 });
    console.log(response)
    if (response.content) {
      return response.content;
    }
    // setFormations(response.data); // Assurez-vous que cela correspond à la structure de votre réponse
  } catch (error) {
    console.error('Erreur lors de la récupération des formations:', error);
  }
  return null
}

export const deleteFormation = async (formationId: string) => {
  if (!formationId) {
    console.error('ID de la formation manquant');
    return;
  }

  const formationApi = new FormationApi(new Configuration({
    accessToken: getToken,
  }));

  try {
    await formationApi.deleteFormationRaw({ formationId });
    console.log('Formation supprimée avec succès');
  } catch (error) {
    console.error('Erreur lors de la suppression de la formation:', error);
  }
};

export const getFormationDetails = async (formationId: string) => {
  if (!formationId) {
    console.error('ID de la formation manquant');
    return null;
  }

  const formationApi = new FormationApi(new Configuration({
    accessToken: getToken,
  }));

  try {
    const response = await formationApi.getFormationDetail({ formationId });
    console.log('Détails de la formation récupérés avec succès:', response);
    return response;
  } catch (error) {
    console.error('Erreur lors de la récupération des détails de la formation:', error);
    return null;
  }
};

export const updateFormation = async (formationId: string, updateData: FormationRequest) => {
  if (!formationId) {
    console.error('ID de la formation manquant');
    return;
  }

  const formationApi = new FormationApi(new Configuration({
    accessToken: getToken,
  }));

  try {
    const updateParams = {
      formationId: formationId,
      formationRequest: updateData
    };

    await formationApi.updateFormation(updateParams);
    console.log('Formation mise à jour avec succès');
  } catch (error) {
    console.error('Erreur lors de la mise à jour de la formation:', error);
  }
};

export const createChaptersForFormation = async (formationId: string, chaptersData: ChaptersRequest) => {
  if (!formationId) {
    console.error('ID de la formation manquant');
    return;
  }

  const formationApi = new FormationApi(new Configuration({
    accessToken: getToken,
  }));

  try {
    const createChaptersParams = {
      formationId: formationId,
      chaptersRequest: chaptersData
    };
    console.log(createChaptersParams);

    await formationApi.createChapters(createChaptersParams);
    console.log('Chapitres créés avec succès pour la formation');
  } catch (error) {
    console.error('Erreur lors de la création des chapitres pour la formation:', error);
  }
};


export const getChapters = async (formationId: string) => {
  if (!formationId) {
    console.error('Formation ID is missing');
    return [];
  }

  const formationApi = new FormationApi(new Configuration({
    accessToken: getToken,
  }));

  try {
    const response = await formationApi.getChapters({ formationId });
    console.log('Chapters retrieved successfully:', response);
    return response;
  } catch (error) {
    console.error('Error retrieving chapters:', error);
    return [];
  }
};