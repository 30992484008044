import React, { FC, useState } from "react";
import styled from "styled-components";
import { Button, ButtonVariantEnum } from "../buttons/Button";
import { ArrowRightIcon } from "@radix-ui/react-icons";
import { useTranslation } from 'react-i18next';

function LanguageSelector() {
  const { i18n } = useTranslation();

  const changeLanguage = (language) => {
    console.log(i18n);
    i18n.changeLanguage(language);
  };

  return (
    <div>
      <button onClick={() => changeLanguage('en')}>English</button>
      <button onClick={() => changeLanguage('fr')}>Français</button>
      <button onClick={() => changeLanguage('es')}>Español</button>
      <button onClick={() => changeLanguage('de')}>Deutsch</button>
    </div>
  );
}


/*
 * Props.
 */

/*
 * Styles.
 */

const Container = styled.div`
    display: flex;
    align-items: center;
    gap: 25px;
    flex-direction: column;
    width: 100%;
`;

const Header = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
    align-self: stretch;
`;

const Title = styled.h2`
    color: #1B3042;
    font-size: 16px;
    font-weight: 700;
`;

const Description = styled.span`
    color: #1B3042;
    font-size: 12px;
    font-weight: 400;
`;

const Cell = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 15px;
  align-self: stretch;
`;

const TitleCell = styled.span`
    color: #727272;
    font-size: 16px;
    font-weight: 400;
    width: 40%;
`;

const Divider = styled.div`
    display: flex;
    padding-top: 15px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;
`;

const Line = styled.div`
    height: 1px;
    align-self: stretch;
    background: #D9D9D9;
`;

const Columns = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
`;

const InputContainer = styled.div`
    display: flex;
    width: 500px;
    height: 77px;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
`;

const InputTitle = styled.div`
    color: #363636;
    font-size: 12.8px;
    font-weight: 400;
    // margin-left: 20px;
`;

const Input = styled.input`
    display: flex;
    padding: 16px 21px 16px 20px;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    border-radius: 15px;
    border: none;
    background: #F7F7F7;

    &:focus,
    &:active {
      outline: none;
    }
`;

const ArrowIcon = styled.div`
  display: flex;
  padding: 3px;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.1);
`;

export const Payment: FC = () => {
  const [nom, setNom] = useState("");
  const [number, setNumber] = useState("");
  const [date, setDate] = useState("");
  const [cvv, setCvv] = useState("");

  return (
    <Container>
        <Header>
            <Title>Méthode de payment</Title>
            <Description>Mettez à jour vos informations banquaire.</Description>
            <Divider><Line/></Divider>
        </Header>
        <Cell>
            <TitleCell>Détaille de la carte</TitleCell>
            <Columns>
              <InputContainer>
                <InputTitle>Nom sur la carte</InputTitle>
                <Input
                    type="text"
                    placeholder=""
                    value={nom}
                    onChange={(e) => setNom(e.target.value)}
                    />
              </InputContainer>
              <InputContainer>
                <InputTitle>Numero de la carte</InputTitle>
                <Input
                    type="text"
                    placeholder=""
                    value={number}
                    onChange={(e) => setNumber(e.target.value)}
                    />
              </InputContainer>
            </Columns>
            <Columns>
              <InputContainer style={{width:"200px"}}>
                <InputTitle>Date d’expiration</InputTitle>
                <Input
                    type="text"
                    placeholder=""
                    value={date}
                    onChange={(e) => setDate(e.target.value)}
                    />
              </InputContainer>
              <InputContainer style={{width:"200px"}}>
                <InputTitle>CVV</InputTitle>
                <Input
                    type="text"
                    placeholder=""
                    value={cvv}
                    onChange={(e) => setCvv(e.target.value)}
                    />
              </InputContainer>
            </Columns>
            <LanguageSelector/>
        </Cell>
        <Cell style={{justifyContent: "flex-end", marginRight:"10%"}}>
          <Button variant={ButtonVariantEnum.PRIMARY} style={{width:"115px", display:"flex", justifyContent:"center", alignItems:"center", gap:"10px"}}>
            Valider
            <ArrowIcon>
                <ArrowRightIcon/>
            </ArrowIcon>
          </Button>
        </Cell>
    </Container>
  );
};
