/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  RoleRequest,
  RoleResponse,
} from '../models/index';
import {
    RoleRequestFromJSON,
    RoleRequestToJSON,
    RoleResponseFromJSON,
    RoleResponseToJSON,
} from '../models/index';

export interface CreateRoleRequest {
    roleRequest: RoleRequest;
}

export interface DeleteRoleRequest {
    roleId: string;
}

export interface UpdateRoleRequest {
    roleId: string;
    roleRequest: RoleRequest;
}

/**
 * 
 */
export class AdminUserRoleManagementApi extends runtime.BaseAPI {

    /**
     */
    async createRoleRaw(requestParameters: CreateRoleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RoleResponse>> {
        if (requestParameters.roleRequest === null || requestParameters.roleRequest === undefined) {
            throw new runtime.RequiredError('roleRequest','Required parameter requestParameters.roleRequest was null or undefined when calling createRole.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer-key", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/admin/user-role-management/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RoleRequestToJSON(requestParameters.roleRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RoleResponseFromJSON(jsonValue));
    }

    /**
     */
    async createRole(requestParameters: CreateRoleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RoleResponse> {
        const response = await this.createRoleRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async deleteRoleRaw(requestParameters: DeleteRoleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RoleResponse>> {
        if (requestParameters.roleId === null || requestParameters.roleId === undefined) {
            throw new runtime.RequiredError('roleId','Required parameter requestParameters.roleId was null or undefined when calling deleteRole.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer-key", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/admin/user-role-management/{roleId}`.replace(`{${"roleId"}}`, encodeURIComponent(String(requestParameters.roleId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RoleResponseFromJSON(jsonValue));
    }

    /**
     */
    async deleteRole(requestParameters: DeleteRoleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RoleResponse> {
        const response = await this.deleteRoleRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async updateRoleRaw(requestParameters: UpdateRoleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RoleResponse>> {
        if (requestParameters.roleId === null || requestParameters.roleId === undefined) {
            throw new runtime.RequiredError('roleId','Required parameter requestParameters.roleId was null or undefined when calling updateRole.');
        }

        if (requestParameters.roleRequest === null || requestParameters.roleRequest === undefined) {
            throw new runtime.RequiredError('roleRequest','Required parameter requestParameters.roleRequest was null or undefined when calling updateRole.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer-key", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/admin/user-role-management/{roleId}`.replace(`{${"roleId"}}`, encodeURIComponent(String(requestParameters.roleId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: RoleRequestToJSON(requestParameters.roleRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RoleResponseFromJSON(jsonValue));
    }

    /**
     */
    async updateRole(requestParameters: UpdateRoleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RoleResponse> {
        const response = await this.updateRoleRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
