import React from 'react';
import styled from 'styled-components';
import fuse from "../../assets/icons/fuse.svg"
import lock from "../../assets/icons/lock.svg"
import check from "../../assets/icons/check.svg"

// Définition des types pour les props du composant ProgressBar
interface ProgressBarProps {
    steps: number;
    currentStep: number;
}

// Création des composants Styled
const ProgressBarContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

const Step = styled.div<{ isActive: boolean; isCompleted: boolean }>`
  flex: 1;
  height: 3px;
  background: ${props => props.isActive ? "linear-gradient(270deg, #028CB5 0%, #01A89D 103.63%)" : props.isCompleted  ? '#01A89D' : '#363636'};

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }
`;

const StepIcon = styled.div<{ isActive: boolean; isCompleted: boolean }>`
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: ${props => props.isCompleted ? '#01A89D' : props.isActive ? '#028CB5' : '#363636'};
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
//   margin: 0 auto;
`;

const Icon = styled.img`
    width: 12px;
    height: 12px;
`;

// Composant ProgressBar
const ProgressBar: React.FC<ProgressBarProps> = ({ steps, currentStep }) => {
    return (
        <ProgressBarContainer>
            {[...Array(steps)].map((_, index) => {
                const isCompleted = index < currentStep;
                const isActive = index === currentStep;

                return (
                    <React.Fragment key={index}>
                        {index > 0 && <Step isCompleted={isCompleted} isActive={isActive} />}
                        <StepIcon isActive={isActive} isCompleted={isCompleted}>
                            {/* Ici vous pouvez insérer vos icônes selon le statut de l'étape */}
                            {isActive ? <Icon src={fuse}/> :isCompleted ? <Icon src={check}/> : <Icon src={lock}/>}
                        </StepIcon>
                    </React.Fragment>
                );
            })}
        </ProgressBarContainer>
    );
};

export default ProgressBar;
