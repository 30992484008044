import { CreateSubscriptionRequest, GetSubscriptionDetailRequest, SubscriptionApi } from "../api/apis/SubscriptionApi";
import { SubscriptionRequest } from "../api/models/SubscriptionRequest";
import { Configuration } from "../api/runtime";
import { getToken } from "./token";


const api = new SubscriptionApi(new Configuration({
    accessToken: getToken,
  }));

export const createSubscriptionFunction = async (userId: string, formationId: string, sealed: boolean, saved: boolean) => {
  try {
    // Préparation de l'objet SubscriptionRequest
    const subscriptionData: SubscriptionRequest = {
      userId: userId,
      formationId: formationId,
      sealed: sealed,
      saved: saved
    };

    // Création de l'objet CreateSubscriptionRequest
    const request: CreateSubscriptionRequest = {
      subscriptionRequest: subscriptionData,
    };
    console.log('Création de l’abonnement:', request);

    // Appel de la méthode createSubscription de l'API
    const response = await api.createSubscription(request);

    // Traitement de la réponse
    console.log('Abonnement créé avec succès:', response);
  } catch (error) {
    // Gestion des erreurs
    console.error('Erreur lors de la création de l’abonnement:', error);
  }
};

export const getSubscriptionFunction = async () =>  {
  try {
    const response = await api.getSubscription();
    console.log('Abonnement récupéré avec succès:', response);
    return response;
  } catch (error) {
    console.error('Erreur lors de la récupération de l’abonnement:', error);
    return [];
  }
};

export const getSubscriptionByID = async (subscriptionId: string) =>  {
  try {

    const request: GetSubscriptionDetailRequest = {
      subscriptionId: subscriptionId,
    };
    const response = await api.getSubscriptionDetail(request);
    console.log('Abonnement récupéré avec succès:', response);
    return response;
  } catch (error) {
    console.error('Erreur lors de la récupération de l’abonnement:', error);
    return null;
  }
}