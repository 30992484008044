import React, { FC, useState } from 'react';
import styled from 'styled-components';


const CollapseContainer = styled.div<{ isFirst?: boolean; isLast?: boolean, index1?: boolean }>`
    font-size: 0.8rem;
    width: 100%;
    border: 1px solid #ccc;
    border-top: ${({ isFirst, isLast, index1 }) => ((isFirst && !isLast) || index1 ? '1px solid #ccc' : 'none')};
    border-radius: ${props => (props.index1 ? '5px': props.isFirst ? '5px 5px 0 0' : props.isLast ? '0 0 5px 5px' : '0')};
    overflow: hidden;
    color: #5C5C5C;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    font-family: Poppins;
`;

const CollapseHeader = styled.div`
    cursor: pointer;
    font-weight: 500;
    padding: 5px 10px;
    background-color: #fbfbfb;
`;

const CollapseContent = styled.div<{ isOpen: boolean }>`
    display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
    padding: 5px 10px;
    border-top: ${({ isOpen }) => (isOpen ? '1px solid #ccc' : 'none')};
`;

interface CollapseProps {
    title: string;
    isFirst?: boolean;
    isLast?: boolean;
    index1?: boolean;
    children: React.ReactNode;
}

export const Collapse: React.FC<CollapseProps> = ({ title, isFirst, isLast, children, index1 }) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleCollapse = () => {
        setIsOpen(!isOpen);
    };

    return (
        <CollapseContainer isFirst={isFirst} isLast={isLast} index1={index1}>
            <CollapseHeader onClick={toggleCollapse}>{title}</CollapseHeader>
            <CollapseContent isOpen={isOpen}>{children}</CollapseContent>
        </CollapseContainer>
    );
};

interface CollapseListProps {
    list: { title: string; content: string }[];
}

export const CollapseList: FC<CollapseListProps> = ({ list }) => {
    return (
        <>
            {list.map((item, index) => (
                <Collapse
                    key={index}
                    title={item.title}
                    isFirst={index === 0}
                    isLast={index === list.length - 1}
                    index1={list.length === 1}
                >
                    {item.content}
                </Collapse>
            ))}
        </>
    );
};