import React, { FC } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { Button, ButtonVariantEnum } from "../buttons/Button";


/*
 * Styles.
 */

const Container = styled.div`
    display: flex;
    height: 125px;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    border-radius: 5px;
    background: rgba(209, 214, 217, 0.52);
    transition:  0.3s ease;
    border: 1px solid #D2D6DB;

    &:hover{
        scale: 1.01;
    }
`;

const ProgressBar = styled.div<{ $pc: number }>`
    width: ${(p) => (p.$pc)}%;
    height: 9px;
    flex-shrink: 0;
    border-radius: 5px ${(p) => (p.$pc === 100 ? "5": "0")}px 0px 0px;
    background: ${(p) => (p.$pc === 100 ? "rgba(1, 168, 157, 0.30)" : "rgba(2, 140, 181, 0.30)")};
`;

const Body = styled.div`
    display: flex;
    height: 116px;
    padding: 20px;
    align-items: center;
    flex-shrink: 0;
    align-self: stretch;
    border-radius: 0px 0px 5px 5px;
    border-bottom: 1px solid #D2D6DB;
    background: #FFF;
`;

const Image = styled.div<{ $url: string }>`
    width: 78px;
    height: 78px;
  background: url(${(p) => (p.$url)}) lightgray 50% / cover no-repeat;
  border-radius: 5px;
`;

const TextContainer = styled.div`
    display: flex;
    padding: 0px 10px;
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
    flex: 1 0 0;
    align-self: stretch;
`;

const Title = styled.div`
    align-self: stretch;
    color: #363636;
    font-family: Poppins;
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
`;

const SubTitle = styled.div`
    align-self: stretch;
    color: #727272;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
`;

const Separator = styled.div`
    width: 1px;
    height: 78px;
    border-radius: 5px;
    background: #D9D9D9;
`;

const ButtonContainer = styled.div`
    display: flex;
    width: 252px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    align-self: stretch;
`;

const OptionContainer = styled.div`
    display: flex;
    width: 14px;
    flex-direction: column;
    align-items: center;
    gap: 2px;
    align-self: stretch;
`;

const Dot = styled.div`
    border-radius: 1000px;
    background: #028CB5;
    width: 5px;
    height: 5px;
    cursor:pointer;
`;

/*
 * Props.
 */

interface CardProps {
  title: string;
  subtitle: string;
  url: string,
  id: string,
}

/*
 * Component.
 */

export const FormationCard: FC<CardProps> = ({
  title,
  subtitle,
  url,
  id,
}) => {
  const navigate = useNavigate();
  const nombreAleatoire = Math.floor(Math.random() * 101);

  const handleButtonClick = (url: string) => {
    navigate(url);
  };
  return (
    <Container>
      <ProgressBar $pc={nombreAleatoire}/>
      <Body>
        <Image $url={url}/>
        <TextContainer>
            <Title>{title}</Title>
            <SubTitle>{subtitle}</SubTitle>
        </TextContainer>
        <Separator/>
        <ButtonContainer>
            <Button variant={ButtonVariantEnum.PRIMARY} onClick={() => { handleButtonClick("/articles/"+id)}}>Commencer</Button>
        </ButtonContainer>
        <OptionContainer>
            <Dot/>
            <Dot/>
            <Dot/>
        </OptionContainer>
      </Body>
    </Container>
  );
};
