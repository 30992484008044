/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  PageProjectLightResponse,
  ProjectCreateRequest,
  ProjectLightResponse,
  ProjectMemberRequest,
  ProjectResponse,
} from '../models/index';
import {
    PageProjectLightResponseFromJSON,
    PageProjectLightResponseToJSON,
    ProjectCreateRequestFromJSON,
    ProjectCreateRequestToJSON,
    ProjectLightResponseFromJSON,
    ProjectLightResponseToJSON,
    ProjectMemberRequestFromJSON,
    ProjectMemberRequestToJSON,
    ProjectResponseFromJSON,
    ProjectResponseToJSON,
} from '../models/index';

export interface CreateProjectRequest {
    projectCreateRequest: ProjectCreateRequest;
}

export interface GetProjectRequest {
    page: number;
    size: number;
}

export interface GetProjectDetailRequest {
    projectId: string;
}

export interface SetProjectMembersRequest {
    projectId: string;
    projectMemberRequest: ProjectMemberRequest;
}

/**
 * 
 */
export class ProjectApi extends runtime.BaseAPI {

    /**
     */
    async createProjectRaw(requestParameters: CreateProjectRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProjectLightResponse>> {
        if (requestParameters.projectCreateRequest === null || requestParameters.projectCreateRequest === undefined) {
            throw new runtime.RequiredError('projectCreateRequest','Required parameter requestParameters.projectCreateRequest was null or undefined when calling createProject.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer-key", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/projects/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ProjectCreateRequestToJSON(requestParameters.projectCreateRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProjectLightResponseFromJSON(jsonValue));
    }

    /**
     */
    async createProject(requestParameters: CreateProjectRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProjectLightResponse> {
        const response = await this.createProjectRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getProjectRaw(requestParameters: GetProjectRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PageProjectLightResponse>> {
        if (requestParameters.page === null || requestParameters.page === undefined) {
            throw new runtime.RequiredError('page','Required parameter requestParameters.page was null or undefined when calling getProject.');
        }

        if (requestParameters.size === null || requestParameters.size === undefined) {
            throw new runtime.RequiredError('size','Required parameter requestParameters.size was null or undefined when calling getProject.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer-key", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/projects/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PageProjectLightResponseFromJSON(jsonValue));
    }

    /**
     */
    async getProject(requestParameters: GetProjectRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PageProjectLightResponse> {
        const response = await this.getProjectRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getProjectDetailRaw(requestParameters: GetProjectDetailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProjectResponse>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling getProjectDetail.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer-key", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/projects/{projectId}`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProjectResponseFromJSON(jsonValue));
    }

    /**
     */
    async getProjectDetail(requestParameters: GetProjectDetailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProjectResponse> {
        const response = await this.getProjectDetailRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async setProjectMembersRaw(requestParameters: SetProjectMembersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProjectLightResponse>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling setProjectMembers.');
        }

        if (requestParameters.projectMemberRequest === null || requestParameters.projectMemberRequest === undefined) {
            throw new runtime.RequiredError('projectMemberRequest','Required parameter requestParameters.projectMemberRequest was null or undefined when calling setProjectMembers.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer-key", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/projects/{projectId}/members`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ProjectMemberRequestToJSON(requestParameters.projectMemberRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProjectLightResponseFromJSON(jsonValue));
    }

    /**
     */
    async setProjectMembers(requestParameters: SetProjectMembersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProjectLightResponse> {
        const response = await this.setProjectMembersRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
