import React from 'react';
import * as Popover from '@radix-ui/react-popover';
import styled, { keyframes } from 'styled-components';
import { ChevronDownIcon, Cross2Icon } from '@radix-ui/react-icons';


const slideUpAndFade = keyframes`
  0% { opacity: 0; transform: translateY(2px) }
  100% { opacity: 1; transform: translateY(0) }
`;

const slideRightAndFade = keyframes`
  0% { opacity: 0; transform: translateX(-2px) }
  100% { opacity: 1; transform: translateX(0) }
`;

const slideDownAndFade = keyframes`
  0% { opacity: 0; transform: translateY(-2px) }
  100% { opacity: 1; transform: translateY(0) }
`;

const slideLeftAndFade = keyframes`
  0% { opacity: 0; transform: translateX(2px) }
  100% { opacity: 1; transform: translateX(0) }
`;

const PopoverContent = styled(Popover.Content)`
  border-radius: 4px;
  padding: 20px;
  width: 260px;
  background-color: white;
  box-shadow: 0px 10px 38px -10px rgba(22, 23, 24, 0.35), 0px 10px 20px -15px rgba(22, 23, 24, 0.2);
  animation-duration: 400ms;
  animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
  will-change: transform, opacity;

  &[data-state="open"] {
    &[data-side="top"] { animation-name: ${slideDownAndFade}; }
    &[data-side="right"] { animation-name: ${slideLeftAndFade}; }
    &[data-side="bottom"] { animation-name: ${slideUpAndFade}; }
    &[data-side="left"] { animation-name: ${slideRightAndFade}; }
  }

  &:focus {
    box-shadow: 0px 10px 38px -10px rgba(22, 23, 24, 0.35), 0px 10px 20px -15px rgba(22, 23, 24, 0.2), 0 0 0 2px #01A89D;
  }
`;

const PopoverArrow = styled(Popover.Arrow)`
  fill: white;
`;

const PopoverClose = styled(Popover.Close)`
  all: unset;
  border-radius: 100%;
  height: 25px;
  width: 25px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: #5c5c5c;
  position: absolute;
  top: 5px;
  right: 5px;

  &:hover { background-color: #F4FCFA; }
  &:focus { box-shadow: 0 0 0 2px #01A89D; }
`;

const Flex = styled.div`
  display: flex;
`;

const IconButton = styled.button`
  display:flex;
  gap:11px;
  align-items: center;
  justify-content: center;
  
  color: #5c5c5c;
  font-size: 15px;
  font-weight: 400;
`;

const Fieldset = styled.fieldset`
  all: unset;
  display: flex;
  gap: 20px;
  align-items: center;
`;

const Label = styled.label`
  font-size: 13px;
  color: #5c5c5c;
  width: 75px;
`;

const Input = styled.input`
  all: unset;
  width: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  border-radius: 4px;
  padding: 0 10px;
  font-size: 13px;
  line-height: 1;
  color: #5c5c5c;
  box-shadow: 0 0 0 1px #01A89D;
  height: 25px;

  &:focus { box-shadow: 0 0 0 2px #9D4EDD; }
`;

const Text = styled.p`
  margin: 0;
  color: #F9FAFB;
  font-size: 15px;
  line-height: 19px;
  font-weight: 500;
`;



export const PopoverSetting = () => (
  <Popover.Root>
    <Popover.Trigger asChild>
      <IconButton aria-label="Update dimensions">
        Options
        <ChevronDownIcon />
      </IconButton>
    </Popover.Trigger>

    <Popover.Portal>
      <PopoverContent sideOffset={5}>

        <Flex style={{ flexDirection: 'column', gap: 10 }}>
          <Text style={{ marginBottom: 10 }}>Dimensions</Text>
          <Fieldset>
            <Label htmlFor="width">Width</Label>
            <Input id="width" defaultValue="100%" />
          </Fieldset>
          <Fieldset>
            <Label htmlFor="maxWidth">Max. width</Label>
            <Input id="maxWidth" defaultValue="300px" />
          </Fieldset>
          <Fieldset>
            <Label htmlFor="height">Height</Label>
            <Input id="height" defaultValue="25px" />
          </Fieldset>
          <Fieldset>
            <Label htmlFor="maxHeight">Max. height</Label>
            <Input id="maxHeight" defaultValue="none" />
          </Fieldset>
        </Flex>
        <PopoverClose aria-label="Close">
          <Cross2Icon />
        </PopoverClose>
        <PopoverArrow />

      </PopoverContent>
    </Popover.Portal>

  </Popover.Root>
);

