/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AdminUserCreateRequest
 */
export interface AdminUserCreateRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminUserCreateRequest
     */
    firstname?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminUserCreateRequest
     */
    lastname?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminUserCreateRequest
     */
    email?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminUserCreateRequest
     */
    roles?: Array<string>;
}

/**
 * Check if a given object implements the AdminUserCreateRequest interface.
 */
export function instanceOfAdminUserCreateRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function AdminUserCreateRequestFromJSON(json: any): AdminUserCreateRequest {
    return AdminUserCreateRequestFromJSONTyped(json, false);
}

export function AdminUserCreateRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): AdminUserCreateRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'firstname': !exists(json, 'firstname') ? undefined : json['firstname'],
        'lastname': !exists(json, 'lastname') ? undefined : json['lastname'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'roles': !exists(json, 'roles') ? undefined : json['roles'],
    };
}

export function AdminUserCreateRequestToJSON(value?: AdminUserCreateRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'firstname': value.firstname,
        'lastname': value.lastname,
        'email': value.email,
        'roles': value.roles,
    };
}

