import React, { FC, useState } from "react";
import styled from "styled-components";
import { Button, ButtonVariantEnum } from "../buttons/Button";
import { ArrowRightIcon } from "@radix-ui/react-icons";

/*
 * Props.
 */

/*
 * Styles.
 */

const Container = styled.div`
    display: flex;
    align-items: center;
    gap: 25px;
    flex-direction: column;
    width: 100%;
`;

const Header = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
    align-self: stretch;
`;

const Title = styled.h2`
    color: #1B3042;
    font-size: 16px;
    font-weight: 700;
`;

const Description = styled.span`
    color: #1B3042;
    font-size: 12px;
    font-weight: 400;
`;

const Cell = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 15px;
  align-self: stretch;
`;

const TitleCell = styled.span`
    color: #727272;
    font-size: 16px;
    font-weight: 400;
    width: 40%;
`;

const Divider = styled.div`
    display: flex;
    padding-top: 15px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;
`;

const Line = styled.div`
    height: 1px;
    align-self: stretch;
    background: #D9D9D9;
`;

const Columns = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
`;

const InputContainer = styled.div`
    display: flex;
    width: 500px;
    height: 77px;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
`;

const InputTitle = styled.div`
    color: #363636;
    font-size: 12.8px;
    font-weight: 400;
    // margin-left: 20px;
`;

const Input = styled.input`
    display: flex;
    padding: 16px 21px 16px 20px;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    border-radius: 15px;
    border: none;
    background: #F7F7F7;

    &:focus,
    &:active {
      outline: none;
    }
`;

const ArrowIcon = styled.div`
  display: flex;
  padding: 3px;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.1);
`;

export const Password: FC = () => {
  const [actualPassword, setActualPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [reNewPassword, setReNewPassword] = useState("");

  return (
    <Container>
        <Header>
            <Title>Change ton mot de passe</Title>
            <Description>Un mot de passe sécurisé : long, complexe, pas d'infos personnelles.</Description>
            <Divider><Line/></Divider>
        </Header>
        <Cell>
            <TitleCell>Current Password</TitleCell>
            <Columns>
              <InputContainer>
                <InputTitle>Mot de passe Actuel</InputTitle>
                <Input
                    type="password"
                    placeholder=""
                    value={actualPassword}
                    onChange={(e) => setActualPassword(e.target.value)}
                    />
              </InputContainer>
            </Columns>
        </Cell>
        <Divider><Line/></Divider>
        <Cell>
            <TitleCell>Change Password</TitleCell>
            <Columns>
              <InputContainer>
                <InputTitle>Nouveau mot de passe</InputTitle>
                <Input
                    type="password"
                    placeholder=""
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    />
              </InputContainer>
              <InputContainer>
                <InputTitle>Confimer mot de passe</InputTitle>
                <Input
                    type="password"
                    placeholder=""
                    value={reNewPassword}
                    onChange={(e) => setReNewPassword(e.target.value)}
                    />
              </InputContainer>
            </Columns>
        </Cell>
        <Cell style={{justifyContent: "flex-end", marginRight:"10%"}}>
          <Button variant={ButtonVariantEnum.PRIMARY} style={{width:"115px", display:"flex", justifyContent:"center", alignItems:"center", gap:"10px"}}>
            Valider
            <ArrowIcon>
                <ArrowRightIcon/>
            </ArrowIcon>
          </Button>
        </Cell>
    </Container>
  );
};
