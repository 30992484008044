import React, { forwardRef } from 'react';
import * as Select from '@radix-ui/react-select';
import styled from 'styled-components';
import { CheckIcon, ChevronDownIcon, ChevronUpIcon } from '@radix-ui/react-icons';

const villes = [
    "Paris",
    "Marseille",
    "Lyon",
    "Toulouse",
    "Nice",
    "Nantes",
    "Montpellier",
    "Strasbourg",
    "Bordeaux",
    "Lille",
    "Rennes",
    "Reims",
    "Saint-Étienne",
    "Le Havre",
    "Toulon",
    "Grenoble",
    "Dijon",
    "Angers",
    "Nîmes",
    "Villeurbanne",
  ]

/*
 * Styles.
 */

const SelectTrigger = styled(Select.SelectTrigger)`
  all: unset;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  line-height: 1;
  height: 35px;
  gap: 5px;
  color: #5c5c5c;
  &[data-placeholder] {
    color: 5c5c5c;
  }
`;

const SelectIcon = styled(Select.SelectIcon)`
  color: #5c5c5c;
`;

const SelectContent = styled(Select.Content)`
  overflow: hidden;
  background-color: white;
  border-radius: 6px;
  box-shadow: 0px 10px 38px -10px rgba(22, 23, 24, 0.35), 0px 10px 20px -15px rgba(22, 23, 24, 0.2);
`;

const SelectViewport = styled(Select.Viewport)`
  padding: 5px;
`;

export const SelectLoc = () => (
  <Select.Root>
    <SelectTrigger aria-label="Food">
      <Select.Value placeholder="Select a localisation…" />
      <SelectIcon>
        <ChevronDownIcon />
      </SelectIcon>
    </SelectTrigger>
    <Select.Portal>
      <SelectContent>
        <SelectScrollUpButton>
          <ChevronUpIcon />
        </SelectScrollUpButton>
        <SelectViewport>
          <Select.Group>
            <SelectItem value="Télétravail">Télétravail</SelectItem>
          </Select.Group>
          <SelectSeparator/>
          <Select.Group>
            {villes.map((e) => <SelectItem value={e}>{e}</SelectItem>)}
          </Select.Group>
        </SelectViewport>
        <SelectScrollDownButton>
          <ChevronDownIcon />
        </SelectScrollDownButton>
      </SelectContent>
    </Select.Portal>
  </Select.Root>
);

const StyledItem = styled(Select.Item)`
  font-size: 13px;
  line-height: 1;
  color: #5c5c5c;
  border-radius: 3px;
  display: flex;
  align-items: center;
  height: 25px;
  padding: 0 35px 0 25px;
  position: relative;
  user-select: none;
  &[data-disabled] {
    color: #E5E5E5;
    pointer-events: none;
  }
  &[data-highlighted] {
    outline: none;
    background-color: #01A89D;
    color: white;
  }
`;

// const SelectLabel = styled(Select.Label)`
//   padding: 0 25px;
//   font-size: 12px;
//   line-height: 25px;
//   color: #E5E5E5;
// `;

const SelectSeparator = styled(Select.Separator)`
  height: 1px;
  background-color: #01A89D;
  margin: 5px;
`;

const StyledItemIndicator = styled(Select.ItemIndicator)`
  position: absolute;
  left: 0;
  width: 25px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
`;

const scrollButtonStyles = `
  display: flex;
  align-items: center;
  justify-content: center;
  height: 25px;
  background-color: white;
  color: #01A89D;
  cursor: default;
`;

const SelectScrollUpButton = styled(Select.ScrollUpButton)`
  ${scrollButtonStyles}
`;

const SelectScrollDownButton = styled(Select.ScrollDownButton)`
  ${scrollButtonStyles}
`;


const SelectItem = forwardRef<HTMLDivElement, Select.ItemProps>((props, forwardedRef) => {
  return (
    <StyledItem {...props} ref={forwardedRef}>
      <Select.ItemText>{props.children}</Select.ItemText>
      <StyledItemIndicator>
        <CheckIcon />
      </StyledItemIndicator>
    </StyledItem>
  );
});
