import React from 'react';
import styled from 'styled-components';

interface TagProps {
    label: string;
    color?: string;
    handleClick?: () => void;
}

const TagContainer = styled.div`
    display: flex;
    border-radius: 5px;
    padding: 5px 10px;
    background: #fff;
    border: 1px solid #D2D6DB;
    flex-shrink: 0; /* Empêche la réduction de la carte */
    cursor:pointer;
    justify-content: center;
    align-items: center;

    color: #D2D6DB;
    font-size: 10px;

    font-weight: 200;
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;


    transition: background  0.1s ease;

    &:hover{
        background: #eeeeee;
    }
`;

const Tag: React.FC<TagProps> = ({ label, color, handleClick }) => {
    return <TagContainer color={color} onClick={handleClick}>{label}</TagContainer>;
};

export default Tag;