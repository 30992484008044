import { AdminUserRoleManagementApi } from "../api";
import { RoleApi } from "../api/apis/RoleApi";
import { Configuration } from "../api/runtime";
import { getToken } from "./token";

const apiRole = new RoleApi(new Configuration({
  accessToken: getToken,
}));

const apiAdminUserRoleManagementApi = new AdminUserRoleManagementApi(new Configuration({
  accessToken: getToken,
}));

export const getRoleCanAssign = async () => {
  try {
    const response = await apiRole.getRoleUserCanAssign();
    return response;
  } catch (error) {
    console.error('Erreur lors de la récupération des rôles:', error);
    return [];
  }
}

export const getRoles= async () => {
  try {
    const response = await apiRole.getRoles({ page: 0, size: 1000});
    return response;
  } catch (error) {
    console.error('Erreur lors de la récupération des rôles:', error);
    return [];
  }
}

export const getRights = async () => {
  try {
    const response = await apiRole.getRights();
    return response;
  } catch (error) {
    console.error('Erreur lors de la récupération des rôles:', error);
    return [];
  }
}

export const createRole = async (roleRequest) => {
  try {
    const body = {
      roleRequest: roleRequest
    }

    const response = await apiAdminUserRoleManagementApi.createRole(body);
    return response;
  } catch (error) {
    console.error('Erreur lors de la création du rôle:', error);
    return [];
  }
}