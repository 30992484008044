// LoginPage.tsx

import React, { useState, FormEvent, FC, useContext } from "react";
import styled from "styled-components";
import { Button, ButtonVariantEnum } from "../components/buttons/Button";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../auth/AuthContext.jsx"
import { AuthApi } from '../api/apis/AuthApi';
import { LoginRequest } from '../api/models/LoginRequest';

/*
 * Styles.
 */

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #E6F4F8;
`;

const Card = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 18px 28px;
  background-color: #fff;
  border-radius: 3px;
  box-shadow: 0px 5px 5px 2px rgba(0, 0, 0, 0.02);
`;

const ContainerLeft = styled.div`
  display: flex;
  min-width: 348.5px;
  padding: 12px;
  flex-direction: column;
  align-items: flex-start;
  gap: 50px;
  flex: 1 0 0;
`;

const ContainerMenu = styled.div`
  display: flex;
  padding-right: 0px;
  align-items: center;
  gap: 26px;
`;

const ButtonMenu = styled.div<{ $isToggle: boolean }>`
  display: flex;
  padding: 8px 15.81px 8px 16px;
  align-items: flex-start;
  border-radius: 10px;
  cursor: pointer;

  text-align: center;
  font-size: 13.333px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  background: ${(p) => (p.$isToggle ? "#F5F5F5" : "transparent")};
  color: ${(p) => (p.$isToggle ? "#000" : "#4D4D4D")};
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 30px;
  width: 100%;
`;

const Input = styled.input`
  padding: 16px 16px 15px 16px;
  border: none;
  border-radius: 10px;
  width: 100%;
  background: #f5f5f5;

  color: #2c2c2c;
  font-size: 13.333px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  &:focus,
  &:active {
    outline: none;
  }
`;

const ConnexionVia = styled.div`
  display: flex;
  min-width: 348.5px;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 0 0;
  padding: 12px 12px 101px 12px;
  gap: 20px;
`;

const Titre = styled.h2`
  color: #474747;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 20px;
`;

const Separateur = styled.div`
  border-radius: 99px;
  background: #f5f5f5;
  width: 3px;
  height: 277px;
  margin: auto;
`;

const ContainerSeparateur = styled.div`
  display: flex;
  min-width: 47px;
  padding: 12px 22px;
  align-items: flex-start;
`;

/*
 * Component.
 */

export const LoginPage: FC = () => {
  const [toggle, setToggle] = useState(true);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [repassword, setRePassword] = useState("");

  const authApi = new AuthApi();

  const navigate = useNavigate();
  const { updateAuthState } = useContext(AuthContext);

  const handleLogin = async (e: FormEvent) => {
    e.preventDefault();

    const loginData: LoginRequest = {
      username: email,
      password: password,
    };

    try {
      const responseData = await authApi.login({ loginRequest: loginData });

      const token = responseData.token; // Assurez-vous que cette clé correspond à la réponse de votre serveur

      // Stocker le token JWT pour une utilisation ultérieure
      localStorage.setItem('jwtToken', token);
      console.log(token);
      await updateAuthState();
      // Rediriger l'utilisateur ou effectuer d'autres actions après la connexion
      navigate("/");
    } catch (error) {
      console.error('Erreur : ', error);
    }
  };

  const onSubmit = async (e: FormEvent) => {
    e.preventDefault();
    console.log(process.env.REACT_APP_API_URL)
  };


  const handleConnexion = () => {
    setToggle(true);
  };

  const handleInscription = () => {
    setToggle(false);
  };

  return (
    <Container>
      <Card>
        <ContainerLeft>
          <ContainerMenu>
            <ButtonMenu $isToggle={toggle} onClick={handleConnexion}>
              Connexion
            </ButtonMenu>
            <ButtonMenu $isToggle={!toggle} onClick={handleInscription}>
              Inscription
            </ButtonMenu>
          </ContainerMenu>
          <Form onSubmit={toggle?handleLogin:onSubmit}>
            <Input
              type="text"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <Input
              type="password"
              placeholder="Mot de passe"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            {toggle === false ? (
              <Input
                type="password"
                placeholder=" Répéter le mot de passe"
                value={repassword}
                onChange={(e) => setRePassword(e.target.value)}
              />
            ) : null}
            <div style={{ width: "128px" }}>
              <Button variant={ButtonVariantEnum.PRIMARY}>
              {toggle === false ?
                "Valider"
                :  "Connect"
                }
              </Button>
            </div>
          </Form>
        </ContainerLeft>
        {/* <ContainerSeparateur>
          <Separateur />
        </ContainerSeparateur>
        <ConnexionVia>
          <Titre>Connexion via</Titre>
          <Button variant={ButtonVariantEnum.SECONDARY} onClick={handleLogin}>
            Google
          </Button>
          <Button variant={ButtonVariantEnum.SECONDARY} onClick={handleLogin}>
            Facebok
          </Button>
        </ConnexionVia> */}
      </Card>
    </Container>
  );
};

