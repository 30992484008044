import { ModuleApi, CreateModuleRequest } from '../api/apis/ModuleApi';
import { ContentRequestFromJSON, ContentRequestLanguageTypeEnum, TagRequestColorEnum, TagRequestFromJSON } from '../api/models';
import { Configuration } from "../api/runtime";
import { getToken } from './token';


export const createModule = async (title: string, content: string) => {

    const moduleApi = new ModuleApi(new Configuration({
        accessToken: getToken,
    }));

    const myTitle = ContentRequestFromJSON({
        languageType: ContentRequestLanguageTypeEnum.Fr, // Choisissez entre 'FR' et 'EN'
        rawContent: title,
    });

    const myContent = ContentRequestFromJSON({
        languageType: ContentRequestLanguageTypeEnum.Fr, // Choisissez entre 'FR' et 'EN'
        rawContent: content, // Le contenu réel
    });

    const myTag = TagRequestFromJSON({
        tag: 'ExampleTag', // Nom du tag
        color: TagRequestColorEnum.Blue, // Choisissez parmi 'RED', 'BLUE', 'YELLOW', 'PURPLE', 'GREEN'
    });

    const moduleData = {
        title: [myTitle], // Liste des contenus pour le titre
        content: [myContent], // Liste des contenus pour le module
        estimatedTime: 120, // Temps estimé en minutes
        tags: new Set([myTag]), // Ensemble des tags
    };

    const requestParams: CreateModuleRequest = {
        moduleRequest: moduleData, // Les données du module à ajouter
    };

    try {
        const response = await moduleApi.createModule(requestParams);
        return response; // Retourne la réponse de l'API
    } catch (error) {
        console.error('Erreur lors de la création du module :', error);
        throw error; // Propage l'erreur pour une gestion ultérieure
    }
};

export const deleteModule = async (moduleId: string) => {
    if (!moduleId) {
        console.error('ID du module manquant');
        return;
    }

    const moduleApi = new ModuleApi(new Configuration({
        accessToken: getToken,
    }));

    try {
        await moduleApi.deleteModule({ moduleId });
        console.log('Module supprimé avec succès');
    } catch (error) {
        console.error('Erreur lors de la suppression du module:', error);
    }
};

export const GetAllModules = async () => {
    const api = new ModuleApi(new Configuration({
        accessToken: getToken,
    }));
    try {
        const response = await api.getModule({ page: 0, size: 10 });
        if (response.content) {
            return response.content;
        }
    } catch (error) {
        console.error('Erreur lors de la récupération des modules :', error);
    }
    return null
};

export const GetModule = async (moduleId: string) => {
    const api = new ModuleApi(new Configuration({
        accessToken: getToken,
    }));
    try {
        const response = await api.getModuleDetail({ moduleId });
        if (response) {
            return response;
        }
    } catch (error) {
        console.error('Erreur lors de la récupération du module :', error);
    }
    return null
};