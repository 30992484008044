import { ChatApi, ChatCreateResponse, ChatMessageRequest, GetChatRequest, PostChatWithMessagesRequest, SendMessageRequest } from "../api";
import { Configuration } from "../api/runtime";
import { getToken } from "./token";

const apiChat = new ChatApi(new Configuration({
  accessToken: getToken,
}));

export const getConversations = async () => {
  try {
    const body: GetChatRequest = {page: 0, size: 100};
    const response = await apiChat.getChat(body);
    return response;
  } catch (error) {
    console.error('Erreur lors de la récupération des rôles:', error);
    return [];
  }
}

export const getMessages = async (chatId: string) => {
  try {
    const body = {page: 0, size: 100, chatId};
    const response = await apiChat.getChatMessages(body);
    return response;
  } catch (error) {
    console.error('Erreur lors de la récupération des rôles:', error);
    return [];
  }
}

export const sendMessage = async (chatId: string, message: string) => {
  try {
    const chatMessage: ChatMessageRequest = {message: message};
    const body:SendMessageRequest = {chatId, chatMessageRequest: chatMessage};
    const response = await apiChat.sendMessage(body);
    return response;
  } catch (error) {
    console.error('Erreur lors de la récupération des rôles:', error);
    return [];
  }
}

export const createConversation = async (message: string) => {
  try {
    const chatMessage: ChatMessageRequest = {message: message};
    const body:PostChatWithMessagesRequest = {chatMessageRequest: chatMessage};
    const response = await apiChat.postChatWithMessages(body);
    return response;
  } catch (error) {
    console.error('Erreur lors de la récupération des rôles:', error);
    return [];
  }
}

export const deleteConversation = async (chatId: string) => {
  try {
    const response = await apiChat.deleteChat({"chatId": chatId});
    return response;
  } catch (error) {
    console.error('Erreur lors de la récupération des rôles:', error);
    return [];
  }
}