import { BoldOutlined, ClearOutlined, ItalicOutlined, StrikethroughOutlined } from "@ant-design/icons";
import { BubbleMenu, FloatingMenu } from "@tiptap/react"
import React from 'react';
import { styled } from "styled-components";

const BubbleMenuStyle = styled(BubbleMenu)`
  display: flex;
  background-color: #fff;
  padding: 0.2rem;
  border: 1px solid #D2D6DB;
  border-radius: 5px;

  button {
    border-radius: 5px;
    background-color: #FFFFFF;
    color: #5C5C5C;
    padding: 5px 10px;
    cursor: pointer;
    border: 1px solid transparent;
  
    &:hover {
      border: 1px solid #D2D6DB;
      background-color: #F8F9FA;
    }
  
    &:disabled {
      cursor: not-allowed;
      opacity: 0.5;
    }

    // &:hover,
    // &.is-active {
    //   opacity: 1;
    // }
  }
`;

const FloatingMenuStyle = styled(FloatingMenu)`
  display: flex;
  background-color: #fff;
  padding: 0.2rem;
  border: 1px solid #D2D6DB;
  border-radius: 5px;

  button {
    border-radius: 5px;
    background-color: #FFFFFF;
    color: #5C5C5C;
    padding: 5px 10px;
    cursor: pointer;
    border: 1px solid transparent;
  
    &:hover {
      border: 1px solid #D2D6DB;
      background-color: #F8F9FA;
    }
  
    &:disabled {
      cursor: not-allowed;
      opacity: 0.5;
    }

    // &:hover,
    // &.is-active {
    //   opacity: 1;
    // }
  }
`;

interface MenuBarProps {
    editor: any
}

export const FloatingButton: React.FC<MenuBarProps> = ({ editor }) => {
    return (
        <>
            <BubbleMenuStyle tippyOptions={{ duration: 100 }} editor={editor}>

                <button
                    onClick={() => editor.chain().focus().toggleBold().run()}
                    disabled={
                        !editor.can()
                            .chain()
                            .focus()
                            .toggleBold()
                            .run()
                    }
                    className={editor.isActive('bold') ? 'is-active' : ''}
                >
                    <BoldOutlined />
                </button>
                <button
                    onClick={() => editor.chain().focus().toggleItalic().run()}
                    disabled={
                        !editor.can()
                            .chain()
                            .focus()
                            .toggleItalic()
                            .run()
                    }
                    className={editor.isActive('italic') ? 'is-active' : ''}
                >
                    <ItalicOutlined />

                </button>
                <button
                    onClick={() => editor.chain().focus().toggleStrike().run()}
                    disabled={
                        !editor.can()
                            .chain()
                            .focus()
                            .toggleStrike()
                            .run()
                    }
                    className={editor.isActive('strike') ? 'is-active' : ''}
                >
                    <StrikethroughOutlined />
                </button>


                <button onClick={() => editor.chain().focus().unsetAllMarks().run()}>
                    <ClearOutlined />
                </button>
            </BubbleMenuStyle>

            <FloatingMenuStyle className="floating-menu" tippyOptions={{ duration: 100 }} editor={editor}>
                <button
                    onClick={() => editor.chain().focus().toggleHeading({ level: 1 }).run()}
                    className={editor.isActive('heading', { level: 1 }) ? 'is-active' : ''}
                >
                    H1
                </button>
                <button
                    onClick={() => editor.chain().focus().toggleHeading({ level: 2 }).run()}
                    className={editor.isActive('heading', { level: 2 }) ? 'is-active' : ''}
                >
                    H2
                </button>
                <button
                    onClick={() => editor.chain().focus().toggleBulletList().run()}
                    className={editor.isActive('bulletList') ? 'is-active' : ''}
                >
                    Bullet List
                </button>
            </FloatingMenuStyle>
        </>
    )
}