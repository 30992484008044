import React, { FC, useState } from "react";
import styled from "styled-components";
import search from "../../assets/icons/searchbar/search.svg";
import loc from "../../assets/icons/searchbar/loc.svg";
import sup from "../../assets/icons/searchbar/sup.svg";
import setting from "../../assets/icons/searchbar/setting.svg";
import {SelectLoc} from "./Select";
import { PopoverSetting } from "./Popover";




/*
 * Styles.
 */

const Container = styled.div`
  display: flex;
  // justify-content: center;
  align-items: center;
  padding: 20px 10px;
  width: 60%;
  min-width: 300px;
  border-radius: 100px;
  background: #f3f4f5;
  height: 49px;
  position: relative;
`;

const SearchButton = styled.button`
  border-radius: 32px;
  background: #028CB5;
  display: flex;
  width: 126px;
  height: 43px;
  padding: 8px 11px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border: none;
  position: absolute;
  right: 0;
  margin: 0 2px;

  color: #fff;
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  cursor: pointer;

  transition: background-color 0.3s ease;

  &:hover {
    background-color:  rgba(2, 140, 181, 0.75);
  }
`;

const ContainerLeft = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
`;

const Separateur = styled.div`
  border-radius: 99px;
  background: #5c5c5c;
  width: 1px;
  height: 39px;
  margin: auto;
`;

const ContainerOption = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;

  color: #5c5c5c;
  font-size: 15px;
  font-weight: 400;
  line-height: normal;
  gap: 11px;
  margin-left: 1px;
  padding: 8px 11px;
  cursor: pointer;
  border-radius: 100px;

  transition: background-color 0.3s ease;

  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }
`;

const Input = styled.input`
  border: none;
  background: #f5f5f5;

  color: #363636;
  font-size: 15px;
  font-weight: 400;
  line-height: normal;
  padding: 0 11px;

  &:focus,
  &:active {
    outline: none;
  }
`;

/*
 * Component.
 */

export const SearchBar: FC = () => {
  const [text, setText] = useState("");
  return (
    <Container>
      <ContainerLeft>
        <ContainerOption>
          <img src={loc} alt="loc-icon" />
          <SelectLoc/>
        </ContainerOption>
        <Separateur />
        <ContainerOption>
          <img src={setting} alt="setting-icon" />
          <PopoverSetting/>
        </ContainerOption>
        <Separateur />
        <Input
          type="text"
          placeholder="Je recherche un mission..."
          value={text}
          onChange={(e) => setText(e.target.value)}
        />
      </ContainerLeft>
      <SearchButton>
        <img src={search} alt="search-icon" />
        Search
      </SearchButton>
    </Container>
  );
};
