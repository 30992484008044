import React, { FC, useEffect, useState } from "react";
import styled from "styled-components";
import { Sider } from "../components/sider/Sider";
import { useParams } from 'react-router-dom';

import { ModuleApi } from '../api/apis/ModuleApi';
import type { ModuleResponse, TagRequest } from '../api/models';
import { ContentRequestFromJSON, ContentRequestLanguageTypeEnum } from '../api/models';
import { getToken } from "../utils/token";
import { Configuration } from "../api/runtime";

import { message, Modal, Select, SelectProps } from 'antd';
import { BubbleMenu, EditorContent, extensions, FloatingMenu, useCurrentEditor, useEditor } from "@tiptap/react";
import { MenuBar } from "../components/editor/menubar";

import { Color } from '@tiptap/extension-color'
import ListItem from '@tiptap/extension-list-item'
import TextStyle from '@tiptap/extension-text-style'
import StarterKit from '@tiptap/starter-kit'

import CodeBlockLowlight from '@tiptap/extension-code-block-lowlight'
// import Document from '@tiptap/extension-document'
// import Paragraph from '@tiptap/extension-paragraph'
// import Text from '@tiptap/extension-text'
import { ReactNodeViewRenderer } from '@tiptap/react'
import css from 'highlight.js/lib/languages/css'
import js from 'highlight.js/lib/languages/javascript'
import ts from 'highlight.js/lib/languages/typescript'
import html from 'highlight.js/lib/languages/xml'
// load all highlight.js languages
import { lowlight } from 'lowlight'
import { CodeBlockComponent } from "../components/editor/CodeBlockComponent";
import { FloatingButton } from "../components/editor/FloatingButton";
import Highlight from '@tiptap/extension-highlight'
import TextAlign from '@tiptap/extension-text-align'
import Underline from '@tiptap/extension-underline'
import Dropcursor from '@tiptap/extension-dropcursor'
import Image from '@tiptap/extension-image'

import Table from '@tiptap/extension-table'
import TableCell from '@tiptap/extension-table-cell'
import TableHeader from '@tiptap/extension-table-header'
import TableRow from '@tiptap/extension-table-row'
import { Title } from "../components/editor/titre";
import Tag from "../components/editor/Tags";
import TagsManager from "../components/editor/TagsManager";
import { ButtonChat } from "../components/buttons/ButtonChat";


lowlight.registerLanguage('html', html)
lowlight.registerLanguage('css', css)
lowlight.registerLanguage('js', js)
lowlight.registerLanguage('ts', ts)

const CustomTableCell = TableCell.extend({
  addAttributes() {
    return {
      // extend the existing attributes …
      ...this.parent?.(),

      // and add a new one …
      backgroundColor: {
        default: null,
        parseHTML: element => element.getAttribute('data-background-color'),
        renderHTML: attributes => {
          return {
            'data-background-color': attributes.backgroundColor,
            style: `background-color: ${attributes.backgroundColor}`,
          }
        },
      },
    }
  },
})

export const myextensions = [
  Color.configure({ types: [TextStyle.name, ListItem.name] }),
  TextStyle.configure({ types: [ListItem.name] }),
  TextAlign.configure({
    types: ['heading', 'paragraph'],
  }),
  Highlight,
  StarterKit.configure({
    bulletList: {
      keepMarks: true,
      keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
    },
    orderedList: {
      keepMarks: true,
      keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
    },
  }),
  CodeBlockLowlight
    .extend({
      addNodeView() {
        return ReactNodeViewRenderer(CodeBlockComponent)
      },
    })
    .configure({ lowlight }),
  Underline,
  Image,
  Dropcursor,
  Table.configure({
    resizable: true,
  }),
  TableRow,
  TableHeader,
  // Default TableCell
  // TableCell,
  // Custom TableCell with backgroundColor attribute
  CustomTableCell,
]

/*
 * Styles.
 */

const Container = styled.div`
  display: flex;
  height: 100vh;
  width: 100vw;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  flex-grow: 1;
`;

const Body = styled.div`
  width: 100%;
  // flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  overflow-y: auto;
  padding: 10px 0;

  &::-webkit-scrollbar {
    height: 15px;
    width: 15px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 5px;
    background: rgba(200,200,200,0.1);
    // box-shadow: 1px 1px 4px 0px rgba(0, 0, 0, 0.25) inset;
  }

  &::-webkit-scrollbar-thumb {
    background: #D1D6D9;
    border-radius: 5px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #929597;
  }

  &::-webkit-scrollbar-button {
    display: none;
  }
`;

const EditContainer = styled.div`
  width: 760px;
  margin-bottom: 80px;
  // height: 200px;
  // height: 60%;
  // flex-grow: 1;
  // height: auto;

  color: #000;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;

  .ProseMirror {
    &:focus {
      border: none; // Retirer la bordure lors du focus
      outline: none; // Retirer l'outline pour éviter les bordures de focus par défaut du navigateur
    }
  }

  ul,
  ol {
    padding: 0 1rem;
  }

  h1, h2, h3, h4, h5, h6 {
    line-height: 1.1;
    color: #363636;
    font-family: Poppins;
  }

  code {
    background-color: rgba(97, 97, 97, 0.1);
    color: #616161;
  }

  pre {
    background: #1E2126;
    color: #FFF;
    font-family: 'JetBrainsMono', monospace;
    padding: 0.75rem 1rem;
    border-radius: 0.5rem;

    code {
      color: inherit;
      padding: 0;
      background: none;
      font-size: 0.8rem;

      .hljs-comment,
      .hljs-quote {
        color: #616161;
      }

      .hljs-variable,
      .hljs-template-variable,
      .hljs-attribute,
      .hljs-tag,
      .hljs-name,
      .hljs-regexp,
      .hljs-link,
      .hljs-name,
      .hljs-selector-id,
      .hljs-selector-class {
        color: #f98181;
      }

      .hljs-number,
      .hljs-meta,
      .hljs-built_in,
      .hljs-builtin-name,
      .hljs-literal,
      .hljs-type,
      .hljs-params {
        color: #fbbc88;
      }

      .hljs-string,
      .hljs-symbol,
      .hljs-bullet {
        color: #b9f18d;
      }

      .hljs-title,
      .hljs-section {
        color: #faf594;
      }

      .hljs-keyword,
      .hljs-selector-tag {
        color: #70cff8;
      }

      .hljs-emphasis {
        font-style: italic;
      }

      .hljs-strong {
        font-weight: 700;
      }
    }
  }

  img {
    max-width: 100%;
    height: auto;
  }

  blockquote {
    padding-left: 1rem;
    border-left: 2px solid rgba(13, 13, 13, 0.1);
  }

  hr {
    border: none;
    border-top: 2px solid rgba(13, 13, 13, 0.1);
    margin: 2rem 0;
  }
  .tiptap {
    table {
      border-collapse: collapse;
      table-layout: fixed;
      width: 100%;
      margin: 0;
      overflow: hidden;
  
      td,
      th {
        min-width: 1em;
        border: 2px solid #ced4da;
        padding: 3px 5px;
        vertical-align: top;
        box-sizing: border-box;
        position: relative;
  
        > * {
          margin-bottom: 0;
        }
      }
  
      th {
        font-weight: bold;
        text-align: left;
        background-color: #f1f3f5;
      }
  
      .selectedCell:after {
        z-index: 2;
        position: absolute;
        content: "";
        left: 0; right: 0; top: 0; bottom: 0;
        background: rgba(200, 200, 255, 0.4);
        pointer-events: none;
      }
  
      .column-resize-handle {
        position: absolute;
        right: -2px;
        top: 0;
        bottom: -2px;
        width: 4px;
        background-color: #adf;
        pointer-events: none;
      }
    }
  }
  
  .tableWrapper {
    overflow-x: auto;
  }
  
  .resize-cursor {
    cursor: ew-resize;
    cursor: col-resize;
  }
`;

const Header = styled.div`
  width: 100%;
  background-color: #F8F9FA;
`;

const TitleContainer = styled.div`
  width: 760px;
  // flex-grow: 1;
  // background-color: blue;
`;


/*
 * Component.
 */


export const EditModulePage: FC = () => {
  const { moduleId } = useParams();
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [tags, setTags] = useState<TagRequest[]>([]);
  const [messageApi, contextHolder] = message.useMessage();
  const key = 'updatable';
  const editor = useEditor(
    {
      content: content,
      extensions: myextensions
    }
  )

  const options: SelectProps['options'] = [];

  for (let i = 10; i < 36; i++) {
    options.push({
      value: i.toString(36) + i,
      label: i.toString(36) + i,
    });
  }

  const openMessage = () => {
    messageApi.open({
      key,
      type: 'loading',
      content: 'Loading...',
    });
    setTimeout(() => {
      messageApi.open({
        key,
        type: 'success',
        content: 'save!',
        duration: 2,
      });
    }, 1000);
  };

  useEffect(() => {
    const fetchModuleDetail = async () => {
      const api = new ModuleApi(new Configuration({
        accessToken: getToken,
      }));

      if (moduleId) {
        try {
          const response = await api.getModuleDetail({ moduleId });
          console.log(response)
          if (response.content) {
            console.log(response.content)
            setContent(response.content)
            console.log(editor)
          }
          if (response.title) {
            setTitle(response.title)
          }
          if (response.tags) {
            setTags(Array.from(response.tags))
          }
        } catch (error) {
          console.error('Erreur lors de la récupération des détails du module :', error);
        }
      }
    };

    if (moduleId) {
      fetchModuleDetail();
    }
  }, [moduleId]);

  useEffect(() => {
    // Gestionnaire d'événements pour Ctrl+S ou Cmd+S
    const handleKeyDown = (event: KeyboardEvent) => {
      if ((event.ctrlKey || event.metaKey) && event.key === 's') {
        event.preventDefault(); // Empêcher l'action par défaut de Ctrl+S
        saveModuleDetails();    // Sauvegarder les modifications
      }
    };

    // Ajouter l'écouteur d'événements lors du montage du composant
    window.addEventListener('keydown', handleKeyDown);

    // Nettoyer l'écouteur lors du démontage du composant
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [moduleId, title, content, tags]);


  const saveModuleDetails = async () => {
    const api = new ModuleApi(new Configuration({
      accessToken: getToken,
    }));

    const myTitle = ContentRequestFromJSON({
      languageType: ContentRequestLanguageTypeEnum.Fr, // Choisissez entre 'FR' et 'EN'
      rawContent: title,
    });

    const myContent = ContentRequestFromJSON({
      languageType: ContentRequestLanguageTypeEnum.Fr, // Choisissez entre 'FR' et 'EN'
      rawContent: editor ? editor.getHTML() : content, // Le contenu réel
    });


    const moduleData = {
      title: [myTitle], // Liste des contenus pour le titre
      content: [myContent], // Liste des contenus pour le module
      estimatedTime: 120, // Temps estimé en minutes
      tags: new Set(tags), // Ensemble des tags
    };


    if (moduleId) {
      try {
        await api.updateModule({ moduleId, moduleRequest: moduleData });
        console.log('Module mis à jour avec succès');
        openMessage()
      } catch (error) {
        console.error('Erreur lors de la mise à jour du module:', error);
      }
    }
  }

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (moduleId) {
        saveModuleDetails(); // Appeler saveModuleDetails toutes les 60 secondes
      }
    }, 60000);

    return () => clearInterval(intervalId); // Nettoyer l'interval lors du démontage du composant
  }, [moduleId, title, content, tags]);

  const onSave = () => {
    if (moduleId) {
      saveModuleDetails(); // Appeler saveModuleDetails toutes les 60 secondes
    }
  }

  useEffect(() => {
    if (editor && content) {
      // Mettre à jour le contenu de l'éditeur seulement si le contenu et l'éditeur sont définis
      editor.commands.setContent(content);
    }
  }, [editor, content]); // L'effet dépend de l'éditeur et du contenu


  const EditorJSONPreview = () => {
    const { editor } = useCurrentEditor()
    if (!editor) return null
    console.log(editor.getJSON())
  }

  if (!editor) return null

  return (
    <Container>
      <Sider />
      <ButtonChat />
      <Content>
        {contextHolder}
        <Header>
          {content ?
            <MenuBar editor={editor} onSave={onSave} />
            : null
          }
        </Header>
        <Body>
          <TagsManager tags={tags} setTags={setTags} />
          <TitleContainer>
            <Title text={title} setText={setTitle} />
          </TitleContainer>
          {editor && <FloatingButton editor={editor} />}
          <EditContainer style={{}}>
            {editor ?
              <EditorContent editor={editor} />
              : null}
          </EditContainer>
        </Body>
      </Content>
    </Container>
  );
};
