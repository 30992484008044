/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ModuleRequest,
  ModuleResponse,
  PageModuleLightResponse,
} from '../models/index';
import {
    ModuleRequestFromJSON,
    ModuleRequestToJSON,
    ModuleResponseFromJSON,
    ModuleResponseToJSON,
    PageModuleLightResponseFromJSON,
    PageModuleLightResponseToJSON,
} from '../models/index';

export interface CreateModuleRequest {
    moduleRequest: ModuleRequest;
}

export interface DeleteModuleRequest {
    moduleId: string;
}

export interface GetModuleRequest {
    page: number;
    size: number;
}

export interface GetModuleDetailRequest {
    moduleId: string;
}

export interface UpdateModuleRequest {
    moduleId: string;
    moduleRequest: ModuleRequest;
}

/**
 * 
 */
export class ModuleApi extends runtime.BaseAPI {

    /**
     */
    async createModuleRaw(requestParameters: CreateModuleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ModuleResponse>> {
        if (requestParameters.moduleRequest === null || requestParameters.moduleRequest === undefined) {
            throw new runtime.RequiredError('moduleRequest','Required parameter requestParameters.moduleRequest was null or undefined when calling createModule.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer-key", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/modules/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ModuleRequestToJSON(requestParameters.moduleRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ModuleResponseFromJSON(jsonValue));
    }

    /**
     */
    async createModule(requestParameters: CreateModuleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ModuleResponse> {
        const response = await this.createModuleRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async deleteModuleRaw(requestParameters: DeleteModuleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ModuleResponse>> {
        if (requestParameters.moduleId === null || requestParameters.moduleId === undefined) {
            throw new runtime.RequiredError('moduleId','Required parameter requestParameters.moduleId was null or undefined when calling deleteModule.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer-key", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/modules/{moduleId}`.replace(`{${"moduleId"}}`, encodeURIComponent(String(requestParameters.moduleId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ModuleResponseFromJSON(jsonValue));
    }

    /**
     */
    async deleteModule(requestParameters: DeleteModuleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ModuleResponse> {
        const response = await this.deleteModuleRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getModuleRaw(requestParameters: GetModuleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PageModuleLightResponse>> {
        if (requestParameters.page === null || requestParameters.page === undefined) {
            throw new runtime.RequiredError('page','Required parameter requestParameters.page was null or undefined when calling getModule.');
        }

        if (requestParameters.size === null || requestParameters.size === undefined) {
            throw new runtime.RequiredError('size','Required parameter requestParameters.size was null or undefined when calling getModule.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer-key", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/modules/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PageModuleLightResponseFromJSON(jsonValue));
    }

    /**
     */
    async getModule(requestParameters: GetModuleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PageModuleLightResponse> {
        const response = await this.getModuleRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getModuleDetailRaw(requestParameters: GetModuleDetailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ModuleResponse>> {
        if (requestParameters.moduleId === null || requestParameters.moduleId === undefined) {
            throw new runtime.RequiredError('moduleId','Required parameter requestParameters.moduleId was null or undefined when calling getModuleDetail.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer-key", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/modules/{moduleId}`.replace(`{${"moduleId"}}`, encodeURIComponent(String(requestParameters.moduleId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ModuleResponseFromJSON(jsonValue));
    }

    /**
     */
    async getModuleDetail(requestParameters: GetModuleDetailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ModuleResponse> {
        const response = await this.getModuleDetailRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async updateModuleRaw(requestParameters: UpdateModuleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ModuleResponse>> {
        if (requestParameters.moduleId === null || requestParameters.moduleId === undefined) {
            throw new runtime.RequiredError('moduleId','Required parameter requestParameters.moduleId was null or undefined when calling updateModule.');
        }

        if (requestParameters.moduleRequest === null || requestParameters.moduleRequest === undefined) {
            throw new runtime.RequiredError('moduleRequest','Required parameter requestParameters.moduleRequest was null or undefined when calling updateModule.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer-key", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/modules/{moduleId}`.replace(`{${"moduleId"}}`, encodeURIComponent(String(requestParameters.moduleId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ModuleRequestToJSON(requestParameters.moduleRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ModuleResponseFromJSON(jsonValue));
    }

    /**
     */
    async updateModule(requestParameters: UpdateModuleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ModuleResponse> {
        const response = await this.updateModuleRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
