import React, { FC, useEffect, useState } from "react";
import styled from "styled-components";
import { Checkbox, Collapse, CollapseProps, Modal } from "antd";
import { notification } from 'antd';
import { createRole, getRights } from "../../utils/Role";

/*
 * Props.
 */

interface CreateUserProps {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    // setData: React.Dispatch<React.SetStateAction<DataType[]>>
}


/*
 * Styles.
 */

const Container = styled.div`
    display: flex;
    align-items: center;
    gap: 25px;
    flex-direction: column;
    width: 100%;
    height: 400px;
`;

const Header = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
    align-self: stretch;
    height: 47px;
`;

const Title = styled.h2`
    color: #1B3042;
    font-size: 16px;
    font-weight: 700;
`;


const Cell = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 15px;
  align-self: stretch;
  width: 100%;
  height: calc(100% - 47px - 25px);
`;

const Divider = styled.div`
    display: flex;
    padding-top: 15px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;
`;

const Line = styled.div`
    height: 1px;
    align-self: stretch;
    background: #D9D9D9;
`;


const InputContainer = styled.div`
    display: flex;
    width: 50%;
    height: 77px;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
`;

const InputTitle = styled.div`
    color: #363636;
    font-size: 12.8px;
    font-weight: 400;
`;

const Input = styled.input`
    display: flex;
    padding: 16px 21px 16px 20px;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    border-radius: 15px;
    border: none;
    background: #F7F7F7;

    &:focus,
    &:active {
      outline: none;
    }
`;

const RightContent = styled.div`
    width: 50%;
    height: 100%;
    overflow-y: auto;

    
  &::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 5px;
    background: rgba(200,200,200,0.1);
    box-shadow: 1px 1px 4px 0px rgba(0, 0, 0, 0.25) inset;
  }

  &::-webkit-scrollbar-thumb {
    background: #AAA;
    border-radius: 5px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #888;
  }

  &::-webkit-scrollbar-button {
    display: none;
  }
`;

const CheckList = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: flex-start;
    align-self: stretch;
`;

/*
 * Component.
 */

export const ModifyRole: FC<CreateUserProps> = ({ open, setOpen }) => {
    const [name, setName] = useState("");
    const [api, contextHolder] = notification.useNotification();
    const [rights, setRights] = useState<{ [key: string]: { id: string; name: string; selected: boolean }[] }>({});

    const openNotification = () => {
        api.error({
            message: `Veuillez remplir tous les champs.`,
            description:
                'Veuillez remplir tous les champs pour créer un nouvel utilisateur.',
            placement: "top",
        });
    };

    const getPrefix = (name) => {
        const parts = name.split('_');
        return parts.slice(0, parts.length - 1).join('_');
    };

    const fetchData = async () => {
        try {
            const rightsRep = await getRights();
            if (rightsRep) {
                const groupedRights = rightsRep.reduce((acc, item) => {
                    const prefix = getPrefix(item.name);
                    if (!acc[prefix]) {
                        acc[prefix] = [];
                    }
                    acc[prefix].push({ ...item, selected: false });
                    return acc;
                }, {});
                console.log(groupedRights);
                setRights(groupedRights);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }

    const handleCheckboxChange = (prefix: string, id: string, checked: boolean) => {
        setRights(prevRights => {
            // Mise à jour immuable de l'état
            const updatedRights = { ...prevRights };
            updatedRights[prefix] = updatedRights[prefix].map(right =>
                right.id === id ? { ...right, selected: checked } : right
            );
            return updatedRights;
        });
    };

    const handleGroupCheckChange = (prefix: string, checked: boolean) => {
        setRights(prevRights => {
            const updatedRights = { ...prevRights };
            updatedRights[prefix] = updatedRights[prefix].map(right => ({ ...right, selected: checked }));
            return updatedRights;
        });
    };

    const collapseItems: CollapseProps['items'] = Object.entries(rights).map(([prefix, rightsArray]) => {
        const allSelected = rightsArray.every(right => right.selected); // Vérifie si tous les éléments sont sélectionnés
        return {
            key: prefix,
            label: (
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <span>{prefix.replace(/_/g, ' ').toUpperCase()}</span>
                    <Checkbox
                        checked={allSelected}
                        onChange={(e) => handleGroupCheckChange(prefix, e.target.checked)}
                    >
                        Select all
                    </Checkbox>
                </div>
            ),
            children: (
                <CheckList>
                    {rightsArray.map((right) => (
                        <Checkbox
                            key={right.id}
                            checked={right.selected}
                            onChange={(e) => handleCheckboxChange(prefix, right.id, e.target.checked)}
                        >
                            {right.name}
                        </Checkbox>
                    ))}
                </CheckList>
            ),
        };
    });

    const onCollapseChange = (key: string | string[]) => {
        console.log(key);
    };

    const onOk = async () => {
        if (name === "") {
            openNotification();
        } else {
            const rightIds = Object.values(rights).flat().reduce((selectedIds, right) => {
                if (right.selected) {
                    selectedIds.push(right.id);
                }
                return selectedIds;
            }, []);

            const payload = {
                name,
                rightIds,
            };

            console.log(payload);

            setOpen(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <Modal
            title=""
            centered
            open={open}
            onOk={onOk}
            onCancel={() => setOpen(false)}
            width={1000}>
            {contextHolder}
            <Container>
                <Header>
                    <Title>Modify a Role</Title>
                    <Divider><Line /></Divider>
                </Header>
                <Cell>
                    <InputContainer>
                        <InputTitle>Name</InputTitle>
                        <Input
                            type="text"
                            placeholder=""
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        />
                    </InputContainer>
                    <RightContent>
                        <Collapse items={collapseItems} defaultActiveKey={['1']} onChange={onCollapseChange} />
                    </RightContent>
                </Cell>
            </Container>
        </Modal>
    );
};
