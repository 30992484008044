import React, { useRef, useEffect } from 'react';

interface MyDrawerProps {
  isOpen: boolean;
  onClose: () => void;
  width: string; // Largeur personnalisable du drawer
  children?: React.ReactNode; // Enfants JSX pour le contenu du drawer
}

const MyDrawer: React.FC<MyDrawerProps> = ({ isOpen, onClose, width, children }) => {
  const drawerRef = useRef<HTMLDivElement>(null);

  const handleClickOutside = (event: MouseEvent) => {
    if (drawerRef.current && !drawerRef.current.contains(event.target as Node)) {
      onClose();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div 
      ref={drawerRef} 
      style={{ 
        width: width, 
        height: '100%', 
        backgroundColor: 'lightgray', 
        position: 'fixed',
        top: 0,
        right: isOpen ? 0 : `-${width}`,
        transition: 'right 0.3s ease-in-out',
        boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
        zIndex:"1000"
      }}
    >
      {children}
    </div>
  );
};

export default MyDrawer;
