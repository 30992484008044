import React, { FC, useState } from "react";
import styled from "styled-components";
import { Button, ButtonVariantEnum } from "../buttons/Button";
import { ArrowRightIcon } from "@radix-ui/react-icons";
import { Transfer, TransferProps } from "antd";
import { addRoleToUser, removeRoleToUser } from "../../utils/userManager";

/*
 * Props.
 */

/*
 * Styles.
 */

const Container = styled.div`
    display: flex;
    align-items: center;
    gap: 25px;
    flex-direction: column;
    width: 100%;
    height: 400px;
`;

const Header = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
    align-self: stretch;
`;

const Title = styled.h2`
    color: #1B3042;
    font-size: 16px;
    font-weight: 700;
`;

const Cell = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

const Divider = styled.div`
    display: flex;
    padding-top: 15px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;
`;

const Line = styled.div`
    height: 1px;
    align-self: stretch;
    background: #D9D9D9;
`;

interface RecordType {
  key: string;
  title: string;
  description: string;
  disabled: boolean;
}

// const mockData: RecordType[] = Array.from({ length: 20 }).map((_, i) => ({
//   key: i.toString(),
//   title: `content${i + 1}`,
//   description: `description of content${i + 1}`,
//   disabled: i % 3 < 1,
// }));

interface RolesProps {
  data: RecordType[];
  targetKeys: string[];
  setTargetKeys: (targetKeys: string[]) => void;
  userID: string;
  updateData: () => void;
}

// const oriTargetKeys = mockData.filter((item) => Number(item.key) % 3 > 1).map((item) => item.key);

export const Roles: FC<RolesProps> = ({data, targetKeys, setTargetKeys, userID, updateData}) => {
  const [selectedKeys, setSelectedKeys] = useState<string[]>([]);
  const [disabled, setDisabled] = useState(false);

  const handleChange: TransferProps['onChange'] = async (newTargetKeys, direction, moveKeys) => {
    setTargetKeys(newTargetKeys);

    console.log('targetKeys: ', newTargetKeys);
    console.log('direction: ', direction);
    console.log('moveKeys: ', moveKeys);
    if (direction === 'right') {
      for (let i = 0; i < moveKeys.length; i++) {
        console.log({ userId: userID, roleId: moveKeys[i] });
        await addRoleToUser(userID, moveKeys[i]);
      }
      updateData();
    }
    if (direction === 'left') {
      for (let i = 0; i < moveKeys.length; i++) {
        await removeRoleToUser(userID, moveKeys[i]);
      }
      updateData();
    }
  };

  const handleSelectChange = (sourceSelectedKeys: string[], targetSelectedKeys: string[]) => {
    setSelectedKeys([...sourceSelectedKeys, ...targetSelectedKeys]);

    // console.log('sourceSelectedKeys: ', sourceSelectedKeys);
    // console.log('targetSelectedKeys: ', targetSelectedKeys);
  };

  const handleScroll: TransferProps['onScroll'] = (direction, e) => {
    console.log('direction:', direction);
    console.log('target:', e.target);
  };

  return (
    <Container>
      <Header>
        <Title>Roles</Title>
        <Divider><Line /></Divider>
      </Header>
      <Cell>
        <Transfer
          dataSource={data}
          titles={['Source', 'Target']}
          showSearch
          targetKeys={targetKeys}
          selectedKeys={selectedKeys}
          onChange={handleChange}
          onSelectChange={handleSelectChange}
          onScroll={handleScroll}
          render={(item) => item.title}
          disabled={disabled}
          oneWay
          style={{ marginBottom: 16}}
          listStyle={{
            width: 400,
            height: 320,
          }}
        />
      </Cell>
    </Container>
  );
};
