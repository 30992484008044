import React, { FC } from "react";
import styled from "styled-components";
import { UploadImage } from "../commun/UploadImage";
import { Title } from "../commun/titre";

/*
 * Props.
 */


interface FormationData {
    title: string;
    summary: string;
    assignment: string;
    prerequisite: string;
    target: string;
    tags: any[]; // Utilisez un type plus spécifique si possible
}

interface InformationsProps {
    data: FormationData;
    onFormationChange: (key: string, value: any) => void;
    formationId?: string,
}


/*
 * Styles.
 */

const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    padding: 30px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    flex: 1 0 0;
    align-self: stretch;
    background: #FFF;
`;

const Header = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
    align-self: stretch;
`;

const Body = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    flex: 1 0 0;
    align-self: stretch;
`;

const FormStyle = styled.div`
    display: flex;
    width: 760px;
    flex-grow: 1;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
`;



const TitleStyle = styled.h2`
    color: #1B3042;
    font-size: 16px;
    font-weight: 700;
`;

const DescriptionStyle = styled.span`
    color: #1B3042;
    font-size: 12px;
    font-weight: 400;
`;

const Divider = styled.div`
    display: flex;
    padding-top: 15px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;
`;

const Line = styled.div`
    height: 1px;
    align-self: stretch;
    background: #D9D9D9;
`;

const Input = styled.input`
    padding: 16px 21px 16px 20px;
    border-radius: 15px;
    border: none;
    background: #F7F7F7;
    display: flex;
    font-family: Poppins;
    font-size: 0.7vw;
    font-weight: 400;
    line-height: normal;
    resize: none;
    width: 100%;
    color: #363636;

    &:focus, &:active {
      outline: none;
    }
    ::placeholder {
        color: rgba(0, 0, 0, 0.20);
    }
`;

const InputStyle = styled.textarea`
    padding: 16px 21px 16px 20px;
    border-radius: 15px;
    border: none;
    background: #F7F7F7;
    display: flex;
    font-family: Poppins;
    font-size: 0.7vw;
    font-weight: 400;
    line-height: normal;
    resize: none;
    width: 100%;
    color: #363636;
    text-align: left;           
    vertical-align: top;         
    overflow-x: auto;          
    &:focus, &:active {
      outline: none;
    }
    ::placeholder {
        color: rgba(0, 0, 0, 0.20);
    }
`;


export const InformationContent: FC<InformationsProps> = ({ data, onFormationChange, formationId }) => {

    const handleTitleChange = (e: string) => {
        onFormationChange('title', e);
    };

    const handleSummaryChange = (e: string) => {
        onFormationChange('summary', e);
    };

    return (
        <Container>
            <Header>
                <TitleStyle>Mon profile</TitleStyle>
                <DescriptionStyle>Mettez à jour vos informations personnel.</DescriptionStyle>
                <Divider><Line /></Divider>
            </Header>
            <Body>
                <FormStyle>
                    <UploadImage />
                    <Input
                        type="text"
                        placeholder="My Title"
                        value={data.title}
                        onChange={(e) => handleTitleChange(e.target.value)}
                        maxLength={100}
                    />
                    <InputStyle
                        id="inputDescription"
                        // style={{height:"500px"}}
                        style={{height:"100%"}}
                        placeholder="My description about the formation (1000 characters max)"
                        value={data.summary}
                        onChange={(e) => handleSummaryChange(e.target.value)}
                        maxLength={1000}
                    />
                </FormStyle>
            </Body>
        </Container>
    );
};