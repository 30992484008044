import React, { FC, useEffect, useState } from "react";
import styled from "styled-components";
import { GetAllModules } from "../utils/module";
import { ModuleResponse } from "../api/models/ModuleResponse";
import { useNavigate, useParams } from "react-router-dom";
import { getChapters, getFormationDetails, updateFormation } from "../utils/formation";
import logo from "../assets/icons/logo.svg";
import logoName from "../assets/icons/logoName.svg";
import { CloseOutlined, ExclamationCircleOutlined, FileTextOutlined } from "@ant-design/icons";
import { InformationContent } from "../components/pipeline/Information";
import { FormationRequest, FormationRequestTargetEnum } from "../api/models/FormationRequest";
import { TagRequestColorEnum, TagRequestFromJSON } from "../api/models/TagRequest";
import { message } from "antd";
import { ModaleModule } from "../components/pipeline/modaleModule";
import { EditModuleComponent } from "../components/editor/EditModuleComponent";
import { ModuleCard } from "../components/pipeline/ModuleCard";

/*
 * Styles.
 */

const Container = styled.div`
  display: flex;
  height: 100vh;
  width: 100vw;
  background: #fff;
  display: flex;
  flex-direction: column;
`;

const Header = styled.div`
  display: flex;
  height: 73px;
  width: 100%;
  align-items: center;
  align-self: stretch;
  border-bottom: 1px solid #D1D6D9;
`;


const HeaderLogoContainer = styled.div`
  display: flex;
  width: 300px;
  padding-left: 30px;
  align-items: center;
  gap: 10px;
  align-self: stretch;
`;

const HeaderTitleContainer = styled.div`
  display: flex;
  padding-left: 80px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex: 1 0 0;
  align-self: stretch;

  color: #363636;

  font-family: Poppins;
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

const HeaderQuitContainer = styled.div`
  display: flex;
  padding: 0px 30px;
  align-items: center;
  gap: 10px;
  align-self: stretch;
`;

const IconCloseOutlined = styled(CloseOutlined)`
  fill: #363636;
  width: 20px;
  cursor: pointer;
  :hover {
    color: #B3482F;
  }
`;

const Body = styled.div`
  display: flex;
  height: calc(100% - 73px);
  align-items: flex-start;
  width: 100%;
  flex-grow: 1;
`;

const Sider = styled.div`
  display: flex;
  width: 300px;
  height: 100%;
  padding: 30px 10px;
  flex-direction: column;
  align-items: center;
  gap: 0px;

  border-right: 1px solid #D1D6D9;
  background: #E6F3F8;

  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;

  overflow-y: auto;
  &::-webkit-scrollbar {
    height: 7px;
    width: 7px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 5px;
    background: rgba(200,200,200,0.1);
    // box-shadow: 1px 1px 4px 0px rgba(0, 0, 0, 0.25) inset;
  }

  &::-webkit-scrollbar-thumb {
    background: #AAA;
    border-radius: 5px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #888;
  }

  &::-webkit-scrollbar-button {
    display: none;
  }
`;

const Content = styled.div`
  height: 100%;
  flex-grow: 1;
`;

const CardSider = styled.div<{ $selected: boolean }>`
  display: flex;
  width: 250px;
  height: 150px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;

  border-radius: 15px;
  border: 1px solid #D1D6D9;

  background: #FFF;

  cursor: pointer;
  color: #727272;

  ${(p) =>
    !p.$selected &&
    `
    &:hover {
      border: 1px solid #727272;
    }
  `}

  ${(p) =>
    p.$selected &&
    `
    border: 1px solid #028CB5;
    color: #028CB5;
  `}
`;

const CardAdd = styled.div`
  display: flex;
  width: 250px;
  height: 50px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;

  border-radius: 10px;
  border: 1px solid #D1D6D9;

  background: #FFF;

  cursor: pointer;

  &:hover {
    border: 1px solid #727272;
  }

  color: #727272;

  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

const CardUnderSiderContainer = styled.div`
  width: 250px;
  height: 20px; 
  display: flex;
  align-items: center;
  justify-content: center; 
  cursor: pointer;
  flex-shrink: 0;

  &:hover > div {
    background: #D1D6D9; 
  }
`;

const CardUnderSider = styled.div`
  width: 250px;
  height: 1px; 
  align-items: center;
  border-radius: 15px;
  background: transparent; 
  cursor: pointer;
`;

/*
 * Component.
 */

interface FormationData {
  title: string;
  summary: string;
  assignment: string;
  prerequisite: string;
  target: string;
  tags: string[];
}

interface ModuleData {
  id: string | undefined;
  title: string;
}

interface ChapterData {
  title: string;
  modules: ModuleData[];
}

export const EditPipelinePage: FC = () => {
  const { formationId } = useParams();
  const navigate = useNavigate();
  const [selectCard, setSelectCard] = useState(0);
  const [isModal, setIsModal] = useState(false);
  const [formationData, setFormationData] = useState<FormationData>({
    title: "",
    summary: "",
    assignment: "",
    prerequisite: "",
    target: "",
    tags: []
  });
  const [modules, setModules] = useState<Array<ModuleResponse>>([]);
  const [chapters, setChapters] = useState<ChapterData[]>([{ title: "", modules: [] }]);

  const [messageApi, contextHolder] = message.useMessage();
  const key = 'updatable';

  const openMessage = () => {
    messageApi.open({
      key,
      type: 'loading',
      content: 'Loading...',
    });
    setTimeout(() => {
      messageApi.open({
        key,
        type: 'success',
        content: 'save!',
        duration: 2,
      });
    }, 1000);
  };

  const showModal = () => {
    setIsModal(true);
  };

  const handleFormationChange = (key: string, value: any) => {
    setFormationData({ ...formationData, [key]: value });
  };

  const fetchModules = async () => {
    try {
      const res = await GetAllModules(); // Attendre le résultat de la Promise
      if (res) {
        setModules(res);
      }
    } catch (error) {
      console.error('Erreur lors de la récupération des modules :', error);
    }
  };


  const fetchformation = async () => {
    try {
      if (formationId) {
        const res = await getFormationDetails(formationId);

        if (res) {
          const newFormationData = {
            title: res.title || formationData.title,
            summary: res.summary || formationData.summary,
            assignment: res.assignment || formationData.assignment,
            prerequisite: res.prerequisite || formationData.prerequisite,
            target: res.target || formationData.target,
            tags: res.tags ? Array.from(res.tags).map((item) => item.tag) : formationData.tags,
          };

          setFormationData(newFormationData);
        }
      }
    } catch (error) {
      console.error('Erreur lors de la récupération des détails de la formation :', error);
    }
  };
  const fetchChapter = async () => {
    if (formationId) {
      try {
        const res = await getChapters(formationId);
        console.log({ chapters: res });
        if (res) {
          const modules = res.filter(e => e.module !== undefined).map(e => e.module);
  
          if (modules.length > 0) {
            setChapters([{title: "", modules}]);
          }
        }
      } catch (error) {
        console.error('Error fetching modules:', error);
      }
    }
  };

  useEffect(() => {
    if (formationId) {
      fetchformation()
      fetchModules()
      fetchChapter()
    }
  }, [formationId]);

  const renderContent = () => {
    if (selectCard === 0) {
      return <InformationContent
        data={formationData}
        onFormationChange={handleFormationChange}
        formationId={formationId ? formationId : undefined}
      />
    }
    else if (selectCard > 0) {
      const idModule = chapters[0].modules[selectCard - 1]?.id
      if (idModule) {
        return <EditModuleComponent moduleId={idModule} />
      }
      else {
        return <div>Module not found</div>
      }
    }
    return null
  }

  const Update = () => {
    if (formationId) {
      const data: FormationRequest = {
        title: [{ rawContent: formationData.title, languageType: "FR" }],
        summary: [{ rawContent: formationData.summary, languageType: "FR" }],
        assignment: [{ rawContent: formationData.assignment, languageType: "FR" }],
        prerequisite: [{ rawContent: formationData.prerequisite, languageType: "FR" }],
        target: FormationRequestTargetEnum.Developer,
        tags: new Set([TagRequestFromJSON({
          tag: 'ExampleTag',
          color: TagRequestColorEnum.Blue,
        })])
      };
      try {
        updateFormation(formationId, data)
        openMessage()
      }
      catch (error) {
        console.error('Erreur lors de l update de la formation:', error);
      }
    }
  }

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (formationId) {
        Update(); // Appeler saveModuleDetails toutes les 60 secondes
      }
    }, 60000);

    return () => clearInterval(intervalId); // Nettoyer l'interval lors du démontage du composant
  }, [formationId, formationData]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if ((event.ctrlKey || event.metaKey) && event.key === 's') {
        event.preventDefault(); // Empêcher l'action par défaut de Ctrl+S
        Update();    // Sauvegarder les modifications
      }
    };
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [formationId, formationData]);

  const onClose = (url:string) => {
    Update()
    navigate(url)
  }

  const removeModuleById = (moduleId: string) => {
    setChapters(prevChapters => 
      prevChapters.map(chapter => ({
        ...chapter,
        modules: chapter.modules.filter(module => module.id !== moduleId)
      }))
    );
  };

  return (
    <Container>
      {formationId ?
        <>
          {contextHolder}
          <ModaleModule isModalOpenModule={isModal} setIsModalOpenModule={setIsModal} data={modules} setChapters={setChapters} chapters={chapters} formationId={formationId} />
          <Header>
            <HeaderLogoContainer>
              <img src={logo} alt="logo" width={40} style={{ cursor: "pointer" }} onClick={() =>onClose('/')}/>
              <img src={logoName} alt="logoName" width={100} style={{ cursor: "pointer" }} onClick={() =>onClose('/')}/>
            </HeaderLogoContainer>
            <HeaderTitleContainer> {formationData.title !== "" ? formationData.title : "Créer une formation" }</HeaderTitleContainer>
            <HeaderQuitContainer><IconCloseOutlined onClick={() =>onClose('/lab')} /></HeaderQuitContainer>
          </Header>
          <Body>
            <Sider>
              <CardSider $selected={selectCard === 0} onClick={() => setSelectCard(0)}>
                <ExclamationCircleOutlined style={{ fontSize: "50px" }} />
              </CardSider>
              <CardUnderSiderContainer><CardUnderSider /></CardUnderSiderContainer>
              {chapters[0].modules?.map((card, index) => {
                const obj = modules.find(objet => objet.id === card.id)
                if (!obj) return null
                return (
                  <>
                    <ModuleCard key={obj.id} selectCard={selectCard} setSelectCard={setSelectCard} index={index} module={obj} onDelete={() => removeModuleById(obj.id)}/>
                    <CardUnderSiderContainer><CardUnderSider /></CardUnderSiderContainer>
                  </>
                );
              })}
              <CardAdd onClick={showModal}>+</CardAdd>
            </Sider>
            <Content>
              {renderContent()}
            </Content>
          </Body>
        </>
        : null}
    </Container>
  );
};
