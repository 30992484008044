import React, { createContext, useContext, useState, ReactNode } from 'react';

type ActiveMenuContextType = {
  activeMenu: string;
  setActiveMenu: (menu: string) => void;
};

const ActiveMenuContext = createContext<ActiveMenuContextType | undefined>(undefined);

export const useActiveMenu = () => {
  const context = useContext(ActiveMenuContext);
  if (!context) {
    throw new Error('useActiveMenu must be used within a ActiveMenuProvider');
  }
  return context;
};

type ActiveMenuProviderProps = {
  children: ReactNode;
};

export const ActiveMenuProvider: React.FC<ActiveMenuProviderProps> = ({ children }) => {
  const [activeMenu, setActiveMenu] = useState<string>("/");

  return (
    <ActiveMenuContext.Provider value={{ activeMenu, setActiveMenu }}>
      {children}
    </ActiveMenuContext.Provider>
  );
};