import React from "react"
import styled from "styled-components";
import { ModuleData } from "../../types/chapters";

const Container = styled.div`
  display: flex;
  width: 199px;
  height: 536px;
  padding: 20px 15px;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  flex-shrink: 0;
  border-radius: 12px;
  border: 2px solid rgba(0, 0, 0, 0.25);
  background: #FFF;
`;

const Row = styled.div<{ $select: boolean }>`
  color: ${(p) => (p.$select ? "#028CB5" : "#363636")};

  font-size: 14px;
  font-weight: 500;
  cursor:pointer;

  &:hover{
    color: #028CB5;
  }
`;

/*
 * Styles.
 */


interface MenuProps {
  menus: ModuleData[];
  selectMenu: number;
  setSelectMenu: React.Dispatch<React.SetStateAction<number>>;
}


export const Menu: React.FC<MenuProps> = ({ menus, selectMenu, setSelectMenu }) => {

  const onClick = (e:number) => {
    setSelectMenu(e)
  }

  return (
    <Container>
      {
        menus.map((e, i) => (
          <Row key={i} $select={i==selectMenu} onClick={() => onClick(i)}>{e.title}</Row>
        ))
      }
    </Container>
  )
}
