import React, { FC, useEffect, useState } from "react";
import styled from "styled-components";
import { Modal, Tabs, TabsProps } from "antd";
import { createNewUser, getRoleCanAssign, getUserInfo, getUsersManagement } from "../../utils/userManager";
import { notification } from 'antd';
import { AdminUserResponse, PageAdminUserResponse, PageRoleLightResponse, RoleResponse } from "../../api";
import { Profile } from "./Profile";
import { Roles } from "./Roles";
import { Teams } from "./Teams";
import { Projects } from "./Projets";
import { getRoles } from "../../utils/Role";

/*
 * Props.
 */

interface DataType {
    key: string;
    name: string;
    email: string;
    status: boolean;
    roles: string[];
}

interface ModifyUserProps {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    updateData: () => void;
    user: AdminUserResponse;
}


/*
 * Styles.
 */



/*
 * Component.
 */

export const ModifyUser: FC<ModifyUserProps> = ({ open, setOpen, updateData, user }) => {
    const [prenom, setPrenom] = useState("");
    const [nom, setNom] = useState("");
    const [email, setEmail] = useState("");
    const [api, contextHolder] = notification.useNotification();
    const [allRole, setAllRole] = useState<any>([]);
    const [targetKeys, setTargetKeys] = useState<string[]>([]);

    const items: TabsProps['items'] = [
        {
            key: 'profile',
            label: 'Profile',
            children: <Profile prenom={prenom} setPrenom={setPrenom} nom={nom} setNom={setNom} email={email} setEmail={setEmail} />,
        },
        {
            key: 'roles',
            label: 'Roles',
            children: <Roles data={allRole} targetKeys={targetKeys} setTargetKeys={setTargetKeys} userID={user.id} updateData={updateData}/>,
        },
        {
            key: 'teams',
            label: 'Teams',
            children: <Teams />,
        },
        {
            key: 'projects',
            label: 'Projects',
            children: <Projects />,
        },
    ];

    function isRoleResponse(users: any): users is PageRoleLightResponse {
        return users && typeof users === 'object';
    }

    useEffect(() => {
        const fetchData = async () => {

            const rolesAssignable = await getRoleCanAssign()
            const allRoles = await getRoles();
            if (rolesAssignable && allRoles && isRoleResponse(allRoles) && allRoles.content) {
                const rolesData = allRoles.content.map((role, index) => {
                    const isAssignable = rolesAssignable.some(assignableRole => assignableRole.id === role.id);
                    return {
                        key: role.id, // Using the role's id as key
                        title: role.name,
                        description: `description of ${role.name}`,
                        disabled: !isAssignable // Disable the role if it's not assignable
                    };
                });
                console.log("rolesData: ", rolesData);
                setAllRole(rolesData);
            }
        }

        setPrenom(user.firstname ?? '');
        setNom(user.lastname ?? '');
        setEmail(user.email ?? '');
        if (user.roles) {
            setTargetKeys(user.roles.map(role => role.id));
        } else {
            console.log("No roles found for this user."); // Handle the case where roles are undefined
        }
        console.log("user: ", user);
        fetchData();

    }, [user])

    const openNotification = () => {
        api.error({
            message: `Veuillez remplir tous les champs.`,
            description:
                'Veuillez remplir tous les champs pour créer un nouvel utilisateur.',
            placement: "top",
        });
    };

    const onOk = async () => {
        console.log("ok");
        if (prenom === "" || nom === "" || email === "") {
            openNotification()
            return;
        }
        else {
            await updateData();
            setOpen(false)
        }
    }

    return (
        <Modal
            title=""
            centered
            open={open}
            onOk={onOk}
            onCancel={() => setOpen(false)}
            width={1000}>
            {contextHolder}
            <Tabs
                tabPosition={"left"}
                items={items}
            />
        </Modal>
    );
};
