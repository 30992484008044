/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ContentRequest
 */
export interface ContentRequest {
    /**
     * 
     * @type {string}
     * @memberof ContentRequest
     */
    languageType?: ContentRequestLanguageTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ContentRequest
     */
    rawContent?: string;
}


/**
 * @export
 */
export const ContentRequestLanguageTypeEnum = {
    Fr: 'FR',
    En: 'EN'
} as const;
export type ContentRequestLanguageTypeEnum = typeof ContentRequestLanguageTypeEnum[keyof typeof ContentRequestLanguageTypeEnum];


/**
 * Check if a given object implements the ContentRequest interface.
 */
export function instanceOfContentRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ContentRequestFromJSON(json: any): ContentRequest {
    return ContentRequestFromJSONTyped(json, false);
}

export function ContentRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ContentRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'languageType': !exists(json, 'languageType') ? undefined : json['languageType'],
        'rawContent': !exists(json, 'rawContent') ? undefined : json['rawContent'],
    };
}

export function ContentRequestToJSON(value?: ContentRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'languageType': value.languageType,
        'rawContent': value.rawContent,
    };
}

