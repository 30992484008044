import React from "react";
import styled from "styled-components";
import user from "../../assets/icons/user.svg";
import user_select from "../../assets/icons/user_select.svg";
import fusee from "../../assets/icons/fusee.svg";
import fusee_select from "../../assets/icons/fusee_select.svg";
import infos from "../../assets/icons/infos.svg";
import infos_select from "../../assets/icons/infos_select.svg";
import graduate from "../../assets/icons/graduate.svg";
import graduate_select from "../../assets/icons/graduate_select.svg";
import { useNavigate } from "react-router-dom";
import { useActiveMenu } from "../../context";
import { useTranslation } from "react-i18next";

/*
 * Styles.
 */

const MenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  // justify-content: space-evenly;
  padding: 20px;
  gap: 10px;

  width: 100%;
  padding: 30px;
  align-items: flex-start;
  gap: 10px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
`;

const Title = styled.h3`
  color: #028CB5;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 10px;
  height: 21px;
`;

const MenuLine = styled.div<{ $select: boolean }>`
  display: flex;
  height: 35px;

  padding: 5px 10px;
  align-items: center;
  align-self: stretch;

  flex-direction: row;
  gap: 20px;
  cursor: pointer;
  border-radius: 10px;
  transition: background-color 0.3s;

  color: #363636;

  font-family: Poppins;
  font-size: 0.8rem;
  font-weight: 500;

  ${(p) => (p.$select ? "background-color: #028CB5;" : "")}
  ${(p) => (p.$select ? "color: #E6F3F8;" : "")}

  ${(p) => (p.$select ? "" : "  &:hover {background-color: rgba(27, 48, 66, 0.05);}")}
`;

const MenuIcon = styled.img``;

const MenuLabel = styled.span`

`;

/*
 * Props.
 */

interface MenuProps {
  toggle: boolean;
}


/*
 * Component.
 */

export const Menu: React.FC<MenuProps> = ({ toggle }) => {
  const { activeMenu, setActiveMenu } = useActiveMenu();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleButtonClick = (url: string) => {
    setActiveMenu(url);
    navigate(url);
  };


  return (
    <MenuContainer>
      {toggle ? <Title>OVERVIEW</Title> : <Title></Title>}
      <Container>
        <MenuLine onClick={() => { handleButtonClick("/") }} $select={activeMenu === "/"} >
          <MenuIcon src={activeMenu === "/" ? user_select : user} alt="user" />
          {toggle ? <MenuLabel>{t("Onboarding")}</MenuLabel> : null}
        </MenuLine>
        <MenuLine onClick={() => { handleButtonClick("/formations") }} $select={activeMenu === "/formations"}>
          <MenuIcon src={activeMenu === "/formations" ? graduate_select : graduate} alt="graduate" />
          {toggle ? <MenuLabel>{t("Formations")}</MenuLabel> : null}
        </MenuLine>
        <MenuLine onClick={() => { handleButtonClick("/lab") }} $select={activeMenu === "/lab"}>
          <MenuIcon src={activeMenu === "/lab" ? fusee_select : fusee} alt="fusee" />
          {toggle ? <MenuLabel>{t("Lab")}</MenuLabel> : null}
        </MenuLine>
        <MenuLine onClick={() => { handleButtonClick("/info") }} $select={activeMenu === "/info"}>
          <MenuIcon src={activeMenu === "/info" ? infos_select : infos} alt="infos" />
          {toggle ? <MenuLabel>{t("Infos utiles")}</MenuLabel> : null}
        </MenuLine>
      </Container>
    </MenuContainer>
  );
};
