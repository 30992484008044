import React, { FC, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { Space, Table, Tag, Modal, Tabs } from 'antd';
import type { InputRef, TableProps, TabsProps } from 'antd';

import { SearchOutlined } from '@ant-design/icons';
import type { GetRef, TableColumnsType, TableColumnType } from 'antd';
import { Button, Input } from 'antd';
import type { FilterDropdownProps } from 'antd/es/table/interface';
import Highlighter from 'react-highlight-words';
import { getUserInfo, getUsersManagement } from "../../utils/userManager";
import { PageAdminUserResponse } from "../../api/models/PageAdminUserResponse.ts";
import { CreateUser } from "./CreateUser";
import { ModifyUser } from "./ModifyUser";
import { AdminUserResponse } from "../../api";

/*
 * Props.
 */

/*
 * Styles.
 */

const Container = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
    flex-direction: column;
    width: 100%;
    height: 100%;
`;

const Header = styled.div`
    display: flex;
    justify-content: flex-end;
    width: 100%;
`;

const StatusTag = styled.span<{ status: boolean }>`
  color: ${({ status }) => (status ? '#01A89D' : 'red')};
`;

interface DataType {
  key: string;
  name: string;
  email: string;
  status: boolean;
  roles: string[];
}

type DataIndex = keyof DataType;



const dataExemple: DataType[] = [
  { key: '1', name: 'John1 Doe', email: 'john10@example.com', status: true, roles: ['moderator', 'user', 'admin'] },
  { key: '2', name: 'Jane1 Smith', email: 'jane11@example.com', status: false, roles: ['tester', 'user'] },
  { key: '3', name: 'William1 Johnson', email: 'william12@example.com', status: true, roles: ['user'] },
  { key: '4', name: 'Emma1 Wilson', email: 'emma13@example.com', status: true, roles: ['loser'] },
  { key: '5', name: 'Oliver1 Brown', email: 'oliver14@example.com', status: true, roles: ['loser', 'user'] },
];

export const UserManagerPage: FC = () => {

  const [data, setData] = useState<DataType[]>(dataExemple);
  const [user, setUser] = useState<AdminUserResponse | null>(null);
  const [open, setOpen] = useState<boolean>(false);
  const [openCreateUser, setOpenCreateUser] = useState<boolean>(false);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef<InputRef>(null);

  function isPageAdminUserResponse(users: any): users is PageAdminUserResponse {
    return users && typeof users === 'object' && 'content' in users;
  }

  const updateData = async () => {
    try {
      const users = await getUsersManagement();
      console.log(users);
      if (isPageAdminUserResponse(users)) {
        const tmp = users.content.map((user, index) => {
          return { id: user.id, name: user.firstname + ' ' + user.lastname, email: user.email, status: Boolean(user.activated), roles: user.roles ? user.roles.map((role) => role.name) : [] }
        });
        console.log(tmp);
        setData(tmp);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }

  useEffect(() => {
    updateData();
  }, []);

  const handleSearch = (
    selectedKeys: string[],
    confirm: FilterDropdownProps['confirm'],
    dataIndex: DataIndex,
  ) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters: () => void) => {
    clearFilters();
    setSearchText('');
    setSearchedColumn('');
  };


  const getColumnSearchProps = (dataIndex: DataIndex): TableColumnType<DataType> => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? '#1677ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes((value as string).toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const columns: TableProps<DataType>['columns'] = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      ...getColumnSearchProps('name'),
    },
    {
      title: 'E-mail',
      dataIndex: 'email',
      key: 'email',
      ...getColumnSearchProps('email'),
    },
    {
      title: 'Role',
      key: 'role',
      dataIndex: 'role',
      render: (_, { roles }) => (
        <>
          {roles.map((role) => {
            let color = role.length > 5 ? 'geekblue' : 'green';
            if (role === 'loser') {
              color = 'volcano';
            }
            return (
              <Tag color={color} key={role}>
                {role.toUpperCase()}
              </Tag>
            );
          })}
        </>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      filters: [
        { text: 'Active', value: 'active' },
        { text: 'Inactive', value: 'inactive' },
      ],
      render: (status) => <StatusTag status={status}>{status ? "Active" : "Inactive"}</StatusTag>,
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <Space size="middle">
          <a onClick={() => onEdit(record)}>Edit</a>
        </Space>
      ),
    },
  ];


  function isAdminUserResponse(users: any): users is AdminUserResponse {
    return users && typeof users === 'object';
  }


  const onEdit = async (record: DataType) => {
    const userResponse = await getUserInfo(record.id.toString());
    if (isAdminUserResponse(userResponse)) {
      setUser(userResponse);
      setOpen(true)
    };
  }

    return (
      <Container>
        {user ?
          <ModifyUser open={open} user={user} setOpen={setOpen} updateData={updateData}/>
          : null}
        <CreateUser open={openCreateUser} setOpen={setOpenCreateUser} updateData={updateData} />
        <Header>
          <Button type="primary" onClick={() => setOpenCreateUser(true)}>+ Add New User</Button>
        </Header>
        <Table columns={columns} dataSource={data} style={{ width: "100%" }} />
      </Container>
    );
  };
