import React, { FC, useEffect, useState } from "react";
import styled from "styled-components";
import { Search } from "../commun/search";
import { Modal } from 'antd';
import { ModuleResponse } from "../../api/models/ModuleResponse";
import { ModuleCardFormation } from "../module/ModuleCardFormation";
import { Tabs } from "../commun/Tabs";
import { ModuleApi } from "../../api/apis/ModuleApi";
import { GetModule } from "../../utils/module";
import { ChapterRequest, ChaptersRequest, ContentRequest, TagRequest } from "../../api";
import { ChapterData } from "../../types/chapters";
import { createChaptersForFormation } from "../../utils/formation";

/*
 * Styles.
 */

const Container = styled.div`
  display: flex;
  height: 100%;
  flex: 1;
  padding: 20px;
  flex: 1 0 0;
  background: #fff;
  flex-direction: column;
    align-items: flex-start;
    gap: 30px;
`;

const ModuleContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: stretch;
`;

const ModuleTableau = styled.div`
    display: flex;
    width: 100%;
    height: 481px;
    flex-direction: column;
    align-items: flex-start;
    border-radius: 15px;
    border: 1px solid #D2D6DB;
    overflow: hidden;

`;

const Header = styled.div`
    display: flex;
    height: 50px;
    align-items: flex-start;
    gap: 10px;
    flex-shrink: 0;
    align-self: stretch;
    border-bottom: 1px solid #D1D6D9;
    background: #F3F3F3;
    border-radius:  15px 15px 0 0;
`;

const HeaderText = styled.div`
    display: flex;
    padding: 0px 20px;
    align-items: center;
    gap: 10px;
    align-self: stretch;

    color: #727272;

    font-size: 16px;
    font-weight: 600;
`;

const RowContainer = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    align-items: flex-start;

    overflow-y: auto;

    &::-webkit-scrollbar {
      height: 7px;
      width: 7px;
    }
  
    &::-webkit-scrollbar-track {
      border-radius: 5px;
      background: rgba(200,200,200,0.1);
    }
  
    &::-webkit-scrollbar-thumb {
      background: #D1D6D9;
      border-radius: 5px;
    }
  
    &::-webkit-scrollbar-thumb:hover {
      background: #929597;
    }
  
    &::-webkit-scrollbar-button {
      display: none;
    }
`;

const Row = styled.div`
    display: flex;
    height: 70px;
    align-items: flex-start;
    gap: 10px;
    flex-shrink: 0;
    align-self: stretch;

    border-bottom: 1px solid #D1D6D9;
    background: #FFF;
`;

const RowTitle = styled.div`
    display: flex;
    padding: 10px 20px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    flex: 1 0 0;
    align-self: stretch;

    color: #363636;

    font-size: 16px;
    font-weight: 600;
`;

const RowTag = styled.div`
    display: flex;
    width: 200px;
    padding: 0px 20px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 20px;
    align-self: stretch;
`;

const Tag = styled.div`
    display: flex;
    padding: 5px 15px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 15px;
    background: #01A89D;

    color: #FFF;
    font-size: 14px;
    font-weight: 600;
`;

const RowAdd = styled.div`
    display: flex;
    width: 100px;
    padding: 0px 20px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;;
`;

const AddButton = styled.div`
    color: #028CB5;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;

    transition: color 0.2s ease;

    &:hover{
        color: rgba(2, 140, 181, 0.50);
    }
`;


/*
 * Component.
 */

interface ModaleModuleProps {
    isModalOpenModule: boolean;
    setIsModalOpenModule: React.Dispatch<React.SetStateAction<boolean>>;
    setChapters: any;
    chapters: any;
    data: Array<ModuleResponse>;
    formationId: string;
}

interface ChapterDataRequest {
    title: string;
    index: number;
}


export const ModaleModule: FC<ModaleModuleProps> = ({ isModalOpenModule, setIsModalOpenModule, data, setChapters, chapters, formationId }) => {
    const [selectTab, setSelectTab] = useState<string>("My modules");
    const handleOkModule = () => {
        setIsModalOpenModule(false);
    };

    const handleCancelModule = () => {
        setIsModalOpenModule(false);
    };

    function transformToChaptersRequest(chapters: ChapterData[]): ChaptersRequest {
        if (chapters){
            return {
                chapters: chapters[0]?.modules.map((value, i) => {
                    return {
                        index: i,
                        moduleId: value.id
                    }
                })
            }
        }
        return {
            chapters: []
        }
    }

    const putChapter = (chaptersData: any) => {
        if (formationId) {
            createChaptersForFormation(formationId, transformToChaptersRequest(chaptersData))
        }
    }

    const onAdd = async (e: any) => {
        // console.log(chapters)
        if (chapters.length !== 0) {
            putChapter([{ ...chapters[0], modules: [...chapters[0].modules, { title: e.title, id: e.id }] }])
            setChapters([{ ...chapters[0], modules: [...chapters[0].modules, { title: e.title, id: e.id }] }])
        }
        handleCancelModule()
    }

    return (
        <Modal title="Module list" open={isModalOpenModule} onOk={handleOkModule} onCancel={handleCancelModule} footer="" width={1000}>
            <Container>
                <Tabs selectTab={selectTab} setSelectTab={setSelectTab} tabList={["My modules", "All modules"]} />
                <ModuleContainer>
                    <ModuleTableau>
                        <Header>
                            <HeaderText style={{ flex: "1 0 0" }}>TITLE</HeaderText>
                            <HeaderText style={{ width: "200px" }}>TAG</HeaderText>
                            <HeaderText style={{ width: "100px" }}></HeaderText>
                        </Header>
                        <RowContainer>
                            {data ? data.map((e: any) => {
                                return (
                                    <Row key={e.id}>
                                        <RowTitle>
                                            <div>{e.title === "" ? "Untitled" : e.title}</div>
                                            <div style={{ color: "#727272", fontSize: "12px", fontWeight: "300" }}>Web dev</div>
                                        </RowTitle>
                                        <RowTag>
                                            <Tag>Tag</Tag>
                                            {/* <Tag>{Array.from(e.tags)}</Tag> */}
                                        </RowTag>
                                        <RowAdd >
                                            <AddButton onClick={() => onAdd(e)}>
                                                Add
                                            </AddButton>
                                        </RowAdd>
                                    </Row>
                                )
                            }) : null}
                        </RowContainer>
                    </ModuleTableau>
                </ModuleContainer>
            </Container>
        </Modal>
    );
};
