/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  PageTeamLightResponse,
  TeamCreateRequest,
  TeamLightResponse,
  TeamMemberRequest,
  TeamResponse,
} from '../models/index';
import {
    PageTeamLightResponseFromJSON,
    PageTeamLightResponseToJSON,
    TeamCreateRequestFromJSON,
    TeamCreateRequestToJSON,
    TeamLightResponseFromJSON,
    TeamLightResponseToJSON,
    TeamMemberRequestFromJSON,
    TeamMemberRequestToJSON,
    TeamResponseFromJSON,
    TeamResponseToJSON,
} from '../models/index';

export interface CreateTeamRequest {
    teamCreateRequest: TeamCreateRequest;
}

export interface GetTeamRequest {
    page: number;
    size: number;
}

export interface GetTeamDetailRequest {
    teamId: string;
}

export interface SetTeamMembersRequest {
    teamId: string;
    teamMemberRequest: TeamMemberRequest;
}

/**
 * 
 */
export class TeamApi extends runtime.BaseAPI {

    /**
     */
    async createTeamRaw(requestParameters: CreateTeamRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TeamLightResponse>> {
        if (requestParameters.teamCreateRequest === null || requestParameters.teamCreateRequest === undefined) {
            throw new runtime.RequiredError('teamCreateRequest','Required parameter requestParameters.teamCreateRequest was null or undefined when calling createTeam.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer-key", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/teams/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TeamCreateRequestToJSON(requestParameters.teamCreateRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TeamLightResponseFromJSON(jsonValue));
    }

    /**
     */
    async createTeam(requestParameters: CreateTeamRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TeamLightResponse> {
        const response = await this.createTeamRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getTeamRaw(requestParameters: GetTeamRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PageTeamLightResponse>> {
        if (requestParameters.page === null || requestParameters.page === undefined) {
            throw new runtime.RequiredError('page','Required parameter requestParameters.page was null or undefined when calling getTeam.');
        }

        if (requestParameters.size === null || requestParameters.size === undefined) {
            throw new runtime.RequiredError('size','Required parameter requestParameters.size was null or undefined when calling getTeam.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer-key", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/teams/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PageTeamLightResponseFromJSON(jsonValue));
    }

    /**
     */
    async getTeam(requestParameters: GetTeamRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PageTeamLightResponse> {
        const response = await this.getTeamRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getTeamDetailRaw(requestParameters: GetTeamDetailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TeamResponse>> {
        if (requestParameters.teamId === null || requestParameters.teamId === undefined) {
            throw new runtime.RequiredError('teamId','Required parameter requestParameters.teamId was null or undefined when calling getTeamDetail.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer-key", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/teams/{teamId}`.replace(`{${"teamId"}}`, encodeURIComponent(String(requestParameters.teamId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TeamResponseFromJSON(jsonValue));
    }

    /**
     */
    async getTeamDetail(requestParameters: GetTeamDetailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TeamResponse> {
        const response = await this.getTeamDetailRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async setTeamMembersRaw(requestParameters: SetTeamMembersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TeamLightResponse>> {
        if (requestParameters.teamId === null || requestParameters.teamId === undefined) {
            throw new runtime.RequiredError('teamId','Required parameter requestParameters.teamId was null or undefined when calling setTeamMembers.');
        }

        if (requestParameters.teamMemberRequest === null || requestParameters.teamMemberRequest === undefined) {
            throw new runtime.RequiredError('teamMemberRequest','Required parameter requestParameters.teamMemberRequest was null or undefined when calling setTeamMembers.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer-key", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/teams/{teamId}/members`.replace(`{${"teamId"}}`, encodeURIComponent(String(requestParameters.teamId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TeamMemberRequestToJSON(requestParameters.teamMemberRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TeamLightResponseFromJSON(jsonValue));
    }

    /**
     */
    async setTeamMembers(requestParameters: SetTeamMembersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TeamLightResponse> {
        const response = await this.setTeamMembersRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
