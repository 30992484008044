/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FormationResponse } from './FormationResponse';
import {
    FormationResponseFromJSON,
    FormationResponseFromJSONTyped,
    FormationResponseToJSON,
} from './FormationResponse';
import type { UserLightResponse } from './UserLightResponse';
import {
    UserLightResponseFromJSON,
    UserLightResponseFromJSONTyped,
    UserLightResponseToJSON,
} from './UserLightResponse';

/**
 * 
 * @export
 * @interface SubscriptionResponse
 */
export interface SubscriptionResponse {
    /**
     * 
     * @type {string}
     * @memberof SubscriptionResponse
     */
    id?: string;
    /**
     * 
     * @type {UserLightResponse}
     * @memberof SubscriptionResponse
     */
    user?: UserLightResponse;
    /**
     * 
     * @type {FormationResponse}
     * @memberof SubscriptionResponse
     */
    formation?: FormationResponse;
    /**
     * 
     * @type {boolean}
     * @memberof SubscriptionResponse
     */
    sealed?: boolean;
    /**
     * 
     * @type {UserLightResponse}
     * @memberof SubscriptionResponse
     */
    creator?: UserLightResponse;
    /**
     * 
     * @type {boolean}
     * @memberof SubscriptionResponse
     */
    saved?: boolean;
}

/**
 * Check if a given object implements the SubscriptionResponse interface.
 */
export function instanceOfSubscriptionResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function SubscriptionResponseFromJSON(json: any): SubscriptionResponse {
    return SubscriptionResponseFromJSONTyped(json, false);
}

export function SubscriptionResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): SubscriptionResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'user': !exists(json, 'user') ? undefined : UserLightResponseFromJSON(json['user']),
        'formation': !exists(json, 'formation') ? undefined : FormationResponseFromJSON(json['formation']),
        'sealed': !exists(json, 'sealed') ? undefined : json['sealed'],
        'creator': !exists(json, 'creator') ? undefined : UserLightResponseFromJSON(json['creator']),
        'saved': !exists(json, 'saved') ? undefined : json['saved'],
    };
}

export function SubscriptionResponseToJSON(value?: SubscriptionResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'user': UserLightResponseToJSON(value.user),
        'formation': FormationResponseToJSON(value.formation),
        'sealed': value.sealed,
        'creator': UserLightResponseToJSON(value.creator),
        'saved': value.saved,
    };
}

