import React, { useState } from 'react';
import styled from 'styled-components';

const Container = styled.div`
  height: 100%;
  width: 100%;
  max-width: 100%;
`;


const UploadContainer = styled.div`
  border: 2px dashed rgba(0, 0, 0, 0.25);
  text-align: center;
  cursor: pointer;
  border-radius : 15px;
  background: #fafafa;
  color: rgba(0, 0, 0, 0.50);
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-item: center;
  justify-content: center;
`;

const UploadText = styled.div`
  margin-top: 10px;
`;

// const ImagePreview = styled.img`
//   height: 100%;
//   width: auto; 
//   border-radius: 15px;
//   object-fit: contain; 
//   display: block;
// `;

const ImagePreview = styled.div`
  height: 100%;
  width: 100%;
  border-radius: 15px;
  background: #fafafa;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
`;

export const UploadImage: React.FC = () => {
  const [selectedImage, setSelectedImage] = useState<string | null>(null);

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file && file.type.startsWith('image/')) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setSelectedImage(reader.result as string);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <Container>
      {selectedImage ? (
        <ImagePreview style={{ backgroundImage: `url(${selectedImage})` }} />
      ) : (
        <UploadContainer onClick={() => document.getElementById('imageInput')?.click()}>
          <div>+</div>
          <UploadText>Upload</UploadText>
          <input
            id="imageInput"
            type="file"
            accept="image/*"
            onChange={handleImageChange}
            style={{ display: 'none' }}
          />
        </UploadContainer>
      )}
    </Container>
  );
};
