import { Button, Modal, Space, Table, TableProps } from "antd";
import React, { FC, useEffect, useState } from "react";
import styled from "styled-components";
import { CreateRole } from "./CreateRole";
import { getRoles } from "../../utils/Role";
import { ModifyRole } from "./ModifyRole";
/*
 * Props.
 */

/*
 * Styles.
 */

const Container = styled.div`
    display: flex;
    align-items: center;
    gap: 25px;
    flex-direction: column;
    width: 100%;
    height: 100%;
`;

const Header = styled.div`
    display: flex;
    justify-content: flex-end;
    width: 100%;
`;


interface DataType {
  key: string;
  name: string;
  userCount: number; 
}

const dataExemple: DataType[] = [
  { key: '1', name: 'Admin', userCount: 10 },
  { key: '2', name: 'User', userCount: 200 },
  { key: '3', name: 'Guest', userCount: 50 },
  { key: '4', name: 'Moderator', userCount: 5 },
  { key: '5', name: 'Super Admin', userCount: 1 },
]

export const RoleManagerPage: FC = () => {

  const [data, setData] = useState<DataType[]>(dataExemple);
  const [open, setOpen] = useState<boolean>(false);
  const [openCreateUser, setOpenCreateUser] = useState<boolean>(false);

  useEffect(() => {
    getRoles().then((response) => {
      console.log(response);
      setData(response.content.map((role) => {
        return {
          key: role.id,
          name: role.name,
          userCount: 0
        }
      }));
    });
  }, []);

  const columns: TableProps<DataType>['columns'] = [
    {
      title: 'Role Name',
      dataIndex: 'name',
      key: 'name',
      render: (text) => <a>{text}</a>,
    },
    {
      title: 'Number of Users',
      dataIndex: 'userCount',
      key: 'userCount',
      render: (userCount) => <span>{userCount}</span>, // Affiche le nombre d'utilisateurs
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <Space size="middle">
          <a onClick={() => setOpen(true)}>Edit</a>
        </Space>
      ),
    },
  ];

  return (
    <Container>
      <ModifyRole open={open} setOpen={setOpen}/>
      <CreateRole open={openCreateUser} setOpen={setOpenCreateUser}/>
      <Header>
        <Button type="primary" onClick={() => setOpenCreateUser(true)}>+ Add New Role</Button>
      </Header>
      <Table columns={columns} dataSource={data} style={{ width: "100%" }} />
    </Container>
  );
}
