import React, { FC, useEffect, useState } from "react";
import styled from "styled-components";
import { Sider } from "../components/sider/Sider";
import { ModuleCard } from "../components/module/ModuleCard";
import { ButtonChat } from "../components/buttons/ButtonChat";
import { useNavigate } from "react-router-dom";

import { GetAllModules, createModule } from "../utils/module";
import { FormationResponse, ModuleResponse } from "../api";
import { createFormation, getMyFormation } from "../utils/formation";
import { Tabs } from "../components/commun/Tabs";
import { FormationCardEdible } from "../components/module/FormationCardEdible";
import { useTranslation } from "react-i18next";

/*
 * Styles.
 */

const Container = styled.div`
  display: flex;
  height: 100vh;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  gap: 10px;
  

`;

const MissionsContainer = styled.div`
  display: flex;
  width: calc(100vw - 300px - 60px); 
  overflow-x: auto;
  gap: 15px;
  padding: 20px 0;
  margin: 0 30px;
  z-index:1;
  flex-wrap: wrap;

  &::-webkit-scrollbar {
    height: 7px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 5px;
    background: rgba(200,200,200,0.1);
    // box-shadow: 1px 1px 4px 0px rgba(0, 0, 0, 0.25) inset;
  }

  &::-webkit-scrollbar-thumb {
    background: #AAA;
    border-radius: 5px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #888;
  }

  &::-webkit-scrollbar-button {
    display: none;
  }
`;


const Divider = styled.div`
    display: flex;
    padding-top: 15px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;
`;

const Line = styled.div`
    height: 1px;
    align-self: stretch;
    background: #D9D9D9;
`;


const TitlePage = styled.h1`
  color: #363636;
  font-size: 24px;
  padding: 20px 0 0 20px;

  font-weight: 700;
`;

const Menu = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 30px;
  align-self: stretch;
  width: 100%;
  `;

const ContainerModule = styled.div`
  display: flex;
  width: 250px;
  height: 120px;
  min-width: 250px;
  border-radius: 15px;
  background: #fff;
  border: 1px solid #D2D6DB;
  z-index: 1000;
  flex-shrink: 0; /* Empêche la réduction de la carte */
  cursor:pointer;
  justify-content: center;
  align-items: center;

  color: #D2D6DB;
  font-size: 40px;

  font-weight: 200;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;


  transition: background  0.1s ease;

  &:hover{
      background: #eeeeee;
  }
  
`;

const ContainerFormation = styled(ContainerModule)`
  width: 256px;
  height: 280px;
  min-width: 256px;
`;

const defaultContentModule = `
<h2>
  Hi there,
</h2>
<p>
  this is a <em>basic</em> example of <strong>tiptap</strong>. Sure, there are all kind of basic text styles you’d probably expect from a text editor. But wait until you see the lists:
</p>
<ul>
  <li>
    That’s a bullet list with one …
  </li>
  <li>
    … or two list items.
  </li>
</ul>

<pre><code class="language-javascript">for (var i=1; i <= 20; i++)
{
  if (i % 15 == 0)
    console.log("FizzBuzz");
  else if (i % 3 == 0)
    console.log("Fizz");
  else if (i % 5 == 0)
    console.log("Buzz");
  else
    console.log(i);
}</code></pre>

<p>
  Isn’t that great? And all of that is editable. But wait, there’s more. Let’s try a code block:
</p>
<pre><code class="language-css">body {
display: none;
}</code></pre>
<p>
  I know, I know, this is impressive. It’s only the tip of the iceberg though. Give it a try and click a little bit around. Don’t forget to check the other examples too.
</p>
<blockquote>
  Wow, that’s amazing. Good work, boy! 👏
  <br />
  — Mom
</blockquote>
`

/*
 * Component.
 */

export const LabPage: FC = () => {
  const { t } = useTranslation();
  const [modules, setModules] = useState<Array<ModuleResponse>>([]); // État pour stocker les modules
  const [formations, setFormations] = useState<FormationResponse[]>([]);
  const [selectTab, setSelectTab] = useState<string>(t("Mes modules"));

  const navigate = useNavigate();
  const handleButtonClick = (url: string) => {
    navigate(url);
  };


  const fetchFormations = async () => {
    try {
      const res = await getMyFormation(); // Attendre le résultat de la Promise
      console.log(res)
      if (res) {
        setFormations(res);
      }
    } catch (error) {
      console.error('Erreur lors de la récupération des formations :', error);
    }
  };

  const fetchModules = async () => {
    try {
      const res = await GetAllModules(); // Attendre le résultat de la Promise
      console.log(res)
      if (res) {
        setModules(res);
      }
    } catch (error) {
      console.error('Erreur lors de la récupération des modules :', error);
    }
  };

  useEffect(() => {
    fetchModules(); // Appeler la fonction asynchrone
    fetchFormations()
  }, []);
  

  const createModuleClick = async () => {
    const module = await createModule("", defaultContentModule)
    console.log(module)
    if (module.id)
      handleButtonClick("/createmodule/" + module.id)
  }

  const createFormationClick = async () => {
    const module = await createFormation()
    console.log(module)
    if (module?.id)
      handleButtonClick("/createpipeline/" + module.id)
  }

  return (
    <Container>
      <Sider />
      <ButtonChat />
      <Content>
        <TitlePage>{t("Mon Lab")}</TitlePage>
        <div style={{ margin: "0 20px" }}>
          <Menu>
            <Tabs selectTab={selectTab} setSelectTab={setSelectTab} tabList={[t("Mes modules"), t("Mes formations")]} />
          </Menu>
          <Divider><Line /></Divider>
        </div>
        {selectTab === t("Mes modules") ?
          <MissionsContainer>
            <ContainerModule onClick={createModuleClick}>+</ContainerModule>
            {modules ? modules.map((e: any) => {
              return (<ModuleCard
                key={e.id}
                id={e.id}
                title={e.title}
                tags={Array.from(e.tags)}
                onModuleDelete={fetchModules}
                modificationDate={e.modificationDate}
              />)
            }) : null}
          </MissionsContainer>
          :
          <MissionsContainer>
            <ContainerFormation  onClick={createFormationClick}>+</ContainerFormation>
            {formations ? formations.map((e: any) => {
              return (<FormationCardEdible
                key={e.id}
                id={e.id}
                title={e.title}
                description={e.summary}
                tags={Array.from(e.tags)}
                onModuleDelete={fetchFormations}
              />)
            }) : null}
          </MissionsContainer>
        }
      </Content>
    </Container>
  );
};
