import { AdminUserManagementApi, CreateUserRequest, GetUsersRequest } from "../api/apis/AdminUserManagementApi";
import { RoleApi } from "../api/apis/RoleApi";
import { Configuration } from "../api/runtime";
import { getToken } from "./token";


const api = new AdminUserManagementApi(new Configuration({
  accessToken: getToken,
}));

const apiRole = new RoleApi(new Configuration({
  accessToken: getToken,
}));

export const getUsersManagement = async () => {
  try {
    const body: GetUsersRequest = {
      page: 0,
      size: 1000,
    }
    const response = await api.getUsers(body);
    console.log('Abonnement récupéré avec succès:', response);
    return response;
  } catch (error) {
    console.error('Erreur lors de la récupération de l’abonnement:', error);
    return [];
  }
};


export const createNewUser = async (prenom: string, nom: string, email: string) => {
  try {
    const user = {
      firstname: prenom,
      lastname: nom,
      email: email,
      roles: []
    }
    const body: CreateUserRequest = {
      adminUserCreateRequest: user
    }
    const response = await api.createUser(body);
    return response;
  } catch (error) {
    console.error('Erreur lors de la création de l’utilisateur:', error);
    return [];
  }
}

export const getUserInfo = async (id: string) => {
  try {
    const response = await api.getUserDetail({ userId: id });
    return response;
  } catch (error) {
    console.error('Erreur lors de la récupération de l’utilisateur:', error);
    return [];
  }
}

export const getRoleCanAssign = async () => {
  try {
    const response = await apiRole.getRoleUserCanAssign();
    return response;
  } catch (error) {
    console.error('Erreur lors de la récupération des rôles:', error);
    return [];
  }
}

export const addRoleToUser = async (userId: string, roleIds: string) => {
  try {
    const response = await api.addRoleToUser({ userId: userId, roleId: roleIds });
    return response;
  } catch (error) {
    console.error('Erreur lors de l’ajout du rôle:', error);
    return [];
  }
}

export const removeRoleToUser = async (userId: string, roleIds: string) => {
  try {
    const response = await api.removeRoleToUser({ userId: userId, roleId: roleIds });
    return response;
  } catch (error) {
    console.error('Erreur lors de l’ajout du rôle:', error);
    return [];
  }
}