import { useCurrentEditor } from '@tiptap/react';
import './styles.scss'

import React, { useEffect, useMemo, useState } from 'react'
import { styled } from 'styled-components'
import { AlignCenterOutlined, AlignLeftOutlined, AlignRightOutlined, BoldOutlined, ClearOutlined, CodeOutlined, DownOutlined, ExperimentOutlined, FontColorsOutlined, FontSizeOutlined, HighlightOutlined, ItalicOutlined, LineOutlined, LinkOutlined, NumberOutlined, OrderedListOutlined, PaperClipOutlined, PictureOutlined, RedoOutlined, RightOutlined, SaveOutlined, StrikethroughOutlined, TableOutlined, UnderlineOutlined, UndoOutlined, UnorderedListOutlined } from '@ant-design/icons';
import ColorPicker, { Color } from 'antd/es/color-picker';
import { Cascader } from 'antd';



const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 5px;
  background-color: #F8F9FA;
  color: #5C5C5C;
  align-items: center;
  padding: 10px 20px;
  
`;

const Separator = styled.div`
  width: 1px;
  height: 16px;
  background: #5C5C5C;
`;

const Button = styled.button`
  border-radius: 5px;
  background-color: #FFFFFF;
  color: #5C5C5C;
  padding: 5px 10px;
  cursor: pointer;
  border: 1px solid transparent;

  &:hover {
    border: 1px solid #D2D6DB;
    background-color: #F8F9FA;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
`;

const ButtonDropdown = styled(Button)`
    display: flex;
    flex-direction: row;
    gap:3px;
    align-items: center;
    justify-content: center;
`;

const Element = styled.div`
  height: 100%;
  flex-grow: 1;
`;

interface Option {
    value: string | number;
    label: string;
    children?: Option[];
}

const optionsTitre: Option[] = [
    {
        value: 'Text normal',
        label: 'Text normal',
    },
    {
        value: 'Titre 1',
        label: 'Titre 1',
    },
    {
        value: 'Titre 2',
        label: 'Titre 2',
    },
    {
        value: 'Titre 3',
        label: 'Titre 3',
    },
    {
        value: 'Titre 4',
        label: 'Titre 4',
    },
    {
        value: 'Titre 5',
        label: 'Titre 5',
    },
    {
        value: 'Titre 6',
        label: 'Titre 6',
    }
];

const optionsTable: Option[] = [
    {
        value: 'addColumnBefore',
        label: 'addColumnBefore',
    },
    {
        value: 'addColumnAfter',
        label: 'addColumnAfter',
    },
    {
        value: 'deleteColumn',
        label: 'deleteColumn',
    },
    {
        value: 'addRowBefore',
        label: 'addRowBefore',
    },
    {
        value: 'addRowAfter',
        label: 'addRowAfter',
    },
    {
        value: 'deleteRow',
        label: 'deleteRow',
    },
    {
        value: 'deleteTable',
        label: 'deleteTable',
    },
    {
        value: 'mergeCells',
        label: 'mergeCells',
    },
    {
        value: 'splitCell',
        label: 'splitCell',
    },
    {
        value: 'toggleHeaderColumn',
        label: 'toggleHeaderColumn',
    },
    {
        value: 'toggleHeaderRow',
        label: 'toggleHeaderRow',
    },
    {
        value: 'toggleHeaderCell',
        label: 'toggleHeaderCell',
    },
    {
        value: 'mergeOrSplit',
        label: 'mergeOrSplit',
    },
];

interface MenuBarProps {
    editor: any
    onSave: any
}

export const MenuBar: React.FC<MenuBarProps> = ({ editor, onSave }) => {
    const [color, setColor] = useState<Color>('#1677ff');
    const [openColor, setOpenColor] = useState(false);
    const [openTable, setOpenTable] = useState(false);

    const bgColor = useMemo<string>(
        () => (typeof color === 'string' ? color : color!.toHexString()),
        [color],
    );

    const handleTableAction = (actionType: string[]) => {
        if (!actionType) return;
        switch (actionType[0]) {
          case 'addColumnBefore':
            editor.chain().focus().addColumnBefore().run();
            break;
          case 'addColumnAfter':
            editor.chain().focus().addColumnAfter().run();
            break;
          case 'deleteColumn':
            editor.chain().focus().deleteColumn().run();
            break;
          case 'addRowBefore':
            editor.chain().focus().addRowBefore().run();
            break;
          case 'addRowAfter':
            editor.chain().focus().addRowAfter().run();
            break;
          case 'deleteRow':
            editor.chain().focus().deleteRow().run();
            break;
          case 'deleteTable':
            editor.chain().focus().deleteTable().run();
            break;
          case 'mergeCells':
            editor.chain().focus().mergeCells().run();
            break;
          case 'splitCell':
            editor.chain().focus().splitCell().run();
            break;
          case 'toggleHeaderColumn':
            editor.chain().focus().toggleHeaderColumn().run();
            break;
          case 'toggleHeaderRow':
            editor.chain().focus().toggleHeaderRow().run();
            break;
          case 'toggleHeaderCell':
            editor.chain().focus().toggleHeaderCell().run();
            break;
          case 'mergeOrSplit':
            editor.chain().focus().mergeOrSplit().run();
            break;
          default:
            console.log('Unknown action:', actionType);
            break;
        }
      };

    const onChange = (value: string[]) => {
        console.log(value);
        if (!value) return;
        switch (value[0]) {
            case 'Text normal':
                editor.chain().focus().setParagraph().run()
                break;
            case 'Titre 1':
                editor.chain().focus().toggleHeading({ level: 1 }).run();
                break;
            case 'Titre 2':
                editor.chain().focus().toggleHeading({ level: 2 }).run();
                break;
            case 'Titre 3':
                editor.chain().focus().toggleHeading({ level: 3 }).run();
                break;
            case 'Titre 4':
                editor.chain().focus().toggleHeading({ level: 4 }).run();
                break;
            case 'Titre 5':
                editor.chain().focus().toggleHeading({ level: 5 }).run();
                break;
            case 'Titre 6':
                editor.chain().focus().toggleHeading({ level: 6 }).run();
                break;
            default:
                break;
        }
    };

    const addImage = () => {
        const url = window.prompt('URL')

        if (url) {
            editor.chain().focus().setImage({ src: url }).run()
        }
    }

    return (
        <Container>
            <Button
                onClick={() => editor.chain().focus().undo().run()}
                disabled={
                    !editor.can()
                        .chain()
                        .focus()
                        .undo()
                        .run()
                }
            >
                <UndoOutlined />
            </Button>
            <Button
                onClick={() => editor.chain().focus().redo().run()}
                disabled={
                    !editor.can()
                        .chain()
                        .focus()
                        .redo()
                        .run()
                }
            >
                <RedoOutlined />
            </Button>
            <Separator />
            <Cascader options={optionsTitre} onChange={onChange}>
                <ButtonDropdown>
                    <Element>
                        <FontSizeOutlined />
                    </Element>
                    <Element>
                        <DownOutlined
                            style={{
                                fontSize: '8px',
                            }}
                        />
                    </Element>
                </ButtonDropdown>
            </Cascader>
            <Separator />
            <ButtonDropdown className={editor.isActive('textStyle', { color: bgColor }) ? 'is-active' : ''}>
                <Element onClick={() => editor.chain().focus().setColor(bgColor).run()}>
                    <FontColorsOutlined style={{ color: bgColor }} />
                </Element>
                <ColorPicker value={color} onChange={setColor} onOpenChange={setOpenColor} >
                    <Element>
                        <DownOutlined
                            onClick={() => setOpenColor(!openColor)}
                            rotate={openColor ? 180 : 0}
                            style={{
                                fontSize: '8px',
                            }}
                        />
                    </Element>
                </ColorPicker>
            </ButtonDropdown>
            <Button onClick={() => editor.chain().focus().toggleHighlight().run()} className={editor.isActive('highlight') ? 'is-active' : ''}>
                <HighlightOutlined />
            </Button>
            <Button
                onClick={() => editor.chain().focus().toggleBold().run()}
                disabled={
                    !editor.can()
                        .chain()
                        .focus()
                        .toggleBold()
                        .run()
                }
                className={editor.isActive('bold') ? 'is-active' : ''}
            >
                <BoldOutlined />
            </Button>
            <Button
                onClick={() => editor.chain().focus().toggleItalic().run()}
                disabled={
                    !editor.can()
                        .chain()
                        .focus()
                        .toggleItalic()
                        .run()
                }
                className={editor.isActive('italic') ? 'is-active' : ''}
            >
                <ItalicOutlined />

            </Button>
            <Button
                onClick={() => editor.chain().focus().toggleUnderline().run()}
                disabled={
                    !editor.can()
                        .chain()
                        .focus()
                        .toggleUnderline()
                        .run()
                }
                className={editor.isActive('underline') ? 'is-active' : ''}
            >
                <UnderlineOutlined />
            </Button>
            <Button
                onClick={() => editor.chain().focus().toggleStrike().run()}
                disabled={
                    !editor.can()
                        .chain()
                        .focus()
                        .toggleStrike()
                        .run()
                }
                className={editor.isActive('strike') ? 'is-active' : ''}
            >
                <StrikethroughOutlined />
            </Button>
            <Button onClick={() => editor.chain().focus().unsetAllMarks().run()}>
                <ClearOutlined />
            </Button>
            <Separator />
            <Button
                onClick={() => editor.chain().focus().toggleBulletList().run()}
                className={editor.isActive('bulletList') ? 'is-active' : ''}
            >
                <UnorderedListOutlined />
            </Button>
            <Button
                onClick={() => editor.chain().focus().toggleOrderedList().run()}
                className={editor.isActive('orderedList') ? 'is-active' : ''}
            >
                <OrderedListOutlined />
            </Button>
            <Button
                onClick={() => editor.chain().focus().toggleCodeBlock().run()}
                className={editor.isActive('codeBlock') ? 'is-active' : ''}
            >
                {/* <ExperimentOutlined /> */}
                <CodeOutlined />
                {/* Code */}
            </Button>
            <Separator />
            <Button onClick={() => editor.chain().focus().setTextAlign('left').run()} className={editor.isActive({ textAlign: 'left' }) ? 'is-active' : ''}>
                <AlignLeftOutlined />
            </Button>
            <Button onClick={() => editor.chain().focus().setTextAlign('center').run()} className={editor.isActive({ textAlign: 'center' }) ? 'is-active' : ''}>
                <AlignCenterOutlined />
            </Button>
            <Button onClick={() => editor.chain().focus().setTextAlign('right').run()} className={editor.isActive({ textAlign: 'right' }) ? 'is-active' : ''}>
                <AlignRightOutlined />
            </Button>
            <Separator />
            <Button
                onClick={() => editor.chain().focus().toggleBlockquote().run()}
                className={editor.isActive('blockquote') ? 'is-active' : ''}
            >
                <NumberOutlined />
            </Button>
            <Button onClick={() => editor.chain().focus().setHorizontalRule().run()}>
                <LineOutlined />
            </Button>
            <Button onClick={addImage}>
                <PictureOutlined />
            </Button>
            <ButtonDropdown>
                <Element onClick={() => editor.chain().focus().insertTable({ rows: 3, cols: 3, withHeaderRow: true }).run()}>
                    <TableOutlined />
                </Element>
                <Cascader options={optionsTable} onChange={handleTableAction} placement={'bottomRight'}>
                    <Element>
                        <DownOutlined
                            rotate={openColor ? 180 : 0}
                            style={{
                                fontSize: '8px',
                            }}
                        />
                    </Element>
                </Cascader>
            </ButtonDropdown>
            <Separator />
            <Button onClick={onSave} style={{ background: "#5cb85c", color: "#fff", padding: "5px 15px" }}>
                <SaveOutlined style={{ color: "#fff" }} />
                {/* Save */}
            </Button>

        </Container >

    )
}