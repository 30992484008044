import React, { FC, useState } from "react";
import styled from "styled-components";
import { Sider } from "../components/sider/Sider";
import { Tabs } from "../components/commun/Tabs";
import { Profile } from "../components/settings/Profile";
import { Password } from "../components/settings/Password";
import { Payment } from "../components/settings/Payment";
import { UserManagerPage } from "../components/admin/UserManagerPage";
import { RoleManagerPage } from "../components/admin/RoleManagerPage";
import { PluginManagerPage } from "../components/admin/plugin/PluginManagerPage";
import { ButtonChat } from "../components/buttons/ButtonChat";

/*
 * Styles.
 */

const Container = styled.div`
  display: flex;
  height: 100vh;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100%;
  padding : 20px;
  align-items: flex-start;
  gap: 15px;
`;

const Title = styled.h1`
  color: #363636;
  font-size: 24px;
  font-weight: 700;
`;

/*
 * Component.
 */


export const AdminPage: FC = () => {
  const [selectTab, setSelectTab] = useState<string>("User Management");


  const renderTabContent = () => {
    switch (selectTab) {
      case 'User Management':
        return <UserManagerPage />;
      case 'Role Manager':
        return <RoleManagerPage />;
      case 'My Plugins':
        return <PluginManagerPage />;
      default:
        return null;
    }
  }

  return (
    <Container>
      <Sider />
      <ButtonChat />
      <Content>
        <Title>Admin Panel</Title>
        <Tabs selectTab={selectTab} setSelectTab={setSelectTab} tabList={["User Management", "Role Manager", "My Teams", "My Projects", "My Plugins", "My Subscription"]} />
        {renderTabContent()}
      </Content>
    </Container>
  );
};
