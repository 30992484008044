/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ChapterResponse,
  ChaptersRequest,
  FormationRequest,
  FormationResponse,
  PageFormationLightResponse,
} from '../models/index';
import {
    ChapterResponseFromJSON,
    ChapterResponseToJSON,
    ChaptersRequestFromJSON,
    ChaptersRequestToJSON,
    FormationRequestFromJSON,
    FormationRequestToJSON,
    FormationResponseFromJSON,
    FormationResponseToJSON,
    PageFormationLightResponseFromJSON,
    PageFormationLightResponseToJSON,
} from '../models/index';

export interface CreateChaptersRequest {
    formationId: string;
    chaptersRequest: ChaptersRequest;
}

export interface CreateFormationRequest {
    formationRequest: FormationRequest;
}

export interface DeleteFormationRequest {
    formationId: string;
}

export interface GetChaptersRequest {
    formationId: string;
}

export interface GetFormationRequest {
    page: number;
    size: number;
}

export interface GetFormationDetailRequest {
    formationId: string;
}

export interface GetMyFormationRequest {
    page: number;
    size: number;
}

export interface UpdateFormationRequest {
    formationId: string;
    formationRequest: FormationRequest;
}

/**
 * 
 */
export class FormationApi extends runtime.BaseAPI {

    /**
     */
    async createChaptersRaw(requestParameters: CreateChaptersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FormationResponse>> {
        if (requestParameters.formationId === null || requestParameters.formationId === undefined) {
            throw new runtime.RequiredError('formationId','Required parameter requestParameters.formationId was null or undefined when calling createChapters.');
        }

        if (requestParameters.chaptersRequest === null || requestParameters.chaptersRequest === undefined) {
            throw new runtime.RequiredError('chaptersRequest','Required parameter requestParameters.chaptersRequest was null or undefined when calling createChapters.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer-key", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/formations/{formationId}/chapters`.replace(`{${"formationId"}}`, encodeURIComponent(String(requestParameters.formationId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ChaptersRequestToJSON(requestParameters.chaptersRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FormationResponseFromJSON(jsonValue));
    }

    /**
     */
    async createChapters(requestParameters: CreateChaptersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FormationResponse> {
        const response = await this.createChaptersRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async createFormationRaw(requestParameters: CreateFormationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FormationResponse>> {
        if (requestParameters.formationRequest === null || requestParameters.formationRequest === undefined) {
            throw new runtime.RequiredError('formationRequest','Required parameter requestParameters.formationRequest was null or undefined when calling createFormation.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer-key", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/formations/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FormationRequestToJSON(requestParameters.formationRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FormationResponseFromJSON(jsonValue));
    }

    /**
     */
    async createFormation(requestParameters: CreateFormationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FormationResponse> {
        const response = await this.createFormationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async deleteFormationRaw(requestParameters: DeleteFormationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FormationResponse>> {
        if (requestParameters.formationId === null || requestParameters.formationId === undefined) {
            throw new runtime.RequiredError('formationId','Required parameter requestParameters.formationId was null or undefined when calling deleteFormation.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer-key", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/formations/{formationId}`.replace(`{${"formationId"}}`, encodeURIComponent(String(requestParameters.formationId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FormationResponseFromJSON(jsonValue));
    }

    /**
     */
    async deleteFormation(requestParameters: DeleteFormationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FormationResponse> {
        const response = await this.deleteFormationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getChaptersRaw(requestParameters: GetChaptersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ChapterResponse>>> {
        if (requestParameters.formationId === null || requestParameters.formationId === undefined) {
            throw new runtime.RequiredError('formationId','Required parameter requestParameters.formationId was null or undefined when calling getChapters.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer-key", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/formations/{formationId}/chapters`.replace(`{${"formationId"}}`, encodeURIComponent(String(requestParameters.formationId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ChapterResponseFromJSON));
    }

    /**
     */
    async getChapters(requestParameters: GetChaptersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ChapterResponse>> {
        const response = await this.getChaptersRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getFormationRaw(requestParameters: GetFormationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PageFormationLightResponse>> {
        if (requestParameters.page === null || requestParameters.page === undefined) {
            throw new runtime.RequiredError('page','Required parameter requestParameters.page was null or undefined when calling getFormation.');
        }

        if (requestParameters.size === null || requestParameters.size === undefined) {
            throw new runtime.RequiredError('size','Required parameter requestParameters.size was null or undefined when calling getFormation.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer-key", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/formations/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PageFormationLightResponseFromJSON(jsonValue));
    }

    /**
     */
    async getFormation(requestParameters: GetFormationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PageFormationLightResponse> {
        const response = await this.getFormationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getFormationDetailRaw(requestParameters: GetFormationDetailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FormationResponse>> {
        if (requestParameters.formationId === null || requestParameters.formationId === undefined) {
            throw new runtime.RequiredError('formationId','Required parameter requestParameters.formationId was null or undefined when calling getFormationDetail.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer-key", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/formations/{formationId}`.replace(`{${"formationId"}}`, encodeURIComponent(String(requestParameters.formationId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FormationResponseFromJSON(jsonValue));
    }

    /**
     */
    async getFormationDetail(requestParameters: GetFormationDetailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FormationResponse> {
        const response = await this.getFormationDetailRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getMyFormationRaw(requestParameters: GetMyFormationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PageFormationLightResponse>> {
        if (requestParameters.page === null || requestParameters.page === undefined) {
            throw new runtime.RequiredError('page','Required parameter requestParameters.page was null or undefined when calling getMyFormation.');
        }

        if (requestParameters.size === null || requestParameters.size === undefined) {
            throw new runtime.RequiredError('size','Required parameter requestParameters.size was null or undefined when calling getMyFormation.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer-key", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/formations/my-formations`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PageFormationLightResponseFromJSON(jsonValue));
    }

    /**
     */
    async getMyFormation(requestParameters: GetMyFormationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PageFormationLightResponse> {
        const response = await this.getMyFormationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async updateFormationRaw(requestParameters: UpdateFormationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FormationResponse>> {
        if (requestParameters.formationId === null || requestParameters.formationId === undefined) {
            throw new runtime.RequiredError('formationId','Required parameter requestParameters.formationId was null or undefined when calling updateFormation.');
        }

        if (requestParameters.formationRequest === null || requestParameters.formationRequest === undefined) {
            throw new runtime.RequiredError('formationRequest','Required parameter requestParameters.formationRequest was null or undefined when calling updateFormation.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer-key", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/formations/{formationId}`.replace(`{${"formationId"}}`, encodeURIComponent(String(requestParameters.formationId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: FormationRequestToJSON(requestParameters.formationRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FormationResponseFromJSON(jsonValue));
    }

    /**
     */
    async updateFormation(requestParameters: UpdateFormationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FormationResponse> {
        const response = await this.updateFormationRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
