import React, { FC, useEffect, useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { FormationCard } from "../components/commun/FormationCard";
import { ButtonChat } from "../components/buttons/ButtonChat";
import { Sider } from "../components/sider/Sider";
import { Tabs } from "../components/commun/Tabs";
import { getSubscriptionFunction } from "../utils/subscription";
import { SubscriptionResponse } from "../api/models/SubscriptionResponse";
import { useTranslation } from 'react-i18next';
import { Language } from "../components/commun/language";

/*
 * Styles.
 */

const Container = styled.div`
  display: flex;
  height: 100vh;
  width: 100%;
  position: relative;
`;

const LanguageStyled = styled.div`
  position: absolute;
  right: 10px;
  bottom: 10px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100%;
  gap: 15px;
  padding: 20px;
`;

const TitlePage = styled.h1`
  color: #363636;
  font-size: 24px;

  font-weight: 700;
`;


const FormationsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 3px 10px;
  overflow-y: auto;
  z-index:1;

  &::-webkit-scrollbar {
    width: 15px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 5px;
    background: rgba(200,200,200,0.1);
    box-shadow: 1px 1px 4px 0px rgba(0, 0, 0, 0.25) inset;
  }

  &::-webkit-scrollbar-thumb {
    background: #AAA;
    border-radius: 5px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #888;
  }

  &::-webkit-scrollbar-button {
    display: none;
  }
`;

const Header = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
    align-self: stretch;
`;

const Title = styled.h2`
    color: #1B3042;
    font-size: 16px;
    font-weight: 700;
`;

const Description = styled.span`
    color: #1B3042;
    font-size: 12px;
    font-weight: 400;
`;

const Divider = styled.div`
    display: flex;
    padding-top: 15px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;
`;

const Line = styled.div`
    height: 1px;
    align-self: stretch;
    background: #D9D9D9;
`;

/*
 * Component.
 */



export const MonOnboardingPage: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [selectTab, setSelectTab] = useState<string>(t("En cours"));
  const [subscription, setSubscription] = useState<SubscriptionResponse[]>([]);

  const handleButtonClick = (url: string) => {
    navigate(url);
  };

  useEffect(() => {
    const fetchSubscription = async () => {
      const subscriptionResponse = await getSubscriptionFunction();
      setSubscription(subscriptionResponse);
    };

    fetchSubscription();
  }, []);

  return (
    <Container>
      <Sider />
      <ButtonChat />
      <Content>
        <TitlePage>{t("Mon Onboarding")}</TitlePage>
        <Tabs selectTab={selectTab} setSelectTab={setSelectTab} tabList={[t("En cours"), t("Terminé")]} />
        <Header>
          <Title>{t("Mes formations")}</Title>
          <Description>{t("Construire des compétences pour l'avenir !")}</Description>
          <Divider><Line /></Divider>
        </Header>
        <FormationsContainer>
          {subscription.map((e: SubscriptionResponse, i: number) => {
            return (<FormationCard
              key={e.id}
              title={e.formation?.title ? e.formation.title : ""}
              subtitle={e.creator?.email ? e.creator.email : ""}
              id={e.id ? e.id : ""}
              url={"https://upload.wikimedia.org/wikipedia/commons/thumb/e/e0/Logo_INA.svg/1200px-Logo_INA.svg.png"} />)
          })}
        </FormationsContainer>
      </Content>
      <LanguageStyled >
        <Language />
      </LanguageStyled>
    </Container>
  );
};
