import React, { FC, useEffect, useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { TagResponse } from "../../api/models/TagResponse";
import { Popconfirm, message } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { deleteFormation } from "../../utils/formation";
import { createApi } from 'unsplash-js';




/*
 * Styles.
 */

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  width: 256px;
  height: 280px;
  min-width: 256px;
  border-radius: 15px;
  background: #fff;
  border: 1px solid #D2D6DB;
  padding: 20px 20px;
  z-index: 1000;
  flex-shrink: 0; /* Empêche la réduction de la carte */

  cursor:pointer;

  transition: background  0.1s ease;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;


  &:hover{
      background: #eeeeee;
  }
`;

const Image = styled.div`
  width: 218px;
  height: 128px;
  border-radius: 10px;
  // background: url(<path-to-image>), lightgray 50% / cover no-repeat;
  background: #FFF4DE;
  background-size: cover; /* Redimensionne l'image pour couvrir toute la div */
  background-position: center; /* Centre l'image dans la div */
  background-repeat: no-repeat; /* Empêche la répétition de l'image */
`;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content:space-between;
  align-self: stretch;
  align-items:  flex-start ;
  gap: 1px;
`;

const HeaderContainerRow = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap:3px;
`;

const Title = styled.h2`
  color: #363636;
  font-size: 16px;
  font-weight: 700;
  margin: 0;
  padding: 0;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* Ajustez ce nombre selon vos besoins */
  -webkit-box-orient: vertical;
`;

const Tags = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
  margin-top: 5px;
`;

const Tag = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  background: #1b3042;
  color: #fff;
  font-size: 8px;
  font-weight: 400;
  padding: 5px 10px;
`;

const Content = styled.div`
  color: #5c5c5c;
  font-size: 10px;
  font-weight: 400;

  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* Ajustez ce nombre selon vos besoins */
  -webkit-box-orient: vertical;

`;


const Heart = styled.img`
  width: 27px;
  height: 27px;
  cursor: pointer;
`;

const CloseOutlinedStyle = styled(CloseOutlined)`
  cursor: pointer;
  fontSize: 16px;
  color: #727272;
  padding:2px;

  transition: color 0.3s ease;

  &:hover{
    color: #B3482F;
  }
`;

/*
 * Props.
 */

interface CardProps {
  title: string;
  tags: TagResponse[];
  description: string;
  id: string;
  onModuleDelete: () => void;
}

/*
 * Component.
 */

export const FormationCardEdible: FC<CardProps> = ({
  title,
  tags,
  id,
  description,
  onModuleDelete,
}) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const unsplash = createApi({
    accessKey: "fDh-v8ld3pWLHLZYR8JUvF2lOOEvgI9GiBVuWmnclPU",
  });
  const [imageUrl, setImageUrl] = useState("");

  useEffect(() => {
    unsplash.search.getPhotos({
      query: title,
      page: 1,
      perPage: 1,
      orientation: 'landscape',
    }).then(res => {
      // Assuming `res` is of the correct format and contains the necessary data
      const downloadUrl = res.response?.results[0]?.links.download;
      setImageUrl(downloadUrl || ""); // Fallback to an empty string if downloadUrl is undefined
    }).catch(error => {
      console.error("Error fetching image:", error);
      setImageUrl(""); // Set a default or an error image URL here if desired
    });
  }, [title]); 

  const handleButtonClick = (url: string) => {
    navigate(url);
  };

  const confirm = async (e: React.MouseEvent<HTMLElement> | undefined) => {
    if (e) {
      e.stopPropagation();
    }
    try {
      await deleteFormation(id);
      message.success('Formation deleted');
      onModuleDelete(); // Appeler pour mettre à jour la liste des modules
    } catch (error) {
      message.error('Error deleting Formation');
    }
    setOpen(false);
  };

  const cancel = (e: React.MouseEvent<HTMLElement> | undefined) => {
    if (e) {
      e.stopPropagation();
    }
    setOpen(false);
  };

  const onClickDelete = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation(); // Empêche la propagation de l'événement
    setOpen(!open);
  };

  return (
    <Container onClick={() => { handleButtonClick("/createpipeline/" + id) }}>
      <Image style={{ backgroundImage: `url(${imageUrl})` }} />
      <Card>
        <HeaderContainer>
          <HeaderContainerRow>
            <Title>{title}</Title>
            <Tags>
              {tags.map((e) => (
                <Tag key={e.id}>{e.tag}</Tag>
              ))}
            </Tags>
          </HeaderContainerRow>
          <Popconfirm
            title="Delete the module"
            description="Are you sure to delete this module?"
            open={open}
            onConfirm={confirm}
            onCancel={cancel}
            okText="Yes"
            cancelText="No"
          >
            <CloseOutlinedStyle onClick={onClickDelete} />
          </Popconfirm>
        </HeaderContainer>
        <Content>{description}</Content>
      </Card>
    </Container>
  );
};
