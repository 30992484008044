import React, { createContext, useState, useEffect } from 'react';
import { UsersApi } from '../api/apis/UsersApi.ts';
import { Configuration } from '../api/runtime.ts';
import {getToken} from '../utils/token'

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [authUser, setAuthUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true); // Nouvel état pour le chargement

    const usersApi = new UsersApi(new Configuration({
      accessToken: getToken,
    }));

    const updateAuthState = async () => {
      setIsLoading(true);
      const token = getToken();
      if (!token) {
        setAuthUser(null);
        setIsLoading(false);
        console.log('Aucun token trouvé');
        return;
      }
  
      try {
        const userData = await usersApi.getUserInfos();
  
        setAuthUser(userData);
        setIsLoading(false);
      } catch (error) {
        console.error('Erreur lors de la vérification du token : ', error);
        setAuthUser(null);
        setIsLoading(false);
      }
    };

  useEffect(() => {
    updateAuthState();
  }, []);

  return (
    <AuthContext.Provider value={{ authUser, isLoading, updateAuthState }}>
      {children}
    </AuthContext.Provider>
  );
};