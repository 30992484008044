/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RightResponse
 */
export interface RightResponse {
    /**
     * 
     * @type {string}
     * @memberof RightResponse
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof RightResponse
     */
    name?: RightResponseNameEnum;
}


/**
 * @export
 */
export const RightResponseNameEnum = {
    MenuFormationRead: 'MENU_FORMATION_READ',
    MenuAdminRead: 'MENU_ADMIN_READ',
    MenuMyonboardingRead: 'MENU_MYONBOARDING_READ',
    MenuLabRead: 'MENU_LAB_READ',
    MenuInfosRead: 'MENU_INFOS_READ',
    FormationRead: 'FORMATION_READ',
    FormationCreate: 'FORMATION_CREATE',
    FormationUpdate: 'FORMATION_UPDATE',
    FormationDelete: 'FORMATION_DELETE',
    ModuleRead: 'MODULE_READ',
    ModuleCreate: 'MODULE_CREATE',
    ModuleUpdate: 'MODULE_UPDATE',
    ModuleDelete: 'MODULE_DELETE',
    TagRead: 'TAG_READ',
    TagCreate: 'TAG_CREATE',
    TagUpdate: 'TAG_UPDATE',
    TagDelete: 'TAG_DELETE',
    UserRead: 'USER_READ',
    UserCreate: 'USER_CREATE',
    UserUpdate: 'USER_UPDATE',
    UserDelete: 'USER_DELETE',
    UserroleRead: 'USERROLE_READ',
    UserroleCreate: 'USERROLE_CREATE',
    UserroleUpdate: 'USERROLE_UPDATE',
    UserroleDelete: 'USERROLE_DELETE',
    TeamRead: 'TEAM_READ',
    TeamWrite: 'TEAM_WRITE',
    AdminUserRead: 'ADMIN_USER_READ',
    AdminUserWrite: 'ADMIN_USER_WRITE'
} as const;
export type RightResponseNameEnum = typeof RightResponseNameEnum[keyof typeof RightResponseNameEnum];


/**
 * Check if a given object implements the RightResponse interface.
 */
export function instanceOfRightResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function RightResponseFromJSON(json: any): RightResponse {
    return RightResponseFromJSONTyped(json, false);
}

export function RightResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): RightResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
    };
}

export function RightResponseToJSON(value?: RightResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
    };
}

