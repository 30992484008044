import React, { FC, useEffect, useState, useRef } from "react";
import styled from "styled-components";

interface TitreProps {
  text: string;
  setText: (text: string) => void;
}

/*
 * Styles.
 */

const InputStyle = styled.textarea`
  color: #363636;
  border: none;
  background: transparent;
  // background-color: red;
  font-family: Poppins;
  font-size: 32px;
  font-weight: 600;
  line-height: normal;
  width: 100%;
  resize: none; // Désactivez la possibilité de redimensionner le textarea
  overflow: hidden; // Empêche la barre de défilement de s'afficher

  &:focus,
  &:active {
    outline: none;
  }

  ::placeholder {
    color: rgba(0, 0, 0, 0.20);
  }
`;

/*
 * Component.
 */

export const Title: FC<TitreProps> = ({ text, setText }) => {
  const inputRef = useRef<HTMLTextAreaElement>(null);

  const adjustHeight = () => {
    if (inputRef.current) {
      inputRef.current.style.height = 'auto';
      inputRef.current.style.height = inputRef.current.scrollHeight + 'px';
    }
  };

  useEffect(() => {
    adjustHeight();
  }, [text]);

  return (
    <InputStyle
      ref={inputRef}
      id="inputDescription"
      placeholder="Mon Titre"
      value={text}
      onChange={(e) => {
        setText(e.target.value);
      }}
      maxLength={100}
    />
  );
};