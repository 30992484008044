import React, { FC, useEffect, useState } from "react";
import styled from "styled-components";
import { Sider } from "../components/sider/Sider";
import { Button, ButtonVariantEnum } from "../components/buttons/Button";

import { Menu } from "../components/article/menu";
import ProgressBar from "../components/article/progress";
import { useParams } from "react-router-dom";
import { getSubscriptionByID } from "../utils/subscription";
import { SubscriptionResponse } from "../api/models/SubscriptionResponse";
import { GetModule } from "../utils/module";
import parse from 'html-react-parser';
import { ButtonChat } from "../components/buttons/ButtonChat";

/*
 * Styles.
 */

const Container = styled.div`
  display: flex;
  height: 100vh;
  width: 100vw;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100%;
  padding: 30px;
  flex:1;
`;

const Header = styled.div`
  display: flex;
  width: 100%;
  height: 10%;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  padding: 0 300px;
`;

const Body = styled.div`
  display: flex;
  align-items: flex-start;
  flex-shrink: 0;
  align-self: stretch;
  width: 100%;
  height: 90%;
`;

const MenuContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 300px;
  height: 100%;
`;

const ArticleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex:1;
  padding-right: 300px;
  height: 100%;
  gap:30px;
`;


const PreviewContainer = styled.div`
    background: #FFF;
    padding:25px;
    overflow-y: auto;
    width: 100%;
    word-wrap: break-word;
    word-break: break-all;
    height: 100%;

    &::-webkit-scrollbar {
      width: 10px;
    }
  
    &::-webkit-scrollbar-track {
      border-radius: 5px;
      background: rgba(200,200,200,0.1);
      // box-shadow: 1px 1px 4px 0px rgba(0, 0, 0, 0.25) inset;
    }
  
    &::-webkit-scrollbar-thumb {
      background: #D1D6D9;
      border-radius: 5px;
    }
  
    &::-webkit-scrollbar-thumb:hover {
      background: #929597;
    }
  
    &::-webkit-scrollbar-button {
      display: none;
    }
`;

const ContentModule = styled.div`
  color: #000;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;

  .ProseMirror {
    &:focus {
      border: none; // Retirer la bordure lors du focus
      outline: none; // Retirer l'outline pour éviter les bordures de focus par défaut du navigateur
    }
  }

  ul,
  ol {
    padding: 0 1rem;
  }

  h1, h2, h3, h4, h5, h6 {
    line-height: 1.1;
    color: #363636;
    font-family: Poppins;
  }

  code {
    background-color: rgba(97, 97, 97, 0.1);
    color: #616161;
  }

  pre {
    background: #1E2126;
    color: #FFF;
    font-family: 'JetBrainsMono', monospace;
    padding: 0.75rem 1rem;
    border-radius: 0.5rem;

    code {
      color: inherit;
      padding: 0;
      background: none;
      font-size: 0.8rem;

      .hljs-comment,
      .hljs-quote {
        color: #616161;
      }

      .hljs-variable,
      .hljs-template-variable,
      .hljs-attribute,
      .hljs-tag,
      .hljs-name,
      .hljs-regexp,
      .hljs-link,
      .hljs-name,
      .hljs-selector-id,
      .hljs-selector-class {
        color: #f98181;
      }

      .hljs-number,
      .hljs-meta,
      .hljs-built_in,
      .hljs-builtin-name,
      .hljs-literal,
      .hljs-type,
      .hljs-params {
        color: #fbbc88;
      }

      .hljs-string,
      .hljs-symbol,
      .hljs-bullet {
        color: #b9f18d;
      }

      .hljs-title,
      .hljs-section {
        color: #faf594;
      }

      .hljs-keyword,
      .hljs-selector-tag {
        color: #70cff8;
      }

      .hljs-emphasis {
        font-style: italic;
      }

      .hljs-strong {
        font-weight: 700;
      }
    }
  }

  img {
    max-width: 100%;
    height: auto;
  }

  blockquote {
    padding-left: 1rem;
    border-left: 2px solid rgba(13, 13, 13, 0.1);
  }

  hr {
    border: none;
    border-top: 2px solid rgba(13, 13, 13, 0.1);
    margin: 2rem 0;
  }
  .tiptap {
    table {
      border-collapse: collapse;
      table-layout: fixed;
      width: 100%;
      margin: 0;
      overflow: hidden;
  
      td,
      th {
        min-width: 1em;
        border: 2px solid #ced4da;
        padding: 3px 5px;
        vertical-align: top;
        box-sizing: border-box;
        position: relative;
  
        > * {
          margin-bottom: 0;
        }
      }
  
      th {
        font-weight: bold;
        text-align: left;
        background-color: #f1f3f5;
      }
  
      .selectedCell:after {
        z-index: 2;
        position: absolute;
        content: "";
        left: 0; right: 0; top: 0; bottom: 0;
        background: rgba(200, 200, 255, 0.4);
        pointer-events: none;
      }
  
      .column-resize-handle {
        position: absolute;
        right: -2px;
        top: 0;
        bottom: -2px;
        width: 4px;
        background-color: #adf;
        pointer-events: none;
      }
    }
  }
  
  .tableWrapper {
    overflow-x: auto;
  }
  
  .resize-cursor {
    cursor: ew-resize;
    cursor: col-resize;
  }
`;




/*
 * Component.
 */


interface ModuleData {
  id: string | undefined;
  title: string;
}


export const ArticlesPage: FC = () => {
  const { subscriptionId } = useParams();
  const [menus, setMenus] = useState<ModuleData[]>([]);
  const [selectMenu, setSelectMenu] = useState<number>(0);
  const [content, setContent] = useState('');
  const [subscription, setSubscription] = useState<SubscriptionResponse | null>(null);

  const fetchModuleDetail = async () => {
    console.log('fetchModuleDetail', selectMenu, menus[selectMenu])
    if (selectMenu === null) {
      return;
    }
    const moduleId = menus[selectMenu]?.id

    if (moduleId) {
      try {
        const response = await GetModule(moduleId);
        if (response?.content) {
          setContent(response.content)
        }
      } catch (error) {
        console.error('Erreur lors de la récupération des détails du module :', error);
      }
    }
  };

  useEffect(() => {
    const fetchSubscription = async () => {
      if (subscriptionId) {
        const subscriptionResponse = await getSubscriptionByID(subscriptionId);
        console.log(subscriptionResponse);
        if (subscriptionResponse) {
          setSubscription(subscriptionResponse);
          const modules = Array.from(subscriptionResponse.formation?.chapters).map((chapter) => {
            return chapter.module
          })
          setMenus(modules);
        }
      }
    }
    fetchSubscription();
  }, [subscriptionId]);

  useEffect(() => {
    fetchModuleDetail()
  }, [menus]);

  useEffect(() => {
    if (selectMenu) {
      fetchModuleDetail();
    }
  }, [selectMenu]);


  return (
    <Container>
      <Sider />
      <ButtonChat />
      <Content>
        <Header>
          <ProgressBar steps={menus.length} currentStep={selectMenu} />
        </Header>
        <Body>
          <MenuContainer>
            <Menu menus={menus} selectMenu={selectMenu} setSelectMenu={setSelectMenu} />
          </MenuContainer>
          <ArticleContainer>
            <PreviewContainer>
              <ContentModule>
                {parse(content)}
              </ContentModule>
            </PreviewContainer>
            <Button variant={ButtonVariantEnum.PRIMARY} onClick={() => { setSelectMenu(selectMenu + 1) }}>
              Suivant
            </Button>
          </ArticleContainer>
        </Body>
      </Content>
    </Container>
  );
};
