/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ChapterResponse } from './ChapterResponse';
import {
    ChapterResponseFromJSON,
    ChapterResponseFromJSONTyped,
    ChapterResponseToJSON,
} from './ChapterResponse';
import type { TagResponse } from './TagResponse';
import {
    TagResponseFromJSON,
    TagResponseFromJSONTyped,
    TagResponseToJSON,
} from './TagResponse';
import type { UserLightResponse } from './UserLightResponse';
import {
    UserLightResponseFromJSON,
    UserLightResponseFromJSONTyped,
    UserLightResponseToJSON,
} from './UserLightResponse';

/**
 * 
 * @export
 * @interface FormationResponse
 */
export interface FormationResponse {
    /**
     * 
     * @type {string}
     * @memberof FormationResponse
     */
    id?: string;
    /**
     * 
     * @type {UserLightResponse}
     * @memberof FormationResponse
     */
    creator?: UserLightResponse;
    /**
     * 
     * @type {string}
     * @memberof FormationResponse
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof FormationResponse
     */
    summary?: string;
    /**
     * 
     * @type {string}
     * @memberof FormationResponse
     */
    assignment?: string;
    /**
     * 
     * @type {string}
     * @memberof FormationResponse
     */
    prerequisite?: string;
    /**
     * 
     * @type {string}
     * @memberof FormationResponse
     */
    target?: FormationResponseTargetEnum;
    /**
     * 
     * @type {Set<TagResponse>}
     * @memberof FormationResponse
     */
    tags?: Set<TagResponse>;
    /**
     * 
     * @type {Set<ChapterResponse>}
     * @memberof FormationResponse
     */
    chapters?: Set<ChapterResponse>;
    /**
     * 
     * @type {Date}
     * @memberof FormationResponse
     */
    modificationDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof FormationResponse
     */
    creationDate?: Date;
}


/**
 * @export
 */
export const FormationResponseTargetEnum = {
    Developer: 'DEVELOPER',
    Functional: 'FUNCTIONAL',
    Technical: 'TECHNICAL',
    Architecture: 'ARCHITECTURE'
} as const;
export type FormationResponseTargetEnum = typeof FormationResponseTargetEnum[keyof typeof FormationResponseTargetEnum];


/**
 * Check if a given object implements the FormationResponse interface.
 */
export function instanceOfFormationResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FormationResponseFromJSON(json: any): FormationResponse {
    return FormationResponseFromJSONTyped(json, false);
}

export function FormationResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): FormationResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'creator': !exists(json, 'creator') ? undefined : UserLightResponseFromJSON(json['creator']),
        'title': !exists(json, 'title') ? undefined : json['title'],
        'summary': !exists(json, 'summary') ? undefined : json['summary'],
        'assignment': !exists(json, 'assignment') ? undefined : json['assignment'],
        'prerequisite': !exists(json, 'prerequisite') ? undefined : json['prerequisite'],
        'target': !exists(json, 'target') ? undefined : json['target'],
        'tags': !exists(json, 'tags') ? undefined : (new Set((json['tags'] as Array<any>).map(TagResponseFromJSON))),
        'chapters': !exists(json, 'chapters') ? undefined : (new Set((json['chapters'] as Array<any>).map(ChapterResponseFromJSON))),
        'modificationDate': !exists(json, 'modificationDate') ? undefined : (new Date(json['modificationDate'])),
        'creationDate': !exists(json, 'creationDate') ? undefined : (new Date(json['creationDate'])),
    };
}

export function FormationResponseToJSON(value?: FormationResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'creator': UserLightResponseToJSON(value.creator),
        'title': value.title,
        'summary': value.summary,
        'assignment': value.assignment,
        'prerequisite': value.prerequisite,
        'target': value.target,
        'tags': value.tags === undefined ? undefined : (Array.from(value.tags as Set<any>).map(TagResponseToJSON)),
        'chapters': value.chapters === undefined ? undefined : (Array.from(value.chapters as Set<any>).map(ChapterResponseToJSON)),
        'modificationDate': value.modificationDate === undefined ? undefined : (value.modificationDate.toISOString()),
        'creationDate': value.creationDate === undefined ? undefined : (value.creationDate.toISOString()),
    };
}

