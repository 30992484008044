import React, { FC } from "react";
import { LoginPage } from "./pages/LoginPage";
import { createGlobalStyle } from "styled-components";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { MissionPage } from "./pages/MissionPage";
import { SettingsPage } from "./pages/SettingsPage";
import { LabPage } from "./pages/LabPage";
import { MonOnboardingPage } from "./pages/MonOnboardingPage";

// Import des composants pour l'authentification
import { AuthProvider } from './auth/AuthContext';
import { PrivateRoute } from './auth/PrivateRoute';
import { FormationsPageV2 } from "./pages/FormationsPagev2";
import { ArticlesPage } from "./pages/ArticlesPage";
import { FormationPage } from "./pages/FormationPage";
import { ActiveMenuProvider } from "./context";
import { EditModulePage } from "./pages/EditModulePage";
import { EditPipelinePage } from "./pages/EditPipelinePage";
import { AdminPage } from "./pages/AdminPage";
import './i18n.js';

/*
 * Styles.
 */

const GlobalStyles = createGlobalStyle`
  body .ant-cascader-dropdown .ant-cascader-menu {
    min-width: auto !important;
    height: auto !important;
  }

  html {
    font-family: 'Poppins';
    font-style: normal;
    line-height: normal;
    font-weight: 600;
  }

  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  body {
    height: 100%;
    width: 100%;
    font-size: 1rem;

    // border-top: 1px solid #D2D6DB;
  }

  html,
  #root {
    width: 100%;
    height: 100%;
  }
`;

export const App: FC = () => {
  return (
    <>
      <GlobalStyles />
      <BrowserRouter>
        <AuthProvider>
          <ActiveMenuProvider>
            <Routes>
              <Route path="/login" element={<LoginPage />} />
              {/* <Route path="/" element={<PrivateRoute component={HomePage} />} /> */}
              <Route path="/formations" element={<PrivateRoute component={FormationsPageV2} />} />
              <Route path="/mission" element={<PrivateRoute component={MissionPage} />} />
              <Route path="/settings" element={<PrivateRoute component={SettingsPage} />} />
              <Route path="/createpipeline/:formationId" element={<PrivateRoute component={EditPipelinePage} />} />
              <Route path="/lab" element={<PrivateRoute component={LabPage} />} />
              <Route path="/" element={<PrivateRoute component={MonOnboardingPage} />} />
              <Route path="/articles/:subscriptionId" element={<ArticlesPage />} />
              <Route path="/createmodule/:moduleId" element={<PrivateRoute component={EditModulePage} />} />
              <Route path="/formation/:formationId" element={<FormationPage />} />
              <Route path="/admin" element={<AdminPage />} />
            </Routes>
          </ActiveMenuProvider>
        </AuthProvider>
      </BrowserRouter>
    </>
  );
};