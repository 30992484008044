import React, { FC, useState } from "react";
import styled from "styled-components";
import { Sider } from "../components/sider/Sider";
import { Tabs } from "../components/commun/Tabs";
import { Profile } from "../components/settings/Profile";
import { Password } from "../components/settings/Password";
import { Payment } from "../components/settings/Payment";

/*
 * Styles.
 */

const Container = styled.div`
  display: flex;
  height: 100vh;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100%;
  padding : 30px;
  align-items: flex-start;
  gap: 25px;
`;

const Title = styled.h1`
  color: #363636;
  font-size: 32px;
  font-weight: 700;
`;

/*
 * Component.
 */


export const SettingsPage: FC = () => {
  const [selectTab, setSelectTab] = useState<string>("Profile");


  const renderTabContent = () => {
    switch(selectTab) {
      case 'Profile':
        return <Profile />;
      case 'Mot de passe':
        return <Password/>;
      case 'Payment':
        return <Payment />;
      default:
        return null;
    }
  }

  return (
    <Container>
      <Sider />
      <Content>
        <Title>Settings</Title>
        <Tabs selectTab={selectTab} setSelectTab={setSelectTab} tabList={["Profile", "Mot de passe", "Ecole", "Payment", "Notifications"]}/>
        {renderTabContent()}
      </Content>
    </Container>
  );
};
