import React, { FC, useEffect, useState } from "react";
import styled from "styled-components";
import { Sider } from "../components/sider/Sider";
import { SearchBar } from "../components/commun/SearchBar";
import { ButtonChat } from "../components/buttons/ButtonChat";
import { FormationCard } from "../components/module/FormationCard";
import { Tabs } from "../components/commun/Tabs";
import { GetAllModules } from "../utils/module";
import { getAllFormation, getMyFormation } from "../utils/formation";
import { FormationResponse } from "../api/models/FormationResponse";
import { useTranslation } from "react-i18next";

/*
 * Styles.
 */

const Container = styled.div`
  display: flex;
  height: 100vh;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100%;
  margin: 0 0 0 20px;
  gap : 10px;
`;

const ContentModules = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 10px;
  align-items: flex-start;
  gap: 29px;
  align-self: stretch;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 5px;
    background: rgba(200,200,200,0.1);
    // box-shadow: 1px 1px 4px 0px rgba(0, 0, 0, 0.25) inset;
  }

  &::-webkit-scrollbar-thumb {
    background: #D1D6D9;
    border-radius: 5px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #929597;
  }

  &::-webkit-scrollbar-button {
    display: none;
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap : 10px;
`;

const HeaderTitle = styled.h1`
  color: #363636;
  font-size: 24px;
  font-weight: 700;
  margin-top: 20px;
`;

const Title = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  color: #1B3042;

  font-size: 16px;
  font-weight: 700;
`;

const MissionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 15px;
  padding: 15px 0 ;
  padding-top: 10px;
  width: calc(100vw - 300px - 30px - 10px);
  overflow-x: auto;

  &::-webkit-scrollbar {
    height: 7px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 5px;
    background: rgba(200,200,200,0.1);
    // box-shadow: 1px 1px 4px 0px rgba(0, 0, 0, 0.25) inset;
  }

  &::-webkit-scrollbar-thumb {
    background: #D1D6D9;
    border-radius: 5px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #929597;
  }

  &::-webkit-scrollbar-button {
    display: none;
  }
`;

const Divider = styled.div`
    display: flex;
    // padding-top: 5px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;
    margin-right: 20px;
`;

const Line = styled.div`
    height: 1px;
    align-self: stretch;
    background: #D9D9D9;
`;

/*
 * Component.
 */

export const FormationsPageV2: FC = () => {
  const { t } = useTranslation();
  const [selectTab, setSelectTab] = useState<string>(t("Pipelines"));
  const [formations, setFormations] = useState<FormationResponse[]>([]);

  const fetchFormations = async () => {
    try {
      const res = await getAllFormation(); // Attendre le résultat de la Promise
      console.log(res)
      if (res) {
        setFormations(res);
      }
    } catch (error) {
      console.error('Erreur lors de la récupération des formations :', error);
    }
  };

  useEffect(() => {
    fetchFormations()
  }, []);

  return (
    <Container>
      <ButtonChat />
      <Sider />
      <Content>
        <Header>
          <HeaderTitle>{t("Formations")}</HeaderTitle>
          <Tabs selectTab={selectTab} setSelectTab={setSelectTab} tabList={[t("Pipelines"), t("Modules"), t("Ma liste")]} />
          <Divider><Line /></Divider>
        </Header>
        <ContentModules>
          <Row>
            <Title>{t("Nouveaux modules")}</Title>
            <MissionsContainer>
              {formations ? formations.map((e: any) => {
                return (<FormationCard
                  key={e.id}
                  id={e.id}
                  title={e.title}
                  description={e.summary}
                  tags={Array.from(e.tags)}
                  onModuleDelete={fetchFormations}
                />)
              }) : null}
            </MissionsContainer>
          </Row>
          <Row>
            <Title>{t("Nouveaux modules")}</Title>
            <MissionsContainer>
              {formations ? formations.map((e: any) => {
                return (<FormationCard
                  key={e.id}
                  id={e.id}
                  title={e.title}
                  description={e.summary}
                  tags={Array.from(e.tags)}
                  onModuleDelete={fetchFormations}
                />)
              }) : null}
            </MissionsContainer>
          </Row>
          <Row>
            <Title>{t("Nouveaux modules")}</Title>
            <MissionsContainer>
              {formations ? formations.map((e: any) => {
                return (<FormationCard
                  key={e.id}
                  id={e.id}
                  title={e.title}
                  description={e.summary}
                  tags={Array.from(e.tags)}
                  onModuleDelete={fetchFormations}
                />)
              }) : null}
            </MissionsContainer>
          </Row>
          <Row>
            <Title>{t("Nouveaux modules")}</Title>
            <MissionsContainer>
              {formations ? formations.map((e: any) => {
                return (<FormationCard
                  key={e.id}
                  id={e.id}
                  title={e.title}
                  description={e.summary}
                  tags={Array.from(e.tags)}
                  onModuleDelete={fetchFormations}
                />)
              }) : null}
            </MissionsContainer>
          </Row>
        </ContentModules>
      </Content>
    </Container>
  );
};
