/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { TagResponse } from './TagResponse';
import {
    TagResponseFromJSON,
    TagResponseFromJSONTyped,
    TagResponseToJSON,
} from './TagResponse';

/**
 * 
 * @export
 * @interface FormationLightResponse
 */
export interface FormationLightResponse {
    /**
     * 
     * @type {string}
     * @memberof FormationLightResponse
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof FormationLightResponse
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof FormationLightResponse
     */
    summary?: string;
    /**
     * 
     * @type {Set<TagResponse>}
     * @memberof FormationLightResponse
     */
    tags?: Set<TagResponse>;
    /**
     * 
     * @type {Date}
     * @memberof FormationLightResponse
     */
    creationDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof FormationLightResponse
     */
    modificationDate?: Date;
}

/**
 * Check if a given object implements the FormationLightResponse interface.
 */
export function instanceOfFormationLightResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FormationLightResponseFromJSON(json: any): FormationLightResponse {
    return FormationLightResponseFromJSONTyped(json, false);
}

export function FormationLightResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): FormationLightResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'title': !exists(json, 'title') ? undefined : json['title'],
        'summary': !exists(json, 'summary') ? undefined : json['summary'],
        'tags': !exists(json, 'tags') ? undefined : (new Set((json['tags'] as Array<any>).map(TagResponseFromJSON))),
        'creationDate': !exists(json, 'creationDate') ? undefined : (new Date(json['creationDate'])),
        'modificationDate': !exists(json, 'modificationDate') ? undefined : (new Date(json['modificationDate'])),
    };
}

export function FormationLightResponseToJSON(value?: FormationLightResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'title': value.title,
        'summary': value.summary,
        'tags': value.tags === undefined ? undefined : (Array.from(value.tags as Set<any>).map(TagResponseToJSON)),
        'creationDate': value.creationDate === undefined ? undefined : (value.creationDate.toISOString()),
        'modificationDate': value.modificationDate === undefined ? undefined : (value.modificationDate.toISOString()),
    };
}

