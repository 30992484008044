/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ContentRequest } from './ContentRequest';
import {
    ContentRequestFromJSON,
    ContentRequestFromJSONTyped,
    ContentRequestToJSON,
} from './ContentRequest';
import type { TagRequest } from './TagRequest';
import {
    TagRequestFromJSON,
    TagRequestFromJSONTyped,
    TagRequestToJSON,
} from './TagRequest';

/**
 * 
 * @export
 * @interface ModuleRequest
 */
export interface ModuleRequest {
    /**
     * 
     * @type {Array<ContentRequest>}
     * @memberof ModuleRequest
     */
    title?: Array<ContentRequest>;
    /**
     * 
     * @type {Array<ContentRequest>}
     * @memberof ModuleRequest
     */
    content?: Array<ContentRequest>;
    /**
     * 
     * @type {number}
     * @memberof ModuleRequest
     */
    estimatedTime?: number;
    /**
     * 
     * @type {Set<TagRequest>}
     * @memberof ModuleRequest
     */
    tags?: Set<TagRequest>;
}

/**
 * Check if a given object implements the ModuleRequest interface.
 */
export function instanceOfModuleRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ModuleRequestFromJSON(json: any): ModuleRequest {
    return ModuleRequestFromJSONTyped(json, false);
}

export function ModuleRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ModuleRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'title': !exists(json, 'title') ? undefined : ((json['title'] as Array<any>).map(ContentRequestFromJSON)),
        'content': !exists(json, 'content') ? undefined : ((json['content'] as Array<any>).map(ContentRequestFromJSON)),
        'estimatedTime': !exists(json, 'estimatedTime') ? undefined : json['estimatedTime'],
        'tags': !exists(json, 'tags') ? undefined : (new Set((json['tags'] as Array<any>).map(TagRequestFromJSON))),
    };
}

export function ModuleRequestToJSON(value?: ModuleRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'title': value.title === undefined ? undefined : ((value.title as Array<any>).map(ContentRequestToJSON)),
        'content': value.content === undefined ? undefined : ((value.content as Array<any>).map(ContentRequestToJSON)),
        'estimatedTime': value.estimatedTime,
        'tags': value.tags === undefined ? undefined : (Array.from(value.tags as Set<any>).map(TagRequestToJSON)),
    };
}

