/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ChatCreateResponse,
  ChatMessageRequest,
  ChatMessageResponse,
  ChatResponse,
  PageChatMessageResponse,
} from '../models/index';
import {
    ChatCreateResponseFromJSON,
    ChatCreateResponseToJSON,
    ChatMessageRequestFromJSON,
    ChatMessageRequestToJSON,
    ChatMessageResponseFromJSON,
    ChatMessageResponseToJSON,
    ChatResponseFromJSON,
    ChatResponseToJSON,
    PageChatMessageResponseFromJSON,
    PageChatMessageResponseToJSON,
} from '../models/index';

export interface DeleteChatRequest {
    chatId: string;
}

export interface GetChatRequest {
    page: number;
    size: number;
}

export interface GetChatMessagesRequest {
    page: number;
    size: number;
    chatId: string;
}

export interface PostChatWithMessagesRequest {
    chatMessageRequest: ChatMessageRequest;
}

export interface SendMessageRequest {
    chatId: string;
    chatMessageRequest: ChatMessageRequest;
}

/**
 * 
 */
export class ChatApi extends runtime.BaseAPI {

    /**
     */
    async deleteChatRaw(requestParameters: DeleteChatRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>> {
        if (requestParameters.chatId === null || requestParameters.chatId === undefined) {
            throw new runtime.RequiredError('chatId','Required parameter requestParameters.chatId was null or undefined when calling deleteChat.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer-key", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/chats/{chatId}`.replace(`{${"chatId"}}`, encodeURIComponent(String(requestParameters.chatId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<boolean>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async deleteChat(requestParameters: DeleteChatRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean> {
        const response = await this.deleteChatRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getChatRaw(requestParameters: GetChatRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ChatResponse>>> {
        if (requestParameters.page === null || requestParameters.page === undefined) {
            throw new runtime.RequiredError('page','Required parameter requestParameters.page was null or undefined when calling getChat.');
        }

        if (requestParameters.size === null || requestParameters.size === undefined) {
            throw new runtime.RequiredError('size','Required parameter requestParameters.size was null or undefined when calling getChat.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer-key", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/chats/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ChatResponseFromJSON));
    }

    /**
     */
    async getChat(requestParameters: GetChatRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ChatResponse>> {
        const response = await this.getChatRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getChatMessagesRaw(requestParameters: GetChatMessagesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PageChatMessageResponse>> {
        if (requestParameters.page === null || requestParameters.page === undefined) {
            throw new runtime.RequiredError('page','Required parameter requestParameters.page was null or undefined when calling getChatMessages.');
        }

        if (requestParameters.size === null || requestParameters.size === undefined) {
            throw new runtime.RequiredError('size','Required parameter requestParameters.size was null or undefined when calling getChatMessages.');
        }

        if (requestParameters.chatId === null || requestParameters.chatId === undefined) {
            throw new runtime.RequiredError('chatId','Required parameter requestParameters.chatId was null or undefined when calling getChatMessages.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer-key", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/chats/{chatId}`.replace(`{${"chatId"}}`, encodeURIComponent(String(requestParameters.chatId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PageChatMessageResponseFromJSON(jsonValue));
    }

    /**
     */
    async getChatMessages(requestParameters: GetChatMessagesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PageChatMessageResponse> {
        const response = await this.getChatMessagesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async postChatWithMessagesRaw(requestParameters: PostChatWithMessagesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ChatCreateResponse>> {
        if (requestParameters.chatMessageRequest === null || requestParameters.chatMessageRequest === undefined) {
            throw new runtime.RequiredError('chatMessageRequest','Required parameter requestParameters.chatMessageRequest was null or undefined when calling postChatWithMessages.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer-key", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/chats/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ChatMessageRequestToJSON(requestParameters.chatMessageRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ChatCreateResponseFromJSON(jsonValue));
    }

    /**
     */
    async postChatWithMessages(requestParameters: PostChatWithMessagesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ChatCreateResponse> {
        const response = await this.postChatWithMessagesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async sendMessageRaw(requestParameters: SendMessageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ChatMessageResponse>> {
        if (requestParameters.chatId === null || requestParameters.chatId === undefined) {
            throw new runtime.RequiredError('chatId','Required parameter requestParameters.chatId was null or undefined when calling sendMessage.');
        }

        if (requestParameters.chatMessageRequest === null || requestParameters.chatMessageRequest === undefined) {
            throw new runtime.RequiredError('chatMessageRequest','Required parameter requestParameters.chatMessageRequest was null or undefined when calling sendMessage.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer-key", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/chats/{chatId}`.replace(`{${"chatId"}}`, encodeURIComponent(String(requestParameters.chatId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ChatMessageRequestToJSON(requestParameters.chatMessageRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ChatMessageResponseFromJSON(jsonValue));
    }

    /**
     */
    async sendMessage(requestParameters: SendMessageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ChatMessageResponse> {
        const response = await this.sendMessageRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
