import React, { FC, useState } from "react";
import styled from "styled-components";
import url from "../../assets/icons/buttonchat.svg"
import { Chatbot } from "../chat/chatbot";
import MyDrawer from "../chat/drawer";

/*
 * Props.
 */

/*
 * Styles.
 */


const ButtonChatStyle = styled.img<{ $isaVisible: boolean; $isAnimating: boolean }>`
  position: absolute;
  top: 43px;
  width: 50px;
  height: 50px;
  cursor: pointer;
  right: 0px;
  transition: right 0.3s ease;

  &:hover {
    ${(p) => (p.$isaVisible === false && !p.$isAnimating ? "right: 30px;" : "")}
  }
  z-index: 2;
`;

const DrawerPreview = styled.div<{ $isaVisible: boolean }>`
  position: absolute;
  top: 0px;
  right: 0px;

  ${(p) => (p.$isaVisible ? "width: 30px;" : "width: 0px;")}
  height: 100vh;
  background: #E6F3F8;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  
  transition: width 0.3s ease;
  z-index: 3;
`;

const WindowBlur = styled.div<{ $isaVisible: boolean }>`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  z-index: 10;
  opacity: 0;
  transition: opacity 0.3s ease;
  ${(p) => (p.$isaVisible ? "" : "pointer-events: none;")}

  &.visible {
    opacity: 1;
  }
`;

export const ButtonChat: FC = () => {

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [hover, setHover] = useState(false); 
  const [isAnimating, setIsAnimating] = useState(false); // Nouvel état pour suivre l'animation

  const toggleDrawer = () => {
    if (!isAnimating) { // Vérifie si aucune animation n'est en cours
      setIsAnimating(true); // Début de l'animation
      setDrawerOpen(!drawerOpen);

      setTimeout(() => {
        setIsAnimating(false); // Fin de l'animation
      }, 300); // Assurez-vous que la durée correspond à la durée de votre animation CSS
    }
  };

  const windowblurOnClick = () => {
    if (drawerOpen == true) {
      setDrawerOpen(false);
    }
  }

  return (
    <>
      <ButtonChatStyle
        $isaVisible={drawerOpen}
        $isAnimating={isAnimating} 
        src={url}
        onClick={toggleDrawer}
        onMouseEnter={() => setHover(true)} // Mettez à jour l'état du survol lorsque la souris entre dans le bouton
        onMouseLeave={() => setHover(false)} // Mettez à jour l'état du survol lorsque la souris quitte le bouton
      />
      <DrawerPreview $isaVisible={hover}/>
      <WindowBlur $isaVisible={drawerOpen} className={drawerOpen ? "visible" : ""} onClick={windowblurOnClick} />
      <MyDrawer isOpen={drawerOpen} onClose={() => setDrawerOpen(false)} width="893px">
        <Chatbot />
      </MyDrawer>
    </>
  );
};
