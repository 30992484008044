import React, { FC, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import url from "../../assets/icons/chaticon.svg"
import logoIA from "../../assets/users/iconLogo.svg"
// import { InputMessage } from "./Input";
import { InputChat } from "./inputChat";
import { deleteConversation, getConversations, getMessages } from "../../utils/Chat";
import { CloseOutlined } from "@ant-design/icons";

/*
 * Props.
 */

/*
 * Styles.
 */


const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: 893px;
  height: 100%;
`;

const Sider = styled.div`
    display: flex;
    width: 233px;
    height: 100%;
    padding: 8px 0px 8px 8px;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    background: #E6F3F8;
    z-index: 1;
`;

const ButtonNew = styled.div`
    display: flex;
    width: 200px;
    align-items: center;
    justify-content: center;
    gap: 10px;
    display: flex;
    padding: 5px 10px;
    align-self: stretch;

    border-radius: 5px;
    border: 1px solid #028CB5;

    color: #028CB5;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    cursor:pointer;

    transition:  0.3s ease;
  
    &:hover {
      background-color: #028CB5;
      color: #fff;
    }
`;

const History = styled.div`
    display: flex;
    width: 225px;
    padding: 30px 10px 0px 0px;
    flex-direction: column;
    align-items: flex-start;
    overflow-y: auto;

    &::-webkit-scrollbar {
        height: 7px;
        width: 7px;
      }
    
      &::-webkit-scrollbar-track {
        border-radius: 5px;
        background: rgba(200,200,200,0.1);
      }
    
      &::-webkit-scrollbar-thumb {
        background: #AAA;
        border-radius: 5px;
      }
    
      &::-webkit-scrollbar-thumb:hover {
        background: #888;
      }
    
      &::-webkit-scrollbar-button {
        display: none;
      }
`;

const Row = styled.div<{ $isSelect: boolean }>`
    cursor:pointer;
    display: flex;
    padding: 10px;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    border-radius: 5px;
    color: #363636;
    justify-content: space-between;

    transition:  0.3s ease;
    ${(p) => (p.$isSelect ? "background-color: rgba(2, 140, 181, 0.31);" : "")}
  
    &:hover {
      ${(p) => (p.$isSelect === false ? "background-color: rgba(2, 140, 181, 0.10);" : "")}
      ${(p) => (p.$isSelect === false ? "color: #202123;" : "")}
    }
`;

const RowText = styled.div`
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
`;

const Icon = styled.img`
    width: 18px;
    height: 15px;
`;

const Chat = styled.div`
    display: flex;
    width: 672px;
    height: 100%;
    flex-direction: column;
    align-items: flex-start;
    flex-shrink: 0;
    background: #F3F4F5;
    justify-content: space-between;
    position: relative;
    z-index: 0;
`;

const Conversation = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    align-items: flex-start;
    flex-shrink: 0;
    overflow-y: auto;
`;


const MessageContainer = styled.div<{ $isHuman: boolean }>`
    display: flex;
    width: 100%;
    padding: 0 10px ;
    flex-direction: row;
    gap: 10px;
    justify-content: center;
    align-items:  center;
    background: ${(p) => (p.$isHuman ? "#FFF" : "#F3F4F5")};
    padding: 30px 0;
    border-top : 1px solid #E6E6E6;
    flex-shrink: 0;
`;

const Message = styled.div<{ $isHuman: boolean }>`
    display: flex;
    align-items: center;
    width: 500px;

    color: #727272;

    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

`;

const InputContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  padding-bottom : 10px;
  padding-top : 10px;
  height: 60px;
  background-color: rgba(255, 255, 255, 0.1); /* Ajoutez une couleur d'arrière-plan avec une opacité faible */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Ajoutez une ombre portée pour plus de profondeur */
  backdrop-filter: blur(5px); /* Ajoutez un flou d'arrière-plan */
  -webkit-backdrop-filter: blur(5px); /* Préfixe pour la compatibilité avec les navigateurs */
  border: 1px solid rgba(255, 255, 255, 0.2); /* Ajoutez une bordure solide avec une opacité faible */
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
`;

const LogoContainer = styled.div`
    width: 30px;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
`;;

const LogoUser = styled.div`
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: #01A89D;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
`;

const LogoIA = styled.img`
    width: 30px;
    height: 30px;
    border-radius: 50%;
`;

const Option = styled.div`
    width: 30px;
    height: 100%;
    
    background: #F3F4F5;
`;

const Ancre = styled.div<{ $isHuman: boolean }>`
    min-height: 60px;
    height: 100%; 
    width: 100%;
    background: ${(p) => (p.$isHuman ? "#FFF" : "#F3F4F5")};
    // flex-shrink: 0;
`;

const Delete = styled(CloseOutlined)`
    color: #363636;
    cursor: pointer;
    transition: 0.2s ease;
    &:hover {
        color: #FF0000;
    }
    font-size: 12px;
`;

interface Message {
    content: string;
    type: 'ia' | 'user';
}

interface ChatConversation {
    id: string | undefined;
    title: string;
}

export const Chatbot: FC = () => {
    const [messages, setMessages] = useState<Message[]>([])
    const [conversation, setConversation] = useState<ChatConversation[]>([])
    const [selectConversation, setSelectConversation] = useState<number>(0)
    const endOfMessagesRef = useRef<null | HTMLDivElement>(null);

    useEffect(() => {
        if (endOfMessagesRef.current) {
            endOfMessagesRef.current.scrollIntoView({ behavior: "smooth" });
        }
    }, [messages]);

    useEffect(() => {
        getConversations().then((response) => {
            console.log(response)
            const conv: ChatConversation[] = response.map((conv) => {
                if (conv.id && conv.title) {
                    return { id: conv.id, title: conv.title }
                }
                return { id: "", title: "" }; // Add a return statement here
            }).filter((conv) => conv.id !== "" && conv.title !== "")
            setConversation(conv)
        })
    }, [])

    useEffect(() => {
        console.log("selectConversation", selectConversation)
        const getMessagesFromConv = async () => {
            if (conversation && conversation.length > 0) {
                const selectedConversation = conversation[selectConversation];
                if (selectedConversation && selectedConversation.id) {
                    const response = await getMessages(selectedConversation.id);
                    if (response) {
                        const messages = response.content.reverse().reduce((acc: Message[], item: any) => {
                            acc.push({
                                content: item.request,
                                type: 'user'
                            });
                            acc.push({
                                content: item.response,
                                type: 'ia'
                            });
                            return acc;
                        }, []);
                        console.log(messages);
                        setMessages(messages);
                    }
                }
                else {
                    setMessages([]);
                }
            }
        }
        getMessagesFromConv();
    }, [selectConversation, conversation])

    const newConversation = async () => {
        const isNewConversationCreated = conversation.some(conv => conv.id === undefined);
        if (!isNewConversationCreated) {
            await setConversation([{ id: undefined, title: "Nouveau" }, ...conversation])
            await setSelectConversation(0)
        }
    }

    const updateConversation = (title: string, id: string) => {
        const updatedConversation = [...conversation];
        updatedConversation[selectConversation] = { id, title };
        setConversation(updatedConversation);
    };

    const deleteConversation_function = async (id: string, reset_index: boolean) => {
        await deleteConversation(id);
        const updatedConversation = conversation.filter(conv => conv.id !== id);
        setConversation(updatedConversation);
        if (reset_index) {
            setSelectConversation(0);
        }
    }

    return (
        <Container>
            <Sider>
                <ButtonNew onClick={newConversation}>+ Nouveau </ButtonNew>
                <History>
                    {conversation.map((conv, index) => {
                        return (
                            <Row key={index} $isSelect={selectConversation === index} onClick={() => setSelectConversation(index)}>
                                <Icon src={url} alt="" />
                                <RowText>{conv.title}</RowText>
                                {conv.id !== undefined && (
                                    <Delete onClick={(e) => {
                                        e.stopPropagation(); 
                                        deleteConversation_function(conv.id, selectConversation === index);
                                    }} />
                                )}
                            </Row>
                        )
                    }
                    )}
                </History>
            </Sider>
            <Chat>
                <Conversation>
                    {messages.map((message, index) => {
                        return (
                            <MessageContainer key={index}
                                $isHuman={message.type === "user"}>
                                <LogoContainer>
                                    {message.type === "user" ? <LogoUser>T</LogoUser> : <LogoIA src={logoIA} alt="logoUser" />}
                                </LogoContainer>
                                <Message $isHuman={message.type === "user"}>
                                    {message.content}
                                </Message>
                                {/* <Option /> */}
                            </MessageContainer>
                        )
                    })}
                    <Ancre $isHuman={messages[messages.length - 1]?.type === "user"} ref={endOfMessagesRef} />
                </Conversation>
                <InputContainer>
                    <InputChat convId={conversation[selectConversation]?.id} messages={messages} setMessages={setMessages} updateConversation={updateConversation} />
                </InputContainer>
            </Chat>

        </Container>
    );
};
