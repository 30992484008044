import React, { useState } from 'react';
import { Cascader } from 'antd';
import { useTranslation } from 'react-i18next';
import styled from "styled-components";
import { DownOutlined } from '@ant-design/icons';


interface Option {
    value: string | number | JSX.Element;
    label: string | JSX.Element;
    children?: Option[];
}

const Container = styled.div`
    padding: 5px 8px ;
    border-radius: 5px;
    border: 1px solid #d9d9d9;
    display: flex; 
    justify-content: center; 
    align-items: center; 
    width: fit-content; 
    height: fit-content;
    cursor: pointer; 
    gap: 5px;
    &:hover {
        border-color: #1890ff;
        color: #1890ff;
    }
`;

const DownOutlinedStyled = styled(DownOutlined)`
    font-size: 8px;
`;

const FlagIcon = styled.span<{ countryCode: string }>`
    width: 20px; // Largeur de l'icône
    height: 13px; // Hauteur de l'icône
    background-image: url(https://flagcdn.com/w20/${props => props.countryCode}.png);
    background-size: 100% 100%; // Étire l'image pour qu'elle remplisse l'espace
    display: inline-block;
    vertical-align: middle;
    border-radius: 2px; 
`;

const CascaderStyled = styled(Cascader)`
    &.ant-cascader-menu {
        min-width: 0 !important; // Supprime la min-width
    }
`;

export const Language: React.FC = () => {
    const { i18n } = useTranslation();
    const [languages, setLanguages] = useState("fr");

    const options: Option[] = [
        {
            value: "fr",
            label: <FlagIcon countryCode={"fr"} />,
        },
        {
            value: 'en',
            label: <FlagIcon countryCode={"gb"} />,
        },
        {
            value: 'es',
            label: <FlagIcon countryCode={"es"} />,
        },
        {
            value: 'de',
            label: <FlagIcon countryCode={"de"} />,
        },
    ];

    const changeLanguage = (language) => {
        console.log(i18n);
        i18n.changeLanguage(language);
    };

    const onChange = (value: (string | number)[]) => {
        console.log(value);
        changeLanguage(value[0] as string);
        if (value[0] === "en") {
            setLanguages("gb");
        }
        else {
            setLanguages(value[0] as string);
        }
    }


    return (
        <CascaderStyled options={options} onChange={onChange} style={{}}>
            <Container>
                <FlagIcon countryCode={languages} />
                <DownOutlinedStyled />
            </Container>
        </CascaderStyled>
    )
}
