import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n
    .use(initReactI18next)
    .init({
        resources: {
            en: {
                translation: {
                    // Sider
                    "Formations": "Formations",
                    "Onboarding": "Onboarding",
                    "Lab": "Lab",
                    "Infos utiles": "Useful information",
                    // Onboarding
                    "Mon Onboarding": "My Onboarding",
                    "En cours": "In progress",
                    "Terminé": "Completed",
                    "Mes formations": "My trainings",
                    "Construire des compétences pour l'avenir !": "Building Skills for the Future !",
                    // Formations
                    "Pipeline": "Pipeline",
                    "Modules": "Modules",
                    "Ma liste": "My list",
                    "Nouveaux modules": "New modules",
                    // Lab
                    "Mon Lab": "My Lab",
                    "Mes modules": "My modules",
                    "Mes formations": "My trainings",
                }
            },
            fr: {
                translation: {
                    // Sider
                    "Formations": "Formations",
                    "Onboarding": "Onboarding",
                    "Lab": "Lab",
                    "Infos utiles": "Infos utiles",
                    // Onboarding
                    "Mon Onboarding": "Mon Onboarding",
                    "En cours": "En cours",
                    "Terminé": "Terminé",
                    "Mes formations": "Mes formations",
                    "Construire des compétences pour l'avenir !": "Construire des compétences pour l'avenir !",
                    // Formations
                    "Pipeline": "Pipeline",
                    "Modules": "Modules",
                    "Ma liste": "Ma liste",
                    "Nouveaux modules": "Nouveaux modules",
                    // Lab
                    "Mon Lab": "Mon Lab",
                    "Mes modules": "Mes modules",
                    "Mes formations": "Mes formations",
                }
            },
            es: {
                translation: {
                    // Sider
                    "Formations": "Formaciones",
                    "Onboarding": "Incorporación",
                    "Lab": "Laboratorio",
                    "Infos utiles": "Información útil",
                    // Onboarding
                    "Mon Onboarding": "Mi Incorporación",
                    "En cours": "En curso",
                    "Terminé": "Completado",
                    "Mes formations": "Mis formaciones",
                    "Construire des compétences pour l'avenir !": "¡Construyendo habilidades para el futuro!",
                    // Formations
                    "Pipeline": "Secuencia",
                    "Modules": "Módulos",
                    "Ma liste": "Mi lista",
                    "Nouveaux modules": "Nuevos módulos",
                    // Lab
                    "Mon Lab": "Mi Laboratorio",
                    "Mes modules": "Mis módulos",
                    "Mes formations": "Mis formaciones",
                }
            },
            de: {
                translation: {
                    // Sider
                    "Formations": "Fortbildungen",
                    "Onboarding": "Einarbeitung",
                    "Lab": "Labor",
                    "Infos utiles": "Nützliche Infos",
                    // Onboarding
                    "Mon Onboarding": "Meine Einarbeitung",
                    "En cours": "In Bearbeitung",
                    "Terminé": "Abgeschlossen",
                    "Mes formations": "Meine Fortbildungen",
                    "Construire des compétences pour l'avenir !": "Kompetenzen für die Zukunft aufbauen!",
                    // Formations
                    "Pipeline": "Pipeline",
                    "Modules": "Module",
                    "Ma liste": "Meine Liste",
                    "Nouveaux modules": "Neue Module",
                    // Lab
                    "Mon Lab": "Mein Labor",
                    "Mes modules": "Meine Module",
                    "Mes formations": "Meine Fortbildungen",
                }
            }
        },
        lng: "fr", // langue initiale
        fallbackLng: "fr", // langue de secours
        interpolation: {
            escapeValue: false
        }
    });

export default i18n;
