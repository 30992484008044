import React, { useState } from "react"
import styled from 'styled-components';
import icon from "../../assets/icons/send2.svg"
import iconHover from "../../assets/icons/sendHover.svg"
import {
  LoadingOutlined,
} from '@ant-design/icons';
import { createConversation, sendMessage } from "../../utils/Chat";
import { ChatMessageResponse } from "../../api/models/ChatMessageResponse";
import { ChatCreateResponse } from "../../api";

const Container = styled.div`
    display: flex;
    height: 40px;
    padding-left: 25px;
    align-items: center;
    gap: 32px;
    border-radius: 25px;
    background: #FFF;
    box-shadow: 3px 3px 11px 0px rgba(0, 0, 0, 0.25);
    width: 500px;
`;

const Input = styled.input`
    display: flex;
    align-items: center;
    flex: 1 0 0;
    border: none;

    
    color: #727272;
    font-size: 12px;
    font-weight: 400;

    &:focus,
    &:active {
      outline: none;
    }

    ::placeholder {
        color: rgba(0, 0, 0, 0.20);
      }
`;

const Button = styled.button`
    display: flex;
    padding: 0px 20px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: none;
    cursor: pointer;
    background: transparent;

`;

const Icon = styled.img`
  fill: rgba(0, 0, 0, 0.20);

  &:hover {
    fill: #028CB5;
  }
`;


/*
 * Styles.
 */

interface Message {
  content: string;
  type: 'ia' | 'user';
}

interface InputChatProps {
  convId: string | undefined;
  messages: Message[];
  setMessages: (messages: Message[]) => void;
  updateConversation: (title: string, id: string) => void
}



export const InputChat: React.FC<InputChatProps> = ({ convId, messages, setMessages, updateConversation }) => {
  const [text, setText] = useState("");
  const [laoding, setLaoding] = useState<boolean>(false);
  const [hover, setHover] = useState(false);

  const send = async () => {
    if (laoding === false && text !== "") {
      try {
        setLaoding(true);
        if (convId) {
          const tmp: Message[] = [...messages, { content: text, type: "user" }]
          setMessages(tmp);
          const input = text
          setText("");
          const response: ChatMessageResponse | never[] = await sendMessage(convId, input);
          if (Array.isArray(response) && response.length === 0) {
          } else {
            const chatResponse: ChatMessageResponse = response as ChatMessageResponse;
            if (chatResponse.response) {
              await setMessages([...tmp, { content: chatResponse.response, type: "ia" }]);
            }
          }
        } else {
          const tmp: Message[] = [...messages, { content: text, type: "user" }]
          setMessages(tmp);
          const input = text
          setText("");
          const response: never[] | ChatCreateResponse = await createConversation(input);
          if (Array.isArray(response) && response.length === 0) {
          } else {
            const chatResponse: ChatCreateResponse = response as ChatCreateResponse;
            if (chatResponse) {
              if (chatResponse.title && chatResponse.id && chatResponse.lastMessage?.response)
              {
                updateConversation(chatResponse.title, chatResponse.id);
                setMessages([...tmp, { content: chatResponse.lastMessage?.response, type: "ia" }]);
              }
            }
          }

        }
        setLaoding(false);
      } catch (error) {
        console.error(error);
        setLaoding(false)
      }
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && text.trim()) {
      event.preventDefault();
      send();
    }
  };

  return (
    <Container>
      <Input
        type="text"
        placeholder="Envoyer un message"
        value={text}
        onChange={(e) => setText(e.target.value)}
        onKeyDown={handleKeyDown}
      />
      <Button onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
        {laoding ?
          <LoadingOutlined height="22px" style={{ "color": "rgba(0, 0, 0, 0.20)" }} />
          :
          <Icon src={hover ? iconHover : icon} alt="" onClick={send} />
        }
      </Button>
    </Container >
  )
}
