import React, { FC, useEffect, useState } from "react";
import styled from "styled-components";
import logo from "../../assets/icons/logo.svg";
import logoName from "../../assets/icons/logoName.svg";
import { Menu } from "./Menu";
import { Account } from "./Account";
import { UserCard } from "./UserCard";

/*
 * Styles.
 */

const Container = styled.div<{ $toggle: boolean }>`
  width: ${(p) => (p.$toggle ? "225" : "105")}px;
  height: 100%;
  display: flex;
  flex-direction : row;
  transition: width 0.3s ease-in-out;
  border-right: 1px solid #D2D6DB;
`;

const ContainerSider = styled.div`
  width: 100%;
  height: 100%;
  background: #E6F3F8;
  display: flex;
  flex-direction : column;
  justify-content: space-between;
  overflow:hidden;
`;

const ContainerTop = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: flex-start;
`;

const ContainerBottom = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: flex-end;
  padding: 0 10px;
`;

const Separateur = styled.div`
  border-radius: 99px;
  background: #ffffff;
  height: 1px;
  width: 100%;
`;

const SiderLine = styled.div`
  height: 100%;
  width: 5px;
  background: #E6F3F8;
  cursor:pointer;

  &:hover{
    background: #ddd;
  }
`;

const LogoContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
  gap: 5px;

  -webkit-user-select: none;  /* Chrome, Safari, Opera */
  -moz-user-select: none;     /* Firefox */
  -ms-user-select: none;      /* Internet Explorer/Edge */
  user-select: none;          /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
`;



/*
 * Component.
 */

export const Sider: FC = () => {

  const [toggle, setToggle] = useState<boolean>(true);

  return (
    <Container $toggle={toggle}>
      <ContainerSider>
        <ContainerTop>
          <LogoContainer>
            <img src={logo} alt="logo" width={40} />
            {toggle ?
              <img src={logoName} alt="logoName" width={100}/>
              : null
            }
          </LogoContainer>
          <Separateur />
          <Menu toggle={toggle}/>
          {/* <Separateur /> */}
          {/* <Account /> */}
        </ContainerTop>
        <ContainerBottom>
          <Separateur />
          <UserCard toggle={toggle}/>
        </ContainerBottom>
      </ContainerSider>
      <SiderLine onClick={() => setToggle(!toggle)}>
      </SiderLine>
    </Container>
  );
};
