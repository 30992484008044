/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PageableObject } from './PageableObject';
import {
    PageableObjectFromJSON,
    PageableObjectFromJSONTyped,
    PageableObjectToJSON,
} from './PageableObject';

/**
 * 
 * @export
 * @interface AdminUserSearchRequest
 */
export interface AdminUserSearchRequest {
    /**
     * 
     * @type {PageableObject}
     * @memberof AdminUserSearchRequest
     */
    pageable?: PageableObject;
    /**
     * 
     * @type {string}
     * @memberof AdminUserSearchRequest
     */
    query?: string;
}

/**
 * Check if a given object implements the AdminUserSearchRequest interface.
 */
export function instanceOfAdminUserSearchRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function AdminUserSearchRequestFromJSON(json: any): AdminUserSearchRequest {
    return AdminUserSearchRequestFromJSONTyped(json, false);
}

export function AdminUserSearchRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): AdminUserSearchRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'pageable': !exists(json, 'pageable') ? undefined : PageableObjectFromJSON(json['pageable']),
        'query': !exists(json, 'query') ? undefined : json['query'],
    };
}

export function AdminUserSearchRequestToJSON(value?: AdminUserSearchRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'pageable': PageableObjectToJSON(value.pageable),
        'query': value.query,
    };
}

