/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { RoleResponse } from './RoleResponse';
import {
    RoleResponseFromJSON,
    RoleResponseFromJSONTyped,
    RoleResponseToJSON,
} from './RoleResponse';

/**
 * 
 * @export
 * @interface AdminUserResponse
 */
export interface AdminUserResponse {
    /**
     * 
     * @type {string}
     * @memberof AdminUserResponse
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminUserResponse
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminUserResponse
     */
    firstname?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminUserResponse
     */
    lastname?: string;
    /**
     * 
     * @type {Array<RoleResponse>}
     * @memberof AdminUserResponse
     */
    roles?: Array<RoleResponse>;
    /**
     * 
     * @type {boolean}
     * @memberof AdminUserResponse
     */
    activated?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AdminUserResponse
     */
    resetPasswordRequired?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AdminUserResponse
     */
    deleted?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AdminUserResponse
     */
    _protected?: boolean;
}

/**
 * Check if a given object implements the AdminUserResponse interface.
 */
export function instanceOfAdminUserResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function AdminUserResponseFromJSON(json: any): AdminUserResponse {
    return AdminUserResponseFromJSONTyped(json, false);
}

export function AdminUserResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): AdminUserResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'firstname': !exists(json, 'firstname') ? undefined : json['firstname'],
        'lastname': !exists(json, 'lastname') ? undefined : json['lastname'],
        'roles': !exists(json, 'roles') ? undefined : ((json['roles'] as Array<any>).map(RoleResponseFromJSON)),
        'activated': !exists(json, 'activated') ? undefined : json['activated'],
        'resetPasswordRequired': !exists(json, 'resetPasswordRequired') ? undefined : json['resetPasswordRequired'],
        'deleted': !exists(json, 'deleted') ? undefined : json['deleted'],
        '_protected': !exists(json, 'protected') ? undefined : json['protected'],
    };
}

export function AdminUserResponseToJSON(value?: AdminUserResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'email': value.email,
        'firstname': value.firstname,
        'lastname': value.lastname,
        'roles': value.roles === undefined ? undefined : ((value.roles as Array<any>).map(RoleResponseToJSON)),
        'activated': value.activated,
        'resetPasswordRequired': value.resetPasswordRequired,
        'deleted': value.deleted,
        'protected': value._protected,
    };
}

