/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ModuleChapterResponse } from './ModuleChapterResponse';
import {
    ModuleChapterResponseFromJSON,
    ModuleChapterResponseFromJSONTyped,
    ModuleChapterResponseToJSON,
} from './ModuleChapterResponse';

/**
 * 
 * @export
 * @interface ChapterResponse
 */
export interface ChapterResponse {
    /**
     * 
     * @type {string}
     * @memberof ChapterResponse
     */
    id?: string;
    /**
     * 
     * @type {ModuleChapterResponse}
     * @memberof ChapterResponse
     */
    module?: ModuleChapterResponse;
    /**
     * 
     * @type {number}
     * @memberof ChapterResponse
     */
    index?: number;
}

/**
 * Check if a given object implements the ChapterResponse interface.
 */
export function instanceOfChapterResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ChapterResponseFromJSON(json: any): ChapterResponse {
    return ChapterResponseFromJSONTyped(json, false);
}

export function ChapterResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ChapterResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'module': !exists(json, 'module') ? undefined : ModuleChapterResponseFromJSON(json['module']),
        'index': !exists(json, 'index') ? undefined : json['index'],
    };
}

export function ChapterResponseToJSON(value?: ChapterResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'module': ModuleChapterResponseToJSON(value.module),
        'index': value.index,
    };
}

