import React, { FC, useContext, useEffect } from "react";
import styled from "styled-components";
import image from "../../assets/users/user1.png";
// import { useNavigate } from "react-router-dom";
import type { MenuProps } from 'antd';
import { Avatar, Dropdown } from 'antd';
import setting from "../../assets/icons/setting.svg";
import logout from "../../assets/icons/logout.svg";
import { AuthContext } from "../../auth/AuthContext";

import work from "../../assets/icons/work.svg";
import { useNavigate } from "react-router-dom";
import { stringToPastelHex } from "../../utils/token";
/*
 * Styles.
 */

const Container = styled.div`
  display: flex;
  justify-content: center;
  padding: 20px 10px;
`;

const Card = styled.div`
  width: 100%;
  // width: auto;
  flex-shrink: 0;
  border-radius: 116px;
  background: #fff;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  padding: 10px 10px;
`;

const UserImage = styled.img``;

const Information = styled.div`
  display: flex;
  flex-direction: column;
  // padding: 0px 5px;
`;

const Name = styled.span`
  color: #363636;
  font-size: 0.8rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

const Email = styled.span`
  color: #d1d6d9;
  font-size: 0.4rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;


const AccountLine = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  cursor: pointer;
  border-radius: 10px;
  transition: background-color 0.3s;
`;

const AccountIcon = styled.img``;

const AccountLabel = styled.span``;

/*
 * Props.
 */



interface UserCardProps {
  toggle: boolean;
}

/*
 * Component.
 */

export const UserCard: FC<UserCardProps> = ({ toggle }) => {

  const navigate = useNavigate();
  const { authUser, isLoading } = useContext(AuthContext);

  useEffect(() => {
    console.log(authUser);
    console.log(isLoading);
  }, []);

  const handleButtonClick = (url: string) => {
    navigate(url);
  };

  const items: MenuProps['items'] = [
    {
      label: (
        <AccountLine onClick={() => { handleButtonClick("/admin") }}>
          <AccountIcon src={work} alt="work" />
          <AccountLabel>Admin</AccountLabel>
        </AccountLine>
      ),
      key: '0',
    },
    {
      label: (
        <AccountLine onClick={() => { handleButtonClick("/settings") }}>
          <AccountIcon src={setting} alt="setting" />
          <AccountLabel>Settings</AccountLabel>
        </AccountLine>
      ),
      key: '1',
    },
    {
      type: 'divider',
    },
    {
      label: 'Log out',
      key: '3',
      danger: true,
      // disabled: true,
    },
  ];

  return (
    <Container>
      {authUser ?
        <Dropdown menu={{ items }} trigger={['click']}>
          <Card onClick={(e) => e.preventDefault()} >
            {/* <UserImage src={image} alt="User image" /> */}
            <Avatar style={{ backgroundColor: stringToPastelHex(authUser.email) }}>{authUser.firstname.charAt(0).toUpperCase()}{authUser.lastname.charAt(0).toUpperCase()}</Avatar>
            {toggle ?
              <Information>
                <Name>{authUser.firstname} {authUser.lastname}</Name>
                <Email>{authUser.email}</Email>
              </Information>
              : null}
          </Card>
        </Dropdown>
        : null}
    </Container>
  );
};
