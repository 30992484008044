import React, { FC, useState } from "react";
import styled from "styled-components";
import heartTrue from "../../assets/icons/heartTrue.svg";
import { useNavigate } from "react-router-dom";
import { TagResponse } from "../../api/models/TagResponse";

import { CloseOutlined, FieldTimeOutlined } from '@ant-design/icons';
import { message, Popconfirm } from 'antd';
import { formatRelativeDate } from "../../utils/token";
import { deleteModule } from "../../utils/module";

/*
 * Styles.
 */

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 250px;
  height: 120px;
  min-width: 250px;
  border-radius: 15px;
  background: #fff;
  border-radius: 15px;
  border: 1px solid #D2D6DB;
  padding: 20px 20px;
  z-index: 1000;
  flex-shrink: 0; /* Empêche la réduction de la carte */
  cursor:pointer;

  transition: background  0.1s ease;

  &:hover{
      // background: #028CB533;
      background: #eeeeee;
  }
  
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content:space-between;
  align-items:flex-start;
  
`;

const HeaderContainerRow = styled.div`
  display: flex;
`;

const HeaderContainerInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const Title = styled.h2`
  color: #028CB5;
  font-size: 16px;
  font-weight: 700;
  margin: 0;
  padding: 0;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1; 
  -webkit-box-orient: vertical;
`;

const Tags = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
  margin-top: 5px;
`;

const Tag = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  background: #1b3042;
  color: #fff;
  font-size: 8px;
  font-weight: 400;
  padding: 5px 10px;
`;



const Heart = styled.img`
  width: 27px;
  height: 27px;
  cursor: pointer;
`;

const CloseOutlinedStyle = styled(CloseOutlined)`
  cursor: pointer;
  fontSize: 16px;
  color: #727272;
  border-radius: 50%;
  padding:2px;

  transition: color 0.3s ease;

  &:hover{
    color: #B3482F;
  }
`;

const ModificationDateText = styled.span`
color: #707070;
font-size: 10px;
margin-top: 5px;
`;

/*
 * Props.
 */

interface CardProps {
  title: string;
  id: string;
  tags: TagResponse[];
  modificationDate: Date;
  onModuleDelete: () => void;
}

/*
 * Component.
 */



export const ModuleCard: FC<CardProps> = ({
  title,
  tags,
  id,
  modificationDate,
  onModuleDelete,
}) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  const handleButtonClick = (url: string) => {
    navigate(url);
  };

  const confirm = async (e: React.MouseEvent<HTMLElement>|undefined) => {
    if (e)
    {
      e.stopPropagation();
    }
    try {
      await deleteModule(id);
      message.success('Module deleted');
      onModuleDelete(); // Appeler pour mettre à jour la liste des modules
    } catch (error) {
      message.error('Error deleting module');
    }
    setOpen(false);
  };
  
  const cancel = (e: React.MouseEvent<HTMLElement>|undefined) => {
    if (e)
    {
      e.stopPropagation();
    }
    setOpen(false);
  };

  const onClickDelete = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation(); // Empêche la propagation de l'événement
    setOpen(!open);
  };


  return (
    <Container onClick={() => { handleButtonClick("/createmodule/" + id) }}>
      <HeaderContainer>
        <HeaderContainerRow>
          <HeaderContainerInfo>
            <Title>{title === "" ? "Untitled" : title}</Title>
            <ModificationDateText> <FieldTimeOutlined style={{ fontSize: '12px'}}/> {formatRelativeDate(modificationDate)}</ModificationDateText>
            <Tags>
              {tags.map((e) => (
                <Tag key={e.id}>{e.tag}</Tag>
              ))}
            </Tags>
          </HeaderContainerInfo>
        </HeaderContainerRow>
        {/* <Heart src={heartTrue} alt="like" /> */}
        <Popconfirm
          title="Delete the module"
          description="Are you sure to delete this module?"
          open={open}
          onConfirm={confirm}
          onCancel={cancel}
          okText="Yes"
          cancelText="No"
        >
          <CloseOutlinedStyle onClick={onClickDelete} />
        </Popconfirm>
      </HeaderContainer>
    </Container>
  );
};
