import React, { useEffect, useState } from 'react';
import Tag from './Tags';
import { styled } from 'styled-components';
import { TagRequest } from '../../api/models/TagRequest';
import { Input, Modal } from 'antd';

const TagsContainer = styled.div`
  width: 760px;
  display: flex;
  flex-direction: row;
  gap: 10px;
`;

const ContainerModule = styled.div`
  display: flex;
  width: 50px;
  height: 30px;
  border-radius: 5px;
  background: #fff;
  border: 1px solid #D2D6DB;
  z-index: 1000;
  flex-shrink: 0; /* Empêche la réduction de la carte */
  cursor:pointer;
  justify-content: center;
  align-items: center;

  color: #D2D6DB;
  font-size: 10px;

  font-weight: 200;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
  z-index: 0;


  transition: background  0.1s ease;

  &:hover{
      background: #eeeeee;
  }
  
`;

interface TagProps {
    tags: TagRequest[];
    setTags: React.Dispatch<React.SetStateAction<TagRequest[]>>;
}

const TagsManager: React.FC<TagProps> = ({ tags, setTags }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [inputValue, setInputValue] = useState("");

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        addTag(inputValue);
        setIsModalOpen(false);
        setInputValue("");
    };

    const handleCancel = () => {
        setIsModalOpen(false);
        setInputValue("");
    };

    const removeTag = (label) => {
        console.log(label);
        setTags(tags.filter(tag => tag.tag !== label));
    };

    const addTag = (label: string) => {
        setTags([...tags, { tag: label, color: "GREEN" }]); // Ajoute le tag
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setInputValue(e.target.value);
    };

    
    return (
        <TagsContainer>
        <Modal title="Basic Modal" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
            <Input 
                placeholder="Basic usage" 
                onChange={handleInputChange}
                onPressEnter={handleOk}
                value={inputValue}
            />
        </Modal>
            <ContainerModule onClick={showModal}>+</ContainerModule>
            {tags.map((tag, index) => (
                <Tag key={index}  label={tag.tag} handleClick={() => removeTag(tag.tag)} />
            ))}
        </TagsContainer>
    );
};

export default TagsManager;
