/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RoleResponse
 */
export interface RoleResponse {
    /**
     * 
     * @type {string}
     * @memberof RoleResponse
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof RoleResponse
     */
    name?: string;
    /**
     * 
     * @type {Date}
     * @memberof RoleResponse
     */
    creationDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof RoleResponse
     */
    modificationDate?: Date;
    /**
     * 
     * @type {Array<string>}
     * @memberof RoleResponse
     */
    rights?: Array<RoleResponseRightsEnum>;
}


/**
 * @export
 */
export const RoleResponseRightsEnum = {
    MenuFormationRead: 'MENU_FORMATION_READ',
    MenuAdminRead: 'MENU_ADMIN_READ',
    MenuMyonboardingRead: 'MENU_MYONBOARDING_READ',
    MenuLabRead: 'MENU_LAB_READ',
    MenuInfosRead: 'MENU_INFOS_READ',
    FormationRead: 'FORMATION_READ',
    FormationCreate: 'FORMATION_CREATE',
    FormationUpdate: 'FORMATION_UPDATE',
    FormationDelete: 'FORMATION_DELETE',
    ModuleRead: 'MODULE_READ',
    ModuleCreate: 'MODULE_CREATE',
    ModuleUpdate: 'MODULE_UPDATE',
    ModuleDelete: 'MODULE_DELETE',
    TagRead: 'TAG_READ',
    TagCreate: 'TAG_CREATE',
    TagUpdate: 'TAG_UPDATE',
    TagDelete: 'TAG_DELETE',
    UserRead: 'USER_READ',
    UserCreate: 'USER_CREATE',
    UserUpdate: 'USER_UPDATE',
    UserDelete: 'USER_DELETE',
    UserroleRead: 'USERROLE_READ',
    UserroleCreate: 'USERROLE_CREATE',
    UserroleUpdate: 'USERROLE_UPDATE',
    UserroleDelete: 'USERROLE_DELETE',
    TeamRead: 'TEAM_READ',
    TeamWrite: 'TEAM_WRITE',
    AdminUserRead: 'ADMIN_USER_READ',
    AdminUserWrite: 'ADMIN_USER_WRITE'
} as const;
export type RoleResponseRightsEnum = typeof RoleResponseRightsEnum[keyof typeof RoleResponseRightsEnum];


/**
 * Check if a given object implements the RoleResponse interface.
 */
export function instanceOfRoleResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function RoleResponseFromJSON(json: any): RoleResponse {
    return RoleResponseFromJSONTyped(json, false);
}

export function RoleResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): RoleResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'creationDate': !exists(json, 'creationDate') ? undefined : (new Date(json['creationDate'])),
        'modificationDate': !exists(json, 'modificationDate') ? undefined : (new Date(json['modificationDate'])),
        'rights': !exists(json, 'rights') ? undefined : json['rights'],
    };
}

export function RoleResponseToJSON(value?: RoleResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'creationDate': value.creationDate === undefined ? undefined : (value.creationDate.toISOString()),
        'modificationDate': value.modificationDate === undefined ? undefined : (value.modificationDate.toISOString()),
        'rights': value.rights,
    };
}

