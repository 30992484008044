/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TagRequest
 */
export interface TagRequest {
    /**
     * 
     * @type {string}
     * @memberof TagRequest
     */
    tag?: string;
    /**
     * 
     * @type {string}
     * @memberof TagRequest
     */
    color?: TagRequestColorEnum;
}


/**
 * @export
 */
export const TagRequestColorEnum = {
    Red: 'RED',
    Blue: 'BLUE',
    Yellow: 'YELLOW',
    Purple: 'PURPLE',
    Green: 'GREEN'
} as const;
export type TagRequestColorEnum = typeof TagRequestColorEnum[keyof typeof TagRequestColorEnum];


/**
 * Check if a given object implements the TagRequest interface.
 */
export function instanceOfTagRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function TagRequestFromJSON(json: any): TagRequest {
    return TagRequestFromJSONTyped(json, false);
}

export function TagRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): TagRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'tag': !exists(json, 'tag') ? undefined : json['tag'],
        'color': !exists(json, 'color') ? undefined : json['color'],
    };
}

export function TagRequestToJSON(value?: TagRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'tag': value.tag,
        'color': value.color,
    };
}

