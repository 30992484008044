import React, { FC, useState } from "react";
import styled from "styled-components";
import { Button, ButtonVariantEnum } from "../buttons/Button";
import { ArrowRightIcon } from "@radix-ui/react-icons";

/*
 * Props.
 */

/*
 * Styles.
 */

const Container = styled.div`
    display: flex;
    align-items: center;
    gap: 25px;
    flex-direction: column;
    width: 100%;
`;

const Header = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
    align-self: stretch;
`;

const Title = styled.h2`
    color: #1B3042;
    font-size: 16px;
    font-weight: 700;
`;

const Description = styled.span`
    color: #1B3042;
    font-size: 12px;
    font-weight: 400;
`;

const Cell = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 15px;
  align-self: stretch;
`;

const TitleCell = styled.span`
    color: #727272;
    font-size: 16px;
    font-weight: 400;
    width: 40%;
`;

const Divider = styled.div`
    display: flex;
    padding-top: 15px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;
`;

const Line = styled.div`
    height: 1px;
    align-self: stretch;
    background: #D9D9D9;
`;

const Columns = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
`;

const InputContainer = styled.div`
    display: flex;
    width: 500px;
    height: 77px;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
`;

const InputTitle = styled.div`
    color: #363636;
    font-size: 12.8px;
    font-weight: 400;
    // margin-left: 20px;
`;

const Input = styled.input`
    display: flex;
    padding: 16px 21px 16px 20px;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    border-radius: 15px;
    border: none;
    background: #F7F7F7;

    &:focus,
    &:active {
      outline: none;
    }
`;

const ArrowIcon = styled.div`
  display: flex;
  padding: 3px;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.1);
`;

export const Profile: FC = () => {
  const [prenom, setPrenom] = useState("");
  const [nom, setNom] = useState("");
  const [birthday, setBirthday] = useState("");
  const [nationalite, setNationalite] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [adresse, setAdresse] = useState("");
  const [ville, setVille] = useState("");
  const [pays, setPays] = useState("");
  const [zip, setZip] = useState("");

  return (
    <Container>
        <Header>
            <Title>Mon profile</Title>
            <Description>Mettez à jour vos informations personnel.</Description>
            <Divider><Line/></Divider>
        </Header>
        <Cell>
            <TitleCell>Identité</TitleCell>
            <Columns>
              <InputContainer>
                <InputTitle>Prénom</InputTitle>
                <Input
                    type="text"
                    placeholder=""
                    value={prenom}
                    onChange={(e) => setPrenom(e.target.value)}
                    />
              </InputContainer>
              <InputContainer>
                <InputTitle>Nom</InputTitle>
                <Input
                    type="text"
                    placeholder=""
                    value={nom}
                    onChange={(e) => setNom(e.target.value)}
                    />
              </InputContainer>
            </Columns>
            <Columns>
              <InputContainer style={{width:"200px"}}>
                <InputTitle>Date de Naissance</InputTitle>
                <Input
                    type="text"
                    placeholder=""
                    value={birthday}
                    onChange={(e) => setBirthday(e.target.value)}
                    />
              </InputContainer>
              <InputContainer style={{width:"200px"}}>
                <InputTitle>Nationalité</InputTitle>
                <Input
                    type="text"
                    placeholder=""
                    value={nationalite}
                    onChange={(e) => setNationalite(e.target.value)}
                    />
              </InputContainer>
            </Columns>
        </Cell>
        <Divider><Line/></Divider>
        <Cell>
            <TitleCell>Contact</TitleCell>
            <Columns>
              <InputContainer>
                <InputTitle>Email</InputTitle>
                <Input
                    type="text"
                    placeholder=""
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    />
              </InputContainer>
            </Columns>
            <Columns>
              <InputContainer style={{width:"200px"}}>
                <InputTitle>Télephone</InputTitle>
                <Input
                    type="text"
                    placeholder=""
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                    />
              </InputContainer>
            </Columns>
        </Cell>
        <Divider><Line/></Divider>
        <Cell>
            <TitleCell>Localisation</TitleCell>
            <Columns>
              <InputContainer>
                <InputTitle>Adresse</InputTitle>
                <Input
                    type="text"
                    placeholder=""
                    value={adresse}
                    onChange={(e) => setAdresse(e.target.value)}
                    />
              </InputContainer>
              <InputContainer>
                <InputTitle>Ville</InputTitle>
                <Input
                    type="text"
                    placeholder=""
                    value={ville}
                    onChange={(e) => setVille(e.target.value)}
                    />
              </InputContainer>
            </Columns>
            <Columns>
              <InputContainer style={{width:"200px"}}>
                <InputTitle>Pays</InputTitle>
                <Input
                    type="text"
                    placeholder=""
                    value={pays}
                    onChange={(e) => setPays(e.target.value)}
                    />
              </InputContainer>
              <InputContainer style={{width:"200px"}}>
                <InputTitle>Zip</InputTitle>
                <Input
                    type="text"
                    placeholder=""
                    value={zip}
                    onChange={(e) => setZip(e.target.value)}
                    />
              </InputContainer>
            </Columns>
        </Cell>
        <Cell style={{justifyContent: "flex-end", marginRight:"10%"}}>
          <Button variant={ButtonVariantEnum.PRIMARY} style={{width:"115px", display:"flex", justifyContent:"center", alignItems:"center", gap:"10px"}}>
            Valider
            <ArrowIcon>
                <ArrowRightIcon/>
            </ArrowIcon>
          </Button>
        </Cell>
    </Container>
  );
};
