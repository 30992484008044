import React, { FC } from "react";
import styled from "styled-components";
import { CloseOutlined, FieldTimeOutlined, FileTextOutlined } from "@ant-design/icons";
import { ModuleResponse } from "../../api";
import { formatRelativeDate } from "../../utils/token";

/*
 * Styles.
 */


const Container = styled.div<{ $selected: boolean }>`
  display: flex;
  width: 250px;
  height: 150px;
  flex-direction: column;
  flex-shrink: 0;
  padding: 20px 20px;

  border-radius: 15px;
  border: 1px solid #D1D6D9;

  background: #FFF;

  cursor: pointer;
  color: #727272;

  ${(p) =>
        !p.$selected &&
        `
    &:hover {
      border: 1px solid #727272;
    }
  `}

  ${(p) =>
        p.$selected &&
        `
    border: 1px solid #028CB5;
    color: #028CB5;
  `}
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content:space-between;
  align-items:flex-start;
  
`;

const HeaderContainerRow = styled.div`
  display: flex;
`;

const HeaderContainerInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const Title = styled.h2`
  color: #028CB5;
  font-size: 16px;
  font-weight: 700;
  margin: 0;
  padding: 0;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1; 
  -webkit-box-orient: vertical;
`;

const Tags = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
  margin-top: 5px;
`;

const Tag = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  background: #1b3042;
  color: #fff;
  font-size: 8px;
  font-weight: 400;
  padding: 5px 10px;
`;

const CloseOutlinedStyle = styled(CloseOutlined)`
  cursor: pointer;
  fontSize: 16px;
  color: #727272;
  border-radius: 50%;
  padding:2px;

  transition: color 0.3s ease;

  &:hover{
    color: #B3482F;
  }
`;

const ModificationDateText = styled.span`
color: #707070;
font-size: 10px;
margin-top: 5px;
`;

/*
 * Component.
 */


interface ModuleCardProps {
    selectCard: number;
    setSelectCard: (index: number) => void;
    module: ModuleResponse;
    index: number;
    onDelete?: any
}

export const ModuleCard: FC<ModuleCardProps> = ({ selectCard, setSelectCard, module, index, onDelete }) => {
    return (
        <Container $selected={selectCard === index + 1} onClick={() => setSelectCard(index + 1)}>
            <HeaderContainer>
                <HeaderContainerRow>
                    <HeaderContainerInfo>
                        <Title>{module.title === "" ? "Untitled" : module.title}</Title>
                        {module.modificationDate ?
                            <ModificationDateText> <FieldTimeOutlined style={{ fontSize: '12px' }} /> {formatRelativeDate(module.modificationDate)}</ModificationDateText>
                            : null}
                        {module.tags ?
                        <Tags>
                            {Array.from(module.tags).map((e) => (
                                <Tag key={e.id}>{e.tag}</Tag>
                            ))}
                        </Tags>
                        :null}
                    </HeaderContainerInfo>
                </HeaderContainerRow>
                <CloseOutlinedStyle onClick={onDelete}/>
            </HeaderContainer>
        </Container>
    );
};
