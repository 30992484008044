/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  SubscriptionRequest,
  SubscriptionResponse,
  SubscriptionUpdateRequest,
} from '../models/index';
import {
    SubscriptionRequestFromJSON,
    SubscriptionRequestToJSON,
    SubscriptionResponseFromJSON,
    SubscriptionResponseToJSON,
    SubscriptionUpdateRequestFromJSON,
    SubscriptionUpdateRequestToJSON,
} from '../models/index';

export interface CreateSubscriptionRequest {
    subscriptionRequest: SubscriptionRequest;
}

export interface DeleteSubscriptionRequest {
    subscriptionId: string;
}

export interface GetSubscriptionDetailRequest {
    subscriptionId: string;
}

export interface UpdateSubscriptionRequest {
    subscriptionId: string;
    subscriptionUpdateRequest: SubscriptionUpdateRequest;
}

/**
 * 
 */
export class SubscriptionApi extends runtime.BaseAPI {

    /**
     */
    async createSubscriptionRaw(requestParameters: CreateSubscriptionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SubscriptionResponse>> {
        if (requestParameters.subscriptionRequest === null || requestParameters.subscriptionRequest === undefined) {
            throw new runtime.RequiredError('subscriptionRequest','Required parameter requestParameters.subscriptionRequest was null or undefined when calling createSubscription.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer-key", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/subscription/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SubscriptionRequestToJSON(requestParameters.subscriptionRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SubscriptionResponseFromJSON(jsonValue));
    }

    /**
     */
    async createSubscription(requestParameters: CreateSubscriptionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SubscriptionResponse> {
        const response = await this.createSubscriptionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async deleteSubscriptionRaw(requestParameters: DeleteSubscriptionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>> {
        if (requestParameters.subscriptionId === null || requestParameters.subscriptionId === undefined) {
            throw new runtime.RequiredError('subscriptionId','Required parameter requestParameters.subscriptionId was null or undefined when calling deleteSubscription.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer-key", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/subscription/{subscriptionId}`.replace(`{${"subscriptionId"}}`, encodeURIComponent(String(requestParameters.subscriptionId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<boolean>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async deleteSubscription(requestParameters: DeleteSubscriptionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean> {
        const response = await this.deleteSubscriptionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getSubscriptionRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<SubscriptionResponse>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer-key", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/subscription/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SubscriptionResponseFromJSON));
    }

    /**
     */
    async getSubscription(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<SubscriptionResponse>> {
        const response = await this.getSubscriptionRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async getSubscriptionDetailRaw(requestParameters: GetSubscriptionDetailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SubscriptionResponse>> {
        if (requestParameters.subscriptionId === null || requestParameters.subscriptionId === undefined) {
            throw new runtime.RequiredError('subscriptionId','Required parameter requestParameters.subscriptionId was null or undefined when calling getSubscriptionDetail.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer-key", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/subscription/{subscriptionId}`.replace(`{${"subscriptionId"}}`, encodeURIComponent(String(requestParameters.subscriptionId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SubscriptionResponseFromJSON(jsonValue));
    }

    /**
     */
    async getSubscriptionDetail(requestParameters: GetSubscriptionDetailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SubscriptionResponse> {
        const response = await this.getSubscriptionDetailRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async updateSubscriptionRaw(requestParameters: UpdateSubscriptionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SubscriptionResponse>> {
        if (requestParameters.subscriptionId === null || requestParameters.subscriptionId === undefined) {
            throw new runtime.RequiredError('subscriptionId','Required parameter requestParameters.subscriptionId was null or undefined when calling updateSubscription.');
        }

        if (requestParameters.subscriptionUpdateRequest === null || requestParameters.subscriptionUpdateRequest === undefined) {
            throw new runtime.RequiredError('subscriptionUpdateRequest','Required parameter requestParameters.subscriptionUpdateRequest was null or undefined when calling updateSubscription.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer-key", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/subscription/{subscriptionId}`.replace(`{${"subscriptionId"}}`, encodeURIComponent(String(requestParameters.subscriptionId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: SubscriptionUpdateRequestToJSON(requestParameters.subscriptionUpdateRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SubscriptionResponseFromJSON(jsonValue));
    }

    /**
     */
    async updateSubscription(requestParameters: UpdateSubscriptionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SubscriptionResponse> {
        const response = await this.updateSubscriptionRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
