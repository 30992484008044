/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ChatMessageResponse
 */
export interface ChatMessageResponse {
    /**
     * 
     * @type {string}
     * @memberof ChatMessageResponse
     */
    request?: string;
    /**
     * 
     * @type {string}
     * @memberof ChatMessageResponse
     */
    response?: string;
    /**
     * 
     * @type {Date}
     * @memberof ChatMessageResponse
     */
    createdAt?: Date;
}

/**
 * Check if a given object implements the ChatMessageResponse interface.
 */
export function instanceOfChatMessageResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ChatMessageResponseFromJSON(json: any): ChatMessageResponse {
    return ChatMessageResponseFromJSONTyped(json, false);
}

export function ChatMessageResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ChatMessageResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'request': !exists(json, 'request') ? undefined : json['request'],
        'response': !exists(json, 'response') ? undefined : json['response'],
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
    };
}

export function ChatMessageResponseToJSON(value?: ChatMessageResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'request': value.request,
        'response': value.response,
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
    };
}

