import React, { FC, useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { TagResponse } from "../../api/models/TagResponse";
import { CloseOutlined } from "@ant-design/icons";
import heartTrue from "../../assets/icons/heartTrue.svg";




/*
 * Styles.
 */

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  width: 350px;
  height: 182px;
  min-width: 350px;
  border-radius: 15px;
  background: #fff;
  border: 1px solid #D2D6DB;
  // box-shadow: 4px 4px 10px 0px #D0D0D0;
  padding: 20px 20px;
  z-index: 1000;
  flex-shrink: 0; /* Empêche la réduction de la carte */

  cursor:pointer;

  transition: background  0.1s ease;

  &:hover{
      background: #eeeeee;
  }
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content:space-between;
  align-self: stretch;
  gap: 1px;
`;

const HeaderContainerRow = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap:3px;
`;

const Title = styled.h2`
  color: #363636;
  font-size: 18px;
  font-weight: 700;
  margin: 0;
  padding: 0;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* Ajustez ce nombre selon vos besoins */
  -webkit-box-orient: vertical;
`;

const Tags = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
  margin-top: 5px;
`;

const Tag = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  background: #1b3042;
  color: #fff;
  font-size: 8px;
  font-weight: 400;
  padding: 5px 10px;
`;

const Content = styled.div`
  color: #5c5c5c;
  font-size: 10px;
  font-weight: 400;

  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* Ajustez ce nombre selon vos besoins */
  -webkit-box-orient: vertical;

`;

const Heart = styled.img`
  width: 27px;
  height: 27px;
  cursor: pointer;
`;



/*
 * Props.
 */

interface CardProps {
  title: string;
  tags: TagResponse[];
  description: string;
  id: string;
  onModuleDelete: () => void;
}

/*
 * Component.
 */

export const FormationCard: FC<CardProps> = ({
  title,
  tags,
  id,
  description,
  onModuleDelete,
}) => {
  const navigate = useNavigate();
  const handleButtonClick = (url: string) => {
    navigate(url);
  };

  return (
    <Container onClick={() => { handleButtonClick("/formation/" + id) }}>
      <HeaderContainer>
        <HeaderContainerRow>
            <Title>{title}</Title>
            <Tags>
              {tags.map((e) => (
                <Tag key={e.id}>{e.tag}</Tag>
              ))}
            </Tags>
        </HeaderContainerRow>
        <Heart src={heartTrue} alt="like"/>
      </HeaderContainer>
      <Content>{description}</Content>
    </Container>
  );
};
