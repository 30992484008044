/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ChapterRequest } from './ChapterRequest';
import {
    ChapterRequestFromJSON,
    ChapterRequestFromJSONTyped,
    ChapterRequestToJSON,
} from './ChapterRequest';

/**
 * 
 * @export
 * @interface ChaptersRequest
 */
export interface ChaptersRequest {
    /**
     * 
     * @type {Array<ChapterRequest>}
     * @memberof ChaptersRequest
     */
    chapters?: Array<ChapterRequest>;
}

/**
 * Check if a given object implements the ChaptersRequest interface.
 */
export function instanceOfChaptersRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ChaptersRequestFromJSON(json: any): ChaptersRequest {
    return ChaptersRequestFromJSONTyped(json, false);
}

export function ChaptersRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ChaptersRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'chapters': !exists(json, 'chapters') ? undefined : ((json['chapters'] as Array<any>).map(ChapterRequestFromJSON)),
    };
}

export function ChaptersRequestToJSON(value?: ChaptersRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'chapters': value.chapters === undefined ? undefined : ((value.chapters as Array<any>).map(ChapterRequestToJSON)),
    };
}

