import React, { FC, useState } from "react";
import styled from "styled-components";
import { Cascader } from "antd";

/*
 * Props.
 */

/*
 * Styles.
 */

const Container = styled.div`
    display: flex;
    align-items: center;
    gap: 25px;
    flex-direction: column;
    width: 100%;
    height: 400px;
`;

const Header = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
    align-self: stretch;
`;

const Title = styled.h2`
    color: #1B3042;
    font-size: 16px;
    font-weight: 700;
`;


const Cell = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 15px;
  align-self: stretch;
`;

const TitleCell = styled.span`
    color: #727272;
    font-size: 16px;
    font-weight: 400;
    width: 40%;
`;

const Divider = styled.div`
    display: flex;
    padding-top: 15px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;
`;

const Line = styled.div`
    height: 1px;
    align-self: stretch;
    background: #D9D9D9;
`;

const Columns = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
`;

const InputContainer = styled.div`
    display: flex;
    width: 500px;
    height: 77px;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
`;

const InputTitle = styled.div`
    color: #363636;
    font-size: 12.8px;
    font-weight: 400;
    // margin-left: 20px;
`;

const Input = styled.input`
    display: flex;
    padding: 16px 21px 16px 20px;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    border-radius: 15px;
    border: none;
    background: #F7F7F7;

    &:focus,
    &:active {
      outline: none;
    }
`;

const InputCascader = styled.div`
    height: 47px;

    font-size: 13.3333px;
    font-weight: 400;

    display: flex;
    padding: 16px 21px 16px 20px;
    align-items: center;
    align-self: stretch;
    border-radius: 15px;
    background: #F7F7F7;
    cursor: pointer;
`;


interface Option {
    value: string;
    label: string;
    children?: Option[];
}

const optionsLLM: Option[] = [
    {
        value: 'GPT-4 Turbo',
        label: 'gpt-4-turbo',
    },
    {
        value: 'GPT-4',
        label: 'gpt-4',
    },
    {
        value: 'GPT-4-32k',
        label: 'gpt-4-32k',
    },
    {
        value: 'GPT-3.5-turbo-instruct',
        label: 'gpt-3.5-turbo-instruct',
    },
    {
        value: 'GPT-3.5-0125',
        label: 'gpt-3.5-0125',
    },
];

const optionsEmbedding: Option[] = [
    {
        value: 'davinci-002',
        label: 'davinci-002',
    },
    {
        value: 'babbage-002',
        label: 'babbage-002',
    },
];



// interface ProfileProps {
//   prenom: string;
//   setPrenom: (prenom: string) => void;
//   nom: string;
//   setNom: (nom: string) => void;
//   email: string;
//   setEmail: (email: string) => void;
// }

export const OpenAIContent: FC = () => {
    const [key, setKey] = useState<string>("");
    const [llm, setLlm] = useState<string>("");
    const [embedding, setEmbedding] = useState<string>("");

    const onChangeLLM = (_: string[], selectedOptions: Option[]) => {
        setLlm(selectedOptions.map((o) => o.label).join(', '));
    };

    const onChangeEmb = (_: string[], selectedOptions: Option[]) => {
        setEmbedding(selectedOptions.map((o) => o.label).join(', '));
    };

    return (
        <Container>
            <Header>
                <Title>OpenAI</Title>
                <Divider><Line /></Divider>
            </Header>
            <Cell>
                <TitleCell>API</TitleCell>
                <Columns>
                    <InputContainer>
                        <InputTitle>API Key</InputTitle>
                        <Input
                            type="text"
                            placeholder=""
                            value={key}
                            onChange={(e) => setKey(e.target.value)}
                        />
                    </InputContainer>
                </Columns>
            </Cell>
            <Divider><Line /></Divider>
            <Cell>
                <TitleCell>Model</TitleCell>
                <Columns>
                    <InputContainer>
                        <InputTitle>LLM</InputTitle>
                        <Cascader options={optionsLLM} onChange={onChangeLLM}>
                            <InputCascader>
                                {llm}
                            </InputCascader>
                        </Cascader>
                    </InputContainer>
                    <InputContainer>
                        <InputTitle>Embedding</InputTitle>
                        <Cascader options={optionsEmbedding} onChange={onChangeEmb}>
                            <InputCascader>
                                {embedding}
                            </InputCascader>
                        </Cascader>
                    </InputContainer>
                </Columns>
            </Cell>
        </Container>
    );
};
